import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const PlayV1Icon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M1 12C1 16.9461 1.32933 20.1967 1.57708 21.9422C1.70734 22.86 2.49822 23.4324 3.38474 23.1615C4.92045 22.6923 7.75709 21.5724 12.55 18.9C17.3213 16.2397 20.069 14.3486 21.5379 13.2268C22.3883 12.5774 22.3972 11.4121 21.5595 10.7465C20.0331 9.53371 17.1905 7.52569 12.55 5.1C8.18613 2.81889 5.33062 1.64588 3.67542 1.05764C2.66647 0.699073 1.67304 1.32496 1.53194 2.38639C1.2912 4.19735 1 7.34912 1 12Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
