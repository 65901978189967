import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const PauseV1Icon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M3.17482 2.43092C3.20341 1.86407 3.56404 1.37348 4.11541 1.23883C4.61411 1.11703 5.28051 1 6 1C6.71949 1 7.38589 1.11703 7.88459 1.23883C8.43596 1.37348 8.79659 1.86407 8.82518 2.43092C8.89348 3.78515 9 6.73736 9 11.9788C9 17.2314 8.89303 20.2047 8.82475 21.5675C8.79631 22.135 8.43537 22.6268 7.88332 22.7615C7.38476 22.8832 6.71888 23 6 23C5.28112 23 4.61524 22.8832 4.11668 22.7615C3.56463 22.6268 3.20369 22.135 3.17525 21.5675C3.10697 20.2047 3 17.2314 3 11.9788C3 6.73736 3.10652 3.78515 3.17482 2.43092Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M15.1748 2.43092C15.2034 1.86407 15.564 1.37348 16.1154 1.23883C16.6141 1.11703 17.2805 1 18 1C18.7195 1 19.3859 1.11703 19.8846 1.23883C20.436 1.37348 20.7966 1.86407 20.8252 2.43092C20.8935 3.78515 21 6.73736 21 11.9788C21 17.2314 20.893 20.2047 20.8247 21.5675C20.7963 22.135 20.4354 22.6267 19.8833 22.7615C19.3848 22.8832 18.7189 23 18 23C17.2811 23 16.6152 22.8832 16.1167 22.7615C15.5646 22.6267 15.2037 22.135 15.1753 21.5675C15.107 20.2047 15 17.2314 15 11.9788C15 6.73736 15.1065 3.78515 15.1748 2.43092Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
