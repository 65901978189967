import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const NavigationOpenIcon = ({
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <rect
      x="2.9"
      y="2.89999"
      width="18.2"
      height="18.2"
      fill="transparent"
      rx="1.5"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M8.4 21V3"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M13.8 15L16.2 12L13.8 9"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
      strokeWidth={strokeWidth}
    />
  </>
);
