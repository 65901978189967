import React from 'react';
import styled from 'styled-components';

import {
  CAPITAL_HEIGHT,
  COMPUTED_FONT_SIZE,
  V_ALIGN,
  V_CENTER_HEIGHT,
  VerseTypographyTextDecorationEnum,
} from './consts';
import { VerseTypographyStyledComponentProps } from './typings';

export const VerseTypography = styled(
  (props: VerseTypographyStyledComponentProps) => {
    const {
      component,
      textDecoration,
      textTransform,
      fontSize,
      lineHeight,
      fontWeight,
      strikeThroughColor,
      letterSpacing,
      ellipsis,
      ...otherProps
    } = props;
    switch (component) {
      case 'span':
        return <span {...otherProps} />;
      default:
        return <p {...otherProps} />;
    }
  },
)<VerseTypographyStyledComponentProps>`
  && {
    ${({
      color = 'inherit',
      m,
      mt,
      mr,
      mb,
      ml,
      mx,
      my,
      textDecoration,
      fontSize,
      lineHeight,
      fontWeight,
      strikeThroughColor,
      letterSpacing,
      textTransform,
      opacity,
      wordBreak,
      onClick,
      theme,
      ellipsis,
    }) => {
      let decorationStyle = '';
      switch (textDecoration) {
        case VerseTypographyTextDecorationEnum.STRIKETHROUGH:
          decorationStyle += `
            text-decoration: line-through;
            text-decoration-color: ${
              strikeThroughColor ?? theme.colors.main.PrimaryDark100
            };
            color: ${theme.colors.main.PrimaryDark50};
          `;
          break;
        case VerseTypographyTextDecorationEnum.UNDERLINE:
          decorationStyle += 'text-decoration: underline;';
          break;
        default:
      }

      return `
        a {
          color: ${theme.colors.other.Link};
        }
        user-select: none;
        font-kerning: none;

        font-size: calc(${COMPUTED_FONT_SIZE} * ${fontSize}px);
        vertical-align: calc(${V_ALIGN}* -1px);
        line-height: calc((${lineHeight} * ${CAPITAL_HEIGHT} - ${V_ALIGN} + ${V_CENTER_HEIGHT}) * ${fontSize}px);
        color: ${color ?? theme.colors.main.PrimaryDark100};
        font-family: GTEestiDisplay;
        font-weight: ${fontWeight};
        margin: 0;
        word-break: ${wordBreak ?? 'unset'};
        ${m ? `margin: ${theme.spacing(m)}px;` : ''}
        ${mt ? `margin-top: ${theme.spacing(mt)}px;` : ''}
        ${mr ? `margin-right: ${theme.spacing(mr)}px;` : ''}
        ${mb ? `margin-bottom: ${theme.spacing(mb)}px;` : ''}
        ${ml ? `margin-left: ${theme.spacing(ml)}px;` : ''}
        ${
          mx
            ? `margin-left: ${theme.spacing(
                mx,
              )}px; margin-right:${theme.spacing(mx)}px;`
            : ''
        }
        ${
          my
            ? `margin-top: ${theme.spacing(
                my,
              )}px; margin-bottom:${theme.spacing(my)}px;`
            : ''
        }
        ${letterSpacing ? `letter-spacing: ${letterSpacing};` : ''}
        ${textTransform ? `text-transform: ${textTransform};` : ''}
        ${opacity ? `opacity: ${opacity};` : ''}
        ${onClick ? `cursor: pointer;` : ''}
        ${decorationStyle}

        ${
          ellipsis
            ? `overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;`
            : ''
        }
        `;
    }}
  }
`;
