import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const HandHelpIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <g clipPath="url(#handHelpIcon)">
      <path
        d="M20 12.1H4v4.7h16v-4.7z"
        fill={Array.isArray(fill) ? fill[0] : fill}
      />
      <path
        d="M20 15c0 4.4-3.6 7.9-8 7.9S4 19.4 4 15"
        fill={Array.isArray(fill) ? fill[0] : fill}
      />
      <path
        d="M20 15c0 4.4-3.6 7.9-8 7.9S4 19.4 4 15"
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeLinecap="round"
        fill={Array.isArray(fill) ? fill[0] : fill}
      />
      <path
        d="M7.2 14v-1.7c0-1.1-.7-1.9-1.6-1.9-.9 0-1.3.6-1.5 1.9-.1 1.5-.1 2.8-.1 2.8"
        fill={Array.isArray(fill) ? fill[0] : fill}
      />
      <path
        d="M7.2 14v-1.7c0-1.1-.7-1.9-1.6-1.9-.9 0-1.3.6-1.5 1.9-.1 1.5-.1 2.8-.1 2.8"
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={Number.parseFloat(strokeWidth)}
        strokeLinecap="round"
        fill={Array.isArray(fill) ? fill[0] : fill}
      />
      <path
        d="M10.4 12.1V5c0-1-.7-1.8-1.6-1.8-.9 0-1.6 1-1.6 1.8v9c0 1.5 1.6 1.9 2.1 2 1.3.5 2.2.5 2.2 2.3"
        fill={Array.isArray(fill) ? fill[0] : fill}
      />
      <path
        d="M10.4 12.1V5c0-1-.7-1.8-1.6-1.8-.9 0-1.6 1-1.6 1.8v9c0 1.5 1.6 1.9 2.1 2 1.3.5 2.2.5 2.2 2.3"
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={Number.parseFloat(strokeWidth)}
        strokeLinecap="round"
        fill={Array.isArray(fill) ? fill[0] : fill}
      />
      <path
        d="M13.6 12.2V2.8c0-1-.7-1.8-1.6-1.8-.9 0-1.6.9-1.6 1.8V12"
        fill={Array.isArray(fill) ? fill[0] : fill}
      />
      <path
        d="M13.6 12.2V2.8c0-1-.7-1.8-1.6-1.8-.9 0-1.6.9-1.6 1.8V12"
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={Number.parseFloat(strokeWidth)}
        strokeLinecap="round"
        fill={Array.isArray(fill) ? fill[0] : fill}
      />
      <path
        d="M16.8 14.1V3.9c0-1-.7-1.8-1.6-1.8-.9 0-1.6.8-1.6 1.8v8.2"
        fill={Array.isArray(fill) ? fill[0] : fill}
      />
      <path
        d="M16.8 14.1V3.9c0-1-.7-1.8-1.6-1.8-.9 0-1.6.8-1.6 1.8v8.2"
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={Number.parseFloat(strokeWidth)}
        strokeLinecap="round"
        fill={Array.isArray(fill) ? fill[0] : fill}
      />
      <path
        d="M20 15l-.2-7.7c0-1-.5-1.8-1.4-1.8-.9 0-1.6.8-1.6 1.8v6.3"
        fill={Array.isArray(fill) ? fill[0] : fill}
      />
      <path
        d="M20 15l-.2-7.7c0-1-.5-1.8-1.4-1.8-.9 0-1.6.8-1.6 1.8v6.3"
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={Number.parseFloat(strokeWidth)}
        strokeLinecap="round"
        fill={Array.isArray(fill) ? fill[0] : fill}
      />
    </g>
    <defs>
      <clipPath id="handHelpIcon">
        <path fill="#fff" transform="translate(3.5 .5)" d="M0 0h17v22.9H0z" />
      </clipPath>
    </defs>
  </>
);
