import React from 'react';
import { components, IndicatorContainerProps } from 'react-select';
import styled from 'styled-components';

import { VerseIcon, VerseIconIdEnum } from '..';

export const VerseSelectIndicator = (
  props: Omit<
    IndicatorContainerProps<{ [key: string]: any }, boolean>,
    'theme'
  >,
) => {
  const testId = props?.selectProps?.testId;
  return (
    <IconContainer {...props}>
      <VerseIcon
        iconId={VerseIconIdEnum.CHEVRON_DOWN}
        stroke="inherit"
        testId={testId ? `${testId}Indicator` : undefined}
      />
    </IconContainer>
  );
};
const IconContainer = styled(components.IndicatorsContainer)`
  align-self: center;
  transition: all ${({ theme }) => theme.animationSpeed}ms linear;
`;
