import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ArrowUpV2Icon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M11.9999 21.39L11.9999 2.60738M11.9999 2.60738L16.2173 6.60738M11.9999 2.60738L7.7825 6.60738"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
