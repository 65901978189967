import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const H2Icon = ({ stroke }: VerseIconSVGProps) => (
  <>
    <path
      d="M10.1734 10.8401V7.15875H12.368V17.0701H10.1734V12.9073H6.05306V17.0701H3.8584V7.15875H6.05306V10.8401H10.1734Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M13.7507 17.0701V15.3852L16.9649 12.1994C17.3991 11.7652 17.7058 11.4065 17.8852 11.1233C18.0645 10.8307 18.1542 10.5475 18.1542 10.2738C18.1542 9.95281 18.0598 9.70739 17.871 9.53748C17.6822 9.35813 17.4132 9.26846 17.064 9.26846C16.6581 9.26846 16.3466 9.37701 16.1295 9.59412C15.9218 9.80178 15.818 10.1133 15.818 10.5286H13.6516C13.6516 9.50916 13.9537 8.71625 14.5578 8.14989C15.1714 7.58353 16.0256 7.30034 17.1206 7.30034C18.1401 7.30034 18.9377 7.55049 19.5135 8.05078C20.0893 8.55106 20.3772 9.24014 20.3772 10.118C20.3772 10.6655 20.245 11.1752 19.9807 11.6472C19.7259 12.1192 19.2869 12.6478 18.6639 13.233L16.5967 15.2011L20.4055 15.1728V17.0701H13.7507Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
  </>
);
