import * as React from "react";
import { TextIcon } from "@radix-ui/react-icons";
import { TDShapeType, TDSnapshot } from "@tldraw/tldraw";
import { StyledToolButton } from "./StyledToolButton";
import { useButterTldrawApp } from "../../../utils/useButterTldrawApp";
import { VerseTooltip } from "src/verse/src";

const activeToolSelector = (s: TDSnapshot) => s.appState.activeTool;

export const ToolPanelTextButton = React.memo(function PrimaryTools() {
  const app = useButterTldrawApp();

  const activeTool = app?.useStore(activeToolSelector);

  const selectTextTool = React.useCallback(() => {
    app?.selectTool(TDShapeType.Text);
  }, [app]);

  return (
    <VerseTooltip title="Text Tool (T)" placement="right">
      <StyledToolButton
        onClick={selectTextTool}
        isActive={activeTool === TDShapeType.Text}
      >
        <TextIcon />
      </StyledToolButton>
    </VerseTooltip>
  );
});
