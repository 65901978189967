import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ToolIcon = ({ stroke, strokeWidth, fill }: VerseIconSVGProps) => (
  <>
    <path
      d="M1.3749 19.3112C1.58259 21.0843 2.91517 22.4169 4.68823 22.6246C6.34874 22.8191 8.76303 22.9999 11.9995 22.9999C15.236 22.9999 17.6503 22.8191 19.3108 22.6246C21.0839 22.4169 22.4164 21.0843 22.6241 19.3112C22.8186 17.6507 22.9995 15.2364 22.9995 11.9999C22.9995 8.76345 22.8186 6.34917 22.6241 4.68866C22.4164 2.9156 21.0839 1.58301 19.3108 1.37533C17.6503 1.18082 15.236 0.999939 11.9995 0.999939C8.76303 0.999939 6.34874 1.18082 4.68823 1.37533C2.91517 1.58301 1.58259 2.9156 1.3749 4.68866C1.1804 6.34917 0.999512 8.76345 0.999512 11.9999C0.999512 15.2364 1.1804 17.6507 1.3749 19.3112Z"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <circle
      cx="7.3501"
      cy="16.5"
      r="3"
      stroke={Array.isArray(stroke) ? stroke[1] : stroke}
      strokeWidth={strokeWidth}
      fill={Array.isArray(fill) ? fill[1] : fill}
    />
    <rect
      x="13.5"
      y="13.5"
      width="6"
      height="6"
      rx="0.75"
      stroke={Array.isArray(stroke) ? stroke[2] : stroke}
      strokeWidth={strokeWidth}
      fill={Array.isArray(fill) ? fill[2] : fill}
    />
    <path
      d="M19.3335 4.5L13.4999 10.3336"
      stroke={Array.isArray(stroke) ? stroke[3] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      fill={Array.isArray(fill) ? fill[3] : fill}
    />
    <path
      d="M19.3335 4.5L13.4999 10.3336"
      stroke={Array.isArray(stroke) ? stroke[3] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      fill={Array.isArray(fill) ? fill[3] : fill}
    />
    <path
      d="M19.3335 10.3337L13.4999 4.50005"
      stroke={Array.isArray(stroke) ? stroke[3] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      fill={Array.isArray(fill) ? fill[3] : fill}
    />
    <path
      d="M6.93808 4.22204C7.05239 4.0163 7.34828 4.0163 7.46258 4.22204L10.7027 10.0543C10.8138 10.2543 10.6692 10.5 10.4405 10.5H3.96019C3.73144 10.5 3.58685 10.2543 3.69794 10.0543L6.93808 4.22204Z"
      stroke={Array.isArray(stroke) ? stroke[4] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      fill={Array.isArray(fill) ? fill[4] : fill}
    />
  </>
);
