import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const BannerIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M3 12C3 15.6655 3.18983 18.5505 3.37068 20.4674C3.49707 21.8071 4.91079 22.5446 6.11434 21.9428L11.2755 19.3622C11.7316 19.1342 12.2684 19.1342 12.7245 19.3622L17.8857 21.9428C19.0892 22.5446 20.5029 21.8071 20.6293 20.4674C20.8102 18.5505 21 15.6655 21 12C21 8.75355 20.8511 6.33438 20.6914 4.67341C20.522 2.9119 19.2622 1.53359 17.5066 1.31134C16.1765 1.14295 14.354 1 12 1C9.64596 1 7.82355 1.14295 6.49343 1.31134C4.73781 1.53359 3.47797 2.9119 3.3086 4.67341C3.14891 6.33438 3 8.75355 3 12Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
