import { keyframes } from 'styled-components';

export const floatingButterKeyframes = () => keyframes`
  0%, 100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, -25px);
  }
  50% {
    transform: translate(0, -10px);
  }
  75% {
    transform: translate(0, -35px);
  }
`;
