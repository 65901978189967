import React from 'react';
import styled from 'styled-components';

import {
  VerseBody1,
  VerseBody2,
  VerseBody3,
  VerseButtonTextLarge,
  VerseButtonTextMedium,
  VerseButtonTextSmall,
  VerseButtonTextXSmall,
  VerseHelpText,
  VerseIcon,
} from '..';
import {
  VerseTextButtonIconPositionEnum,
  VerseTextButtonTypographyEnum,
} from './consts';
import {
  TextInnerProp,
  TextOuterWrapperProps,
  VerseTextButtonComponentProps,
} from './typings';

export function VerseTextButton({
  title,
  icon,
  typography,
  testId,
  textInnerProp = {},
  ...other
}: VerseTextButtonComponentProps) {
  const reverseAlignment =
    icon?.position === VerseTextButtonIconPositionEnum.RIGHT;
  const TextComponent = React.useMemo(() => {
    switch (typography) {
      case VerseTextButtonTypographyEnum.HELP_TEXT:
        return VerseHelpText;
      case VerseTextButtonTypographyEnum.BUTTON_XSMALL:
        return VerseButtonTextXSmall;
      case VerseTextButtonTypographyEnum.BUTTON_SMALL:
        return VerseButtonTextSmall;
      case VerseTextButtonTypographyEnum.BUTTON_MEDIUM:
        return VerseButtonTextMedium;
      case VerseTextButtonTypographyEnum.BUTTON_LARGE:
        return VerseButtonTextLarge;
      case VerseTextButtonTypographyEnum.BODY_3:
        return VerseBody3;
      case VerseTextButtonTypographyEnum.BODY_2:
        return VerseBody2;
      case VerseTextButtonTypographyEnum.BODY_1:
      default:
        return VerseBody1;
    }
  }, [typography]);

  const iconSize = React.useMemo(() => {
    switch (typography) {
      case VerseTextButtonTypographyEnum.BUTTON_XSMALL:
      case VerseTextButtonTypographyEnum.BUTTON_SMALL:
      case VerseTextButtonTypographyEnum.BUTTON_MEDIUM:
      case VerseTextButtonTypographyEnum.BUTTON_LARGE:
        return 16;
      case VerseTextButtonTypographyEnum.BODY_3:
      case VerseTextButtonTypographyEnum.BODY_2:
      case VerseTextButtonTypographyEnum.BODY_1:
      default:
        return 12;
    }
  }, [typography]);

  return (
    <TextOuterWrapper
      reverseAlignment={reverseAlignment}
      {...other}
      data-testid={testId}
    >
      {icon && (
        <VerseIcon
          size={iconSize}
          mr={reverseAlignment ? 0 : 0.75}
          ml={reverseAlignment ? 0.75 : 0}
          {...icon}
        />
      )}

      <TextEllipsisWrapper {...textInnerProp}>
        <TextComponent component="span">{title}</TextComponent>
      </TextEllipsisWrapper>
    </TextOuterWrapper>
  );
}

const TextEllipsisWrapper = styled.div<TextInnerProp>`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > span {
    color: ${({ theme, color }) =>
      color ?? theme.colors.main.PrimaryDark100} !important;
    ${({ underline = true }) =>
      underline ? 'text-decoration: underline;' : ''}
  }
`;
const TextOuterWrapper = styled.div<TextOuterWrapperProps>`
  display: ${({ inlineDisplay }) => (inlineDisplay ? 'inline-flex' : 'flex')};
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  ${({ width = 'max-content' }) =>
    width && `width: ${Number.isInteger(width) ? `${width}px` : width};`}
  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
    ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
    ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
    ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}
  transition: opacity 0.2s linear;

  :hover {
    opacity: 0.6;
  }

  ${({ reverseAlignment }) =>
    reverseAlignment && `flex-direction: row-reverse;`}
  ${({ disabled }) => disabled && `opacity: 0.6; pointer-events: none;`}
`;
