export enum VerseInputSizeEnum {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum VerseInputDataValueEnum {
  FOCUS = 'focus',
  INVALID = 'invalid',
  FILLED = 'filled',
}

export const CONTAINS_SPECIAL_CHARS_REGEX =
  /(?=.+[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).+/g;
export const CONTAINS_UPPER_AND_LOWER_CASE_REGEX = /(?=.*[a-z])(?=.*[A-Z]).+/g;
