import React, { ReactElement } from 'react';
import { components, GroupProps } from 'react-select';
import styled from 'styled-components';

import { useVerseTheme } from '../..';

export function VerseSelectGroup(
  props: Omit<GroupProps<{ [key: string]: any }, boolean>, 'theme'>,
): ReactElement {
  const theme = useVerseTheme();
  return (
    <GroupContainer {...props} borderColor={theme.colors.main.PrimaryDark20} />
  );
}

const GroupContainer = styled(components.Group)<{ borderColor: string }>`
  && {
    padding: 0 6px;

    :not(:last-child) {
      border-bottom: 1px solid ${({ borderColor }) => borderColor};
      margin-bottom: 8px;
      padding-bottom: 8px;
    }
  }
`;
