import styled, { css, keyframes } from 'styled-components';

import { VerseSkeletonComponentProps } from './typings';

export const VersePlaceholderShimmer = keyframes`
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
`;

export const VerseSkeleton = styled.div<VerseSkeletonComponentProps>`
  position: relative;
  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}
  width: ${({ width }) =>
    width ? (typeof width === 'string' ? width : `${width}px`) : '100%'};
  height: ${({ height }) =>
    height ? (typeof height === 'string' ? height : `${height}px`) : '48px'};
  overflow: hidden;
  border-radius: 12px;

  background: ${({ background, theme }) =>
    background ? background : theme.colors.other.White};
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-image: ${({ theme }) => `linear-gradient(
      ${theme.colors.main.PrimaryDark10RGBA} 100%,
      ${theme.colors.main.PrimaryDark0RGBA} 0
    )`};
    background-position: center;
    background-size: cover;
  }

  ${({ theme, disableAnimation }) =>
    !disableAnimation
      ? css`
          &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: -100%;
            height: 500%;
            width: 100%;
            background: linear-gradient(
              152deg,
              ${theme.colors.other.White0RGBA} 0%,
              ${theme.colors.other.White0RGBA} 40%,
              ${theme.colors.other.White40RGBA} 50%,
              ${theme.colors.other.White0RGBA} 60%,
              ${theme.colors.other.White0RGBA} 100%
            );
            animation: ${VersePlaceholderShimmer} 1s
              cubic-bezier(0.4, 0, 0.2, 1) infinite;
          }
        `
      : css``}
`;
