import { keyframes } from 'styled-components';

export interface ShakeKeyframesProps {
  originDeg: number;
  toDeg: number;
}

export const shakeKeyframes = ({
  originDeg,
  toDeg,
}: ShakeKeyframesProps) => keyframes`
  0% {
    transform: rotate(${originDeg}deg);
  }
  25% {
    transform: rotate(${toDeg}deg);
  }
  30% {
    transform: rotate(${originDeg}deg);
  }
  40% {
    transform: rotate(${toDeg}deg);
  }
  50% {
    transform: rotate(${originDeg}deg);
  }
  100% {
    transform: rotate(${originDeg}deg);
  }
`;
