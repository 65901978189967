import React from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

import { VerseBody1, VerseBody3 } from '..';
import {
  VERSE_RADIO_BUTTON_DOT_GROW_KEYFRAMES,
  VerseRadioButtonSizeEnum,
  VerseRadioButtonVariantEnum,
} from './consts';
import {
  VerseRadioButtonWrapperProps,
  VerseRadioGroupComponentProps,
  VerseRadioGroupWrapperProps,
} from './typings';

export function VerseRadioGroup({
  name,
  control,
  defaultValue,
  onChange,
  size = VerseRadioButtonSizeEnum.MEDIUM,
  options,
  disabled,
  rules,
  onFocus,
  optionGap = 1,
  testId,
  variant = VerseRadioButtonVariantEnum.DEFAULT,
  ...other
}: VerseRadioGroupComponentProps) {
  const {
    field: { ref, value, onChange: defaultOnChange, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
    onFocus,
  });

  const combinedOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    defaultOnChange(e.target.value);
    onChange && onChange(e);
  };

  const renderText = (label: string) => {
    switch (size) {
      case VerseRadioButtonSizeEnum.SMALL:
        return (
          <VerseBody3 ml={0.75} component="span">
            {label}
          </VerseBody3>
        );
      case VerseRadioButtonSizeEnum.MEDIUM:
      case VerseRadioButtonSizeEnum.LARGE:
      default:
        return (
          <VerseBody1 ml={1} component="span">
            {label}
          </VerseBody1>
        );
    }
  };

  return (
    <RadioGroupWrapper {...other}>
      {options.map((option, idx) => {
        const isSelected = value === option.value;
        return (
          <RadioButtonWrapper
            flexDirection={other?.flexDirection}
            size={size}
            key={`verse-radioButton-${name}-${idx}-key`}
            optionGap={optionGap}
            variant={variant}
            htmlFor={`verse-radioButton-${name}-${idx}`}
            isSelected={isSelected}
          >
            <HiddenInput
              type="radio"
              id={`verse-radioButton-${name}-${idx}`}
              name={name}
              value={option.value}
              onChange={combinedOnChange}
              defaultChecked={isSelected}
              disabled={disabled}
            />
            <RadioButton ref={ref} {...inputProps} data-testid={testId} />
            {renderText(option.label)}
          </RadioButtonWrapper>
        );
      })}
    </RadioGroupWrapper>
  );
}

const RadioGroupWrapper = styled.div<VerseRadioGroupWrapperProps>`
  display: flex;

  flex-direction: ${({ flexDirection }) => flexDirection ?? 'column'};

  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}
`;

const RadioButton = styled.div`
  position: relative;
  border-radius: 50%;
  border: 1px solid;

  transition: all ${({ theme }) => theme.animationSpeed}ms linear;

  cursor: pointer;
`;

const RadioButtonWrapper = styled.label<
  VerseRadioButtonWrapperProps & {
    flexDirection?: 'row' | 'column';
    isSelected?: boolean;
  }
>`
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all ${({ theme }) => theme.animationSpeed}ms linear;

  ${({ variant, theme, isSelected }) => {
    switch (variant) {
      case VerseRadioButtonVariantEnum.BORDER:
        return `
              border: 1px solid ${
                isSelected
                  ? theme.colors.other.Blue100
                  : theme.colors.main.PrimaryDark30
              };
              border-radius: 10px;
              padding: ${theme.spacing(1)}px ${theme.spacing(1.5)}px;
           ${isSelected ? `background: ${theme.colors.other.Blue10}` : ``};

          `;
      case VerseRadioButtonVariantEnum.DEFAULT:
      default:
        return ``;
    }
  }}

  > ${RadioButton} {
    ${({ size }) => {
      switch (size) {
        case VerseRadioButtonSizeEnum.SMALL:
          return `
                height: 14px;
                width 14px;
            `;
        case VerseRadioButtonSizeEnum.MEDIUM:
          return `
                height: 16px;
                width 16px;
            `;
        case VerseRadioButtonSizeEnum.LARGE:
          return `
                height: 20px;
                width 20px;
            `;
        default:
          return ``;
      }
    }}

    
  }

  :not(:last-child) {
     ${({ optionGap, theme, flexDirection }) =>
       optionGap
         ? `${
             flexDirection === 'row' ? `margin-right` : `margin-bottom`
           }: ${theme.spacing(optionGap)}px;}`
         : ''}
`;

const HiddenInput = styled.input`
  display: none;

  :hover + ${RadioButton} {
    border-color: ${({ theme }) => theme.colors.main.PrimaryDark50};
  }
  :disabled + ${RadioButton} {
    background-color: ${({ theme }) => theme.colors.other.Sand03};
    border-color: ${({ theme }) => theme.colors.main.PrimaryDark20};
  }
  :checked + ${RadioButton} {
    background-color: ${({ theme }) => theme.colors.main.PrimaryDark100};
    border-color: ${({ theme }) => theme.colors.main.PrimaryDark100};
    ::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;

      width: 50%;
      height: 50%;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.other.White};
      transform: translate(-50%, -50%) scale(1);
      animation: ${VERSE_RADIO_BUTTON_DOT_GROW_KEYFRAMES} 0.3s linear;
    }
  }
  :checked:hover + ${RadioButton} {
    background-color: ${({ theme }) => theme.colors.main.PrimaryDark80};
    border-color: ${({ theme }) => theme.colors.main.PrimaryDark80};
  }
  :checked:disabled + ${RadioButton} {
    background-color: ${({ theme }) => theme.colors.main.PrimaryDark20};
    border-color: ${({ theme }) => theme.colors.main.PrimaryDark20};
  }

  :active + ${RadioButton} {
    border-color: ${({ theme }) => theme.colors.main.PrimaryDark100};
  }
  :active&:checked + ${RadioButton} {
    background-color: ${({ theme }) => theme.colors.main.PrimaryDark70};
    border-color: ${({ theme }) => theme.colors.main.PrimaryDark70};
  }
`;
