import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const AddTemplateIcon = ({ stroke, strokeWidth, fill }: VerseIconSVGProps) => (
  <>
    <path
      d="M3.95902 6.39509C4.16039 4.53758 5.61661 3.21094 7.47843 3.05454C8.90157 2.93499 10.853 2.83337 13.375 2.83337C15.897 2.83337 17.8484 2.93499 19.2716 3.05454C21.1334 3.21094 22.5896 4.53759 22.791 6.39509C22.9091 7.48461 23 8.88756 23 10.625C23 12.3625 22.9091 13.7655 22.791 14.855C22.5896 16.7125 21.1334 18.0391 19.2716 18.1955C17.8484 18.3151 15.897 18.4167 13.375 18.4167C10.853 18.4167 8.90158 18.3151 7.47844 18.1955C5.61661 18.0391 4.16039 16.7125 3.95902 14.855C3.84091 13.7655 3.75 12.3625 3.75 10.625C3.75 8.88756 3.84091 7.48461 3.95902 6.39509Z"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M1.20927 9.14502C1.41063 7.28752 2.86685 5.96088 4.72868 5.80448C6.15182 5.68493 8.10324 5.58331 10.6252 5.58331C13.1472 5.58331 15.0987 5.68493 16.5218 5.80448C18.3836 5.96088 19.8399 7.28752 20.0412 9.14503C20.1593 10.2345 20.2502 11.6375 20.2502 13.375C20.2502 15.1125 20.1593 16.5154 20.0412 17.6049C19.8399 19.4624 18.3836 20.7891 16.5218 20.9455C15.0987 21.065 13.1472 21.1666 10.6252 21.1666C8.10325 21.1666 6.15182 21.065 4.72868 20.9455C2.86685 20.7891 1.41063 19.4624 1.20927 17.6049C1.09116 16.5154 1.00024 15.1125 1.00024 13.375C1.00024 11.6375 1.09116 10.2345 1.20927 9.14502Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M6.95972 13.3697H10.6244M10.6244 13.3697H14.2891M10.6244 13.3697V9.70502M10.6244 13.3697V17.0344"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
