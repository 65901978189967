import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const LoveThisIllustration = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => {
  return (
    <>
      <circle
        cx="12"
        cy="12"
        r="10"
        fill={Array.isArray(fill) ? fill[0] : fill}
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={strokeWidth}
      />
      <path
        d="M4.49898 7.82492C4.30045 9.1153 5.28468 10.7535 8.57039 12.1821C10.9275 9.61063 11.3491 7.85894 10.499 6.46777C9.71324 5.18205 7.78468 5.82491 7.42753 6.89634C6.77404 6.07662 4.78469 5.96776 4.49898 7.82492Z"
        fill={Array.isArray(fill) ? fill[1] : fill}
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={strokeWidth}
      />
      <path
        d="M19.5469 7.82492C19.7454 9.1153 18.7612 10.7535 15.4755 12.1821C13.1183 9.61063 12.6967 7.85894 13.5469 6.46777C14.3326 5.18205 16.2612 5.82491 16.6184 6.89634C17.3478 6.07662 19.2612 5.96776 19.5469 7.82492Z"
        fill={Array.isArray(fill) ? fill[1] : fill}
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={strokeWidth}
      />
      <path
        d="M17.9296 15.4352C18.7154 13.9352 17.8582 13.9352 16.8582 13.7924C14.8582 13.5781 9.07248 13.3638 6.85821 13.8638C5.518 14.1664 5.70579 14.4385 5.92964 15.0781C6.42963 16.5066 8.42966 19.3638 11.9296 19.3638C15.4296 19.3638 17.1439 16.9352 17.9296 15.4352Z"
        fill={Array.isArray(fill) ? fill[1] : fill}
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={strokeWidth}
      />
    </>
  );
};
