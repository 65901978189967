import React from 'react';
import { VerseIconSVGProps } from '../typings';
import { VerseTheme } from '../../../theme';

export const TrashIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M3.34492 6.12391C3.47984 5.19071 4.17405 4.59747 5.10556 4.45143C6.42154 4.24512 8.683 4 12 4C15.317 4 17.5785 4.24512 18.8944 4.45143C19.826 4.59747 20.5202 5.19072 20.6551 6.12391C20.8277 7.31813 21 9.50951 21 13.5C21 16.0503 20.8768 18.0095 20.7343 19.4225C20.5554 21.1975 19.2393 22.5437 17.4656 22.7356C16.1391 22.8791 14.3299 23 12 23C9.67006 23 7.86091 22.8791 6.53444 22.7356C4.76073 22.5437 3.44464 21.1975 3.26567 19.4225C3.12321 18.0095 3 16.0503 3 13.5C3 9.50951 3.17225 7.31813 3.34492 6.12391Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <line
      x1="9.5"
      y1="8.5"
      x2="9.5"
      y2="18.5"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <line
      x1="14.5"
      y1="8.5"
      x2="14.5"
      y2="18.5"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M16 4V2C16 1.44772 15.5523 1 15 1H9C8.44772 1 8 1.44772 8 2V4"
      fill={VerseTheme.colors.other.White}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M1.84619 5.2C1.84619 5.2 5.00004 4 12 4C19 4 22.1539 5.2 22.1539 5.2"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
