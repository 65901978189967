import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const UserAddIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M12.0001 16C8.76359 16 6.3493 16.1809 4.68879 16.3754C2.91573 16.5831 1.58297 17.9167 1.37533 19.6898C1.31364 20.2166 1.25332 20.8193 1.19971 21.5"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <circle
      cx="12"
      cy="7"
      r="6"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M16 18H19.5M19.5 18H23M19.5 18V14.5M19.5 18V21.5"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
