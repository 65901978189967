import React, { ReactNode } from "react";
import { VerseThemeProvider } from "src/verse/src";
import styled from "styled-components";

export const ButterboardAppBase = ({ children }: { children: ReactNode }) => {
  return (
    <VerseThemeProvider>
      <StyledTLDraw>
        <StyledContainer>{children}</StyledContainer>
      </StyledTLDraw>
    </VerseThemeProvider>
  );
};

const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const StyledTLDraw = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
