import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const MicOnIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M11.7122 20V23"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="7.86633"
      y1="23"
      x2="15.6693"
      y2="23"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M6.90526 4.21154C7.36655 1.98598 9.49501 1 11.7679 1V1C14.0407 1 16.1692 1.98599 16.6305 4.21154C16.8724 5.37895 17.0497 6.93785 17.0497 9C17.0497 11.0621 16.8724 12.621 16.6305 13.7885C16.1692 16.014 14.0407 17 11.7679 17V17C9.49501 17 7.36655 16.014 6.90526 13.7885C6.66329 12.621 6.48608 11.0621 6.48608 9C6.48608 6.93785 6.66329 5.37895 6.90526 4.21154Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M19.5242 10.5C19.4528 11.905 19.285 13.1853 19.0621 14.3C18.3621 17.5 15.5621 19.5 11.7621 19.5C7.9621 19.5 5.1621 17.5 4.4621 14.3C4.23917 13.1853 4.07145 11.905 4 10.5"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      fill="transparent"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </>
);
