import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const PopoutIcon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M20.5 13.3C20.5 16.2 20.3 18.3 20.2 19.8C20 21.4 18.8 22.5 17.3 22.7C15.8 22.9 13.7 23 10.8 23C7.9 23 5.8 22.8 4.3 22.7C2.7 22.5 1.5 21.3 1.3 19.7C1.1 18.2 1 16.1 1 13.2C1 10.3 1.2 8.2 1.3 6.7C1.5 5.2 2.7 4 4.3 3.9C5.7 3.7 7.9 3.5 10.7 3.5"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
      strokeLinecap="round"
      fill="transparent"
    />
    <path
      d="M23 1L11 13M15 1H23H15ZM23 1V9V1Z"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
