export enum VerseTextButtonIconPositionEnum {
  LEFT = 'left',
  RIGHT = 'right',
}
export enum VerseTextButtonTypographyEnum {
  BODY_1 = 'body1',
  BODY_2 = 'body2',
  BODY_3 = 'body3',
  HELP_TEXT = 'helpText',
  BUTTON_XSMALL = 'buttonXSmall',
  BUTTON_SMALL = 'buttonSmall',
  BUTTON_MEDIUM = 'buttonMedium',
  BUTTON_LARGE = 'buttonLarge',
}
