import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const WarningV1Icon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M12 21.5C16.9122 21.5 20.1521 21.2047 21.9062 20.98C22.8408 20.8603 23.4169 20.0594 23.1059 19.1699C22.606 17.7399 21.4721 15.1937 18.9 11C16.2021 6.60129 14.2954 4.09445 13.1798 2.77339C12.5364 2.01145 11.456 2.00236 10.7975 2.75134C9.59051 4.12431 7.56005 6.72166 5.1 11C2.92493 14.7827 1.75733 17.3188 1.14352 18.8509C0.734061 19.8729 1.37222 20.8958 2.4655 21.0259C4.28848 21.2428 7.41613 21.5 12 21.5Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M12 17 T12 17"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth) * 1.25}
      strokeLinecap="round"
    />
    <path
      d="M12 14.5V7.5"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
