import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ClockIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <circle
      cx="12"
      cy="12"
      r="11"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M12 4V12L17.5 15.5"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
