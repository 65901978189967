import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const NavigationCloseIcon = ({
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <rect
      x="2.9"
      y="2.89999"
      width="18.2"
      height="18.2"
      rx="1.5"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M8.4 21V3"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M16.2 9L13.8 12L16.2 15"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
      fill="transparent"
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
