import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const FolderIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M1 9.05902C1 12.5388 1.20554 15.3192 1.41402 17.2443C1.61786 19.1265 3.00987 20.5856 4.89116 20.7986C6.54327 20.9857 8.8927 21.1538 12 21.1538C15.0523 21.1538 17.3733 20.9916 19.0204 20.8085C20.9474 20.5944 22.3755 19.0815 22.6157 17.1576C22.8135 15.5726 23 13.643 23 12C23 11.11 22.8493 10.0067 22.6649 8.9899C22.3422 7.21096 20.7439 5.99998 18.9359 5.99998H12.4482C11.5124 5.99998 10.6063 5.67193 9.88747 5.0729L9.11253 4.42713C8.39367 3.82809 7.4859 3.50004 6.55017 3.50004L4.71347 3.50003C2.99849 3.50002 1.46883 4.59887 1.21384 6.29479C1.09126 7.11008 1 8.05441 1 9.05902Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M22.6156 17.1576C22.8135 15.5726 23 13.643 23 12C23 11.91 22.9984 11.8178 22.9955 11.7237C22.9642 10.7298 22.0921 10 21.0977 10H3.02664C1.91366 10 1.01214 10.9108 1.05086 12.0231C1.12466 14.1431 1.2685 15.9005 1.41379 17.2425C1.61758 19.1248 3.00983 20.5856 4.89112 20.7986C6.54323 20.9857 8.89266 21.1538 12 21.1538C15.0522 21.1538 17.3733 20.9916 19.0204 20.8085C20.9473 20.5944 22.3755 19.0815 22.6156 17.1576Z"
      fill={Array.isArray(fill) ? fill[1] : fill ?? 'transparent'}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
