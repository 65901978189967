import styled from 'styled-components';

import {
  VerseIconClickableBGVariantEnum,
  VerseIconClickablePaddingVariantEnum,
} from './consts';
import {
  VerseIconWrapperProps,
  VerseInteractableIconWrapperProps,
} from './typings';

export const IconWrapper = styled.svg.attrs<VerseIconWrapperProps>(props => ({
  width: props?.size,
  height: props?.size,
  viewBox: props.viewBox,
  xmlns: 'http://www.w3.org/2000/svg',
}))<VerseIconWrapperProps>``;

export const InteractableIconWrapper = styled.div<VerseInteractableIconWrapperProps>`
  display: flex;

  ${({ mr, theme }) =>
    mr && Number.isFinite(mr) ? `margin-right: ${theme.spacing(mr)}px;` : ''}
  ${({ ml, theme }) =>
    ml && Number.isFinite(ml) ? `margin-left: ${theme.spacing(ml)}px;` : ''}
  ${({ mt, theme }) =>
    mt && Number.isFinite(mt) ? `margin-top: ${theme.spacing(mt)}px;` : ''}
  ${({ mb, theme }) =>
    mb && Number.isFinite(mb) ? `margin-bottom: ${theme.spacing(mb)}px;` : ''}

  ${({
    onClick,
    onMouseEnter,
    size,
    clickableBgVariant,
    clickablePaddingVariant = VerseIconClickablePaddingVariantEnum.AUTO,
    disabled,
    theme,
  }) => {
    const useAutoPadding =
      clickablePaddingVariant === VerseIconClickablePaddingVariantEnum.AUTO;

    if (onClick || onMouseEnter) {
      const iconSize = size ?? 24;
      const sizeDiff = Math.max(iconSize - 16, 0);
      const sizeAdaptIncrement = Math.floor(sizeDiff / 4);
      const padding = useAutoPadding
        ? Math.min(6 + sizeAdaptIncrement, 100)
        : 0;
      const radius = useAutoPadding
        ? Math.min(8 + sizeAdaptIncrement * 2, 100)
        : Math.floor(iconSize / 4);

      let bgColor, hoverBGColor;
      switch (clickableBgVariant) {
        case VerseIconClickableBGVariantEnum.SOLID:
          bgColor = theme.colors.other.White;
          hoverBGColor = theme.colors.other.Sand08;
          break;
        case VerseIconClickableBGVariantEnum.SEMI_TRANSPARENT:
        default:
          bgColor = 'transparent';
          hoverBGColor = theme.colors.other.Sand08RGBA;
      }

      return `
      padding: ${padding}px; 
      border-radius: ${radius}px;
      cursor: pointer;
      transition: all ${theme.animationSpeed}ms linear;
      background-color: ${bgColor};

      :hover {
        background-color: ${hoverBGColor};
      }

      ${disabled ? `pointer-events: none; opacity: 0.2;` : ''}
      `;
    }
  }}
`;
