import React from 'react';
import { VerseIconSVGProps } from '../typings';
import { VerseTheme } from '../../../theme';

export const NotificationIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M14 21C14 22.1046 13.1046 23 12 23C10.8954 23 10 22.1046 10 21"
      fill={VerseTheme.colors.other.White}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M4.06394 9.99855C4.04874 10.4973 4.02786 10.9746 4 11.4142C3.86471 13.5486 3.15283 16.3555 2.61658 18.2248C2.31245 19.285 2.99536 20.3868 4.09114 20.5125C5.99172 20.7305 8.95646 21 12 21C15.0435 21 18.0083 20.7305 19.9089 20.5125C21.0046 20.3868 21.6875 19.285 21.3834 18.2248C20.8472 16.3555 20.1353 13.5486 20 11.4142C19.9721 10.9746 19.9513 10.4973 19.9361 9.99856C19.8029 5.6318 16.3688 2 12 2C7.63121 2 4.19705 5.6318 4.06394 9.99855Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M12 1.5 T12 2"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
