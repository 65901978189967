import * as React from "react";
import { CursorArrowIcon } from "@radix-ui/react-icons";
import { TDSnapshot } from "@tldraw/tldraw";
import { StyledToolButton } from "./StyledToolButton";
import { useButterTldrawApp } from "../../../utils/useButterTldrawApp";
import { VerseTooltip } from "src/verse/src";

const activeToolSelector = (s: TDSnapshot) => s.appState.activeTool;

export const ToolPanelSelectButton = React.memo(function PrimaryTools() {
  const app = useButterTldrawApp();

  const activeTool = app?.useStore(activeToolSelector);

  const selectSelectTool = React.useCallback(() => {
    app?.selectTool("select");
  }, [app]);

  return (
    <VerseTooltip title="Select Tool (V)" placement="right">
      <StyledToolButton
        onClick={selectSelectTool}
        isActive={activeTool === "select"}
      >
        <CursorArrowIcon />
      </StyledToolButton>
    </VerseTooltip>
  );
});
