import React from 'react';
import styled from 'styled-components';
import { VerseTileDisplayNameText } from '..';
import { useKeyPress } from '../../utils/useKeyPress';
import { ButtonShortcutIndicatorProps } from './typings';

export const ButtonShortcutIndicator = ({
  targetKey,
  label,
  hidden = false,
  shouldListen = true,
  onTrigger,
}: ButtonShortcutIndicatorProps) => {
  const pressed = useKeyPress({
    targetKey,
    enabled: shouldListen ?? false,
    onKeyDown: onTrigger,
  });

  if (hidden) return null;
  return (
    <StyledKeyboardButton pressed={pressed}>
      <VerseTileDisplayNameText>{label}</VerseTileDisplayNameText>
    </StyledKeyboardButton>
  );
};

const StyledKeyboardButton = styled.div<{ pressed: boolean }>`
  position: absolute;
  left: 50%;
  top: -6px;

  transform: translate(-50%, -50%);

  padding: 4px 8px;
  background-color: ${({ pressed, theme }) =>
    pressed ? theme.colors.other.Sand05 : theme.colors.other.White};
  border: 1px solid ${({ theme }) => theme.colors.main.PrimaryDark20};
  color: ${({ theme }) => theme.colors.main.PrimaryDark100};
  border-radius: 8px;
  box-sizing: border-box;

  display: flex;
  transition: ${({ theme }) => theme.animationSpeed}ms all linear;
`;
