import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const VideoOffIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M1.38042 6.60947C1.63017 5.0408 2.86592 3.92757 4.44515 3.75689C5.71339 3.61983 7.48435 3.5 9.8 3.5C11.9063 3.5 13.5619 3.59914 14.7985 3.72022C16.5752 3.89417 17.9058 5.22483 18.0798 7.00152C18.2009 8.23809 18.3 9.89372 18.3 12C18.3 14.1063 18.2009 15.7619 18.0798 16.9985C17.9058 18.7752 16.5752 20.1058 14.7985 20.2798C13.5619 20.4009 11.9063 20.5 9.8 20.5C7.48435 20.5 5.7134 20.3802 4.44515 20.2431C2.86592 20.0724 1.63017 18.9592 1.38042 17.3905C1.17812 16.1199 1 14.3374 1 12C1 9.66255 1.17812 7.8801 1.38042 6.60947Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M23 12C23 9.1197 22.7962 7.16682 22.6637 6.20129C22.6074 5.79128 22.2546 5.5 21.8408 5.5C21.433 5.5 21.0383 5.64344 20.7256 5.9052L19.0162 7.33641C18.5623 7.71641 18.3 8.27796 18.3 8.86991V15.1301C18.3 15.7221 18.5623 16.2836 19.0162 16.6636L20.7256 18.0948C21.0383 18.3566 21.433 18.5 21.8408 18.5C22.2546 18.5 22.6074 18.2087 22.6637 17.7987C22.7962 16.8332 23 14.8803 23 12Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M22 22L1 1"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
