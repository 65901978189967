import React from 'react';
import styled from 'styled-components';

import { useVerseTheme } from '../..';
import { VerseBox } from '../VerseBox';
import { VerseCardBorderRadiusSizeEnum } from './consts/cardConsts';
import { VerseCardComponentProps, VerseCardContainerProps } from './typings';

export function VerseCard({
  children,
  borderRadiusSize = VerseCardBorderRadiusSizeEnum.MEDIUM,
  ...other
}: VerseCardComponentProps) {
  const theme = useVerseTheme();
  return (
    <StyledCard
      borderRadiusSize={borderRadiusSize}
      bgcolor={theme.colors.other.White}
      border={` 1px solid ${theme.colors.main.PrimaryDark20}`}
      boxShadow={theme.shadows.primaryDark07}
      {...other}
    >
      {children}
    </StyledCard>
  );
}

const StyledCard = styled(VerseBox)<VerseCardContainerProps>`
  && {
    ${({ width }) =>
      width && `width: ${Number.isInteger(width) ? `${width}px` : width};`}
    ${({ height }) =>
      height && `height: ${Number.isInteger(height) ? `${height}px` : height};`}

  ${({ m, theme }) => m && `margin: ${theme.spacing(m)}px;`}
  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}

  ${({ p = 2, theme }) => `padding: ${theme.spacing(p)}px;`}
  ${({ pr, theme }) => pr && `padding-right: ${theme.spacing(pr)}px;`}
  ${({ pl, theme }) => pl && `padding-left: ${theme.spacing(pl)}px;`}
  ${({ pt, theme }) => pt && `padding-top: ${theme.spacing(pt)}px;`}
  ${({ pb, theme }) => pb && `padding-bottom: ${theme.spacing(pb)}px;`}

  ${({ borderRadiusSize }) => {
    switch (borderRadiusSize) {
      case VerseCardBorderRadiusSizeEnum.SMALL:
        return `border-radius: 12px;`;
      case VerseCardBorderRadiusSizeEnum.MEDIUM:
        return `border-radius: 16px;`;
      case VerseCardBorderRadiusSizeEnum.LARGE:
        return `border-radius: 24px;`;
      default:
        return ``;
    }
  }}
  box-shadow: ${({ theme, boxShadow }) =>
    boxShadow ?? theme.shadows.primaryDark07};

    display: flex;
    align-items: center;
    justify-content: center;
  
`;
