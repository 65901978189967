import React from 'react';
import styled from 'styled-components';

import { VerseHelpText } from '..';
import { VerseSelectSizeEnum } from './consts';
import {
  VerseSelectGroupHeadingComponentProps,
  VerseSelectGroupHeadingContainerProps,
} from './typings';

export function VerseSelectGroupHeading({
  children,
  selectProps,
}: VerseSelectGroupHeadingComponentProps) {
  return selectProps?.showGroupHeading ? (
    <HeadingContainer size={selectProps?.size}>
      <VerseHelpText>{children}</VerseHelpText>
    </HeadingContainer>
  ) : null;
}

const HeadingContainer = styled.div<VerseSelectGroupHeadingContainerProps>`
  display: flex;
  flex-direction: row;

  height: 28px;
  ${({ size }) => {
    switch (size) {
      case VerseSelectSizeEnum.SMALL:
        return `
          padding: 8px 8px 4px;
          `;
      case VerseSelectSizeEnum.MEDIUM:
        return `
          padding: 8px 12px 4px;
          `;
      case VerseSelectSizeEnum.LARGE:
        return `
          padding: 8px 16px 4px;
          `;
      default:
        return ``;
    }
  }}
`;
