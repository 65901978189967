import { useRef, useEffect } from 'react';
/**
 * Creates DOM element to be used as React root.
 * @returns {HTMLElement}
 *
 * This component was based on @link https://www.jayfreestone.com/writing/react-portals-with-hooks/
 */
function createRootElement(id: string) {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
}

/**
 * Appends element as last child of body.
 * @param {HTMLElement} rootElem
 */
function addRootElement(rootElem: Node) {
  /**
   * Try catch to handle Failed to execute 'insertBefore' on 'Node' error
   * @see https://sentry.io/organizations/meetbutter/issues/2463238493/events/3206054e0e91418ab67b3a1cc02b77a6/?project=5293660
   */
  try {
    document.body.insertBefore(
      rootElem,
      document?.body?.lastElementChild?.nextElementSibling ?? null,
    );
  } catch (err) {
    console.error(err);
  }
}

/**
 * Hook to create a React Portal.
 * Automatically handles creating and tearing-down the root elements (no SRR
 * makes this trivial), so there is no need to ensure the parent target already
 * exists.
 * @example
 * const target = usePortal(id, [id]);
 * return createPortal(children, target);
 * @param {String} id The id of the target container, e.g 'modal' or 'spotlight'
 * @returns {HTMLElement} The DOM node to use as the Portal target.
 */
function usePortal({ portalId }: { portalId: string }) {
  const rootElemRef = useRef<HTMLDivElement | null>(null);

  useEffect(function setupElement() {
    // Look for existing target dom element to append to
    const existingParent = document.querySelector(`#${portalId}`);
    // Parent is either a new root or the existing dom element
    const parentElem = existingParent || createRootElement(portalId);

    // If there is no existing DOM element, add a new one.
    if (!existingParent && parentElem) {
      addRootElement(parentElem);
    }

    if (rootElemRef.current) {
      // Add the detached element to the parent
      parentElem.appendChild(rootElemRef.current);
    }

    return function removeElement() {
      /**
       * try catch to handle 'removeChild' on 'Node' error
       * @see https://sentry.io/organizations/meetbutter/issues/1792599552/?project=5293660&query=is%3Aunresolved+failed+to+execute&statsPeriod=14d
       */
      try {
        /**
         * Only run remove if element exists
         */
        if (rootElemRef.current) rootElemRef.current.remove();
        if (parentElem.childNodes.length === -1) {
          parentElem.remove();
        }
      } catch (err) {
        console.error(err);
      }
    };
  }, []);

  /**
   * It's important we evaluate this lazily:
   * - We need first render to contain the DOM element, so it shouldn't happen
   *   in useEffect. We would normally put this in the constructor().
   * - We can't do 'const rootElemRef = useRef(document.createElement('div))',
   *   since this will run every single render (that's a lot).
   * - We want the ref to consistently point to the same DOM element and only
   *   ever run once.
   * @link https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
   */
  function getRootElem() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  }

  return getRootElem();
}

export default usePortal;
