import { ButterBoardErrorEnum, ButterBoardEventsEnum } from "src/butter-const";
import { TldrawApp } from "@tldraw/tldraw";
import React, { useEffect, useState } from "react";
import {
  sendContentDataToWebapp,
  sendInsertedContentMessageToWebapp,
} from "src/utils/boardToWebappUtils";

export const useEventMessageHook = ({
  app,
  enable = true,
}: {
  app?: TldrawApp | undefined;
  enable?: boolean;
}) => {
  const [boardEnabled, setBoardEnabled] = useState(true);

  const handleMessage = async (evt: MessageEvent) => {
    const data = evt?.data;

    const type = data?.butterBoardType;

    if (type) {
      try {
        switch (type) {
          case ButterBoardEventsEnum.GET_CONTENT:
            if (app?.document) {
              /**
               * This will send data from the TLDraw window to the webapp window
               * The webapp needs to handle it by listening for messages like this:
               */
              await app?.selectNone();
              const content = app?.getContent();

              if (content) {
                sendContentDataToWebapp(content);
              } else {
                sendContentDataToWebapp({
                  error: ButterBoardErrorEnum.EMPTY_BOARD,
                });
              }
            }

            break;
          case ButterBoardEventsEnum.ENABLE_BOARD:
            setBoardEnabled(true);

            break;
          case ButterBoardEventsEnum.INSERT_CONTENT:
            /** This is the import flow
             * this listener waits for the parent window to send a message
             * the message should be a stringified version of the tldraw app conent
             * the listener will draw the content on the board and send a 'contentInserted' message
             * once this message is received on the parent window, then the iframe can be closed
             */
            if (evt?.data?.content && app) {
              const content = evt.data?.content;

              await app?.insertContent(content);

              /**
               * setTimeout so that there is time for backend server to complete the save
               */
              setTimeout(sendInsertedContentMessageToWebapp, 500);
            }

            break;
          case ButterBoardEventsEnum.REPLACE_CONTENT:
            /** This is the import flow
             * this listener waits for the parent window to send a message
             * the message should be a stringified version of the tldraw app conent
             * the listener will draw the content on the board and send a 'contentInserted' message
             * once this message is received on the parent window, then the iframe can be closed
             */
            if (evt?.data?.content && app) {
              const content = evt.data?.content;
              await app?.deleteAll();

              await app?.insertContent(content);

              /**
               * setTimeout so that there is time for backend server to complete the save
               */
              setTimeout(sendInsertedContentMessageToWebapp, 500);
            }

            break;
          default: {
            console.log("do nothing", type);
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (!app || !enable) return;

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [app, enable]);

  return { boardEnabled };
};
