export enum VerseDrawerScrimColorEnum {
  NONE = 'none',
  LIGHT = 'light',
  MEDIUM = 'medium',
  DARK = 'dark',
}

export enum VerseDrawerPositionEnum {
  LEFT = 'left',
  RIGHT = 'right',
}
