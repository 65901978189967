import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const StopV2Icon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M1.37539 4.68872C1.58307 2.91566 2.91566 1.58307 4.68872 1.37539C6.34923 1.18088 8.76351 1 12 1C15.2365 1 17.6508 1.18088 19.3113 1.37539C21.0843 1.58307 22.4169 2.91566 22.6246 4.68872C22.8191 6.34923 23 8.76351 23 12C23 15.2365 22.8191 17.6508 22.6246 19.3113C22.4169 21.0843 21.0843 22.4169 19.3113 22.6246C17.6508 22.8191 15.2365 23 12 23C8.76351 23 6.34923 22.8191 4.68872 22.6246C2.91566 22.4169 1.58307 21.0843 1.37539 19.3113C1.18088 17.6508 1 15.2365 1 12C1 8.76351 1.18088 6.34923 1.37539 4.68872Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M7.16647 8.7862C7.26404 7.92078 7.92078 7.26404 8.7862 7.16647C9.55619 7.07966 10.6347 7 12 7C13.3653 7 14.4438 7.07966 15.2138 7.16647C16.0792 7.26404 16.736 7.92078 16.8335 8.7862C16.9203 9.55619 17 10.6347 17 12C17 13.3653 16.9203 14.4438 16.8335 15.2138C16.736 16.0792 16.0792 16.736 15.2138 16.8335C14.4438 16.9203 13.3653 17 12 17C10.6347 17 9.55619 16.9203 8.7862 16.8335C7.92078 16.736 7.26404 16.0792 7.16647 15.2138C7.07966 14.4438 7 13.3653 7 12C7 10.6347 7.07966 9.55619 7.16647 8.7862Z"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
