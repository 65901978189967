import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const GoogleDocV2Icon = () => (
  <>
    <path
      d="M18.5 23H5.5C4.675 23 4 22.325 4 21.5V2.5C4 1.675 4.675 1 5.5 1H14.5L20 6.5V21.5C20 22.325 19.325 23 18.5 23Z"
      fill="#3086F6"
    />
    <path d="M14.5 1L20 6.5H14.5V1Z" fill="#0C67D6" />
    <path
      d="M16.5 10.75H7.5V9.5H16.5V10.75ZM16.5 12.5H7.5V13.75H16.5V12.5ZM14 15.5H7.5V16.75H14V15.5Z"
      fill="#FDFFFF"
    />
  </>
);
