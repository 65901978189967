import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const BoxIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M23 11.9958C23 9.83482 22.8944 8.29616 22.8048 7.37983C22.7404 6.72054 22.4218 6.12188 21.9 5.71374C21.0962 5.08497 19.6927 4.09202 17.784 3.13769C15.856 2.17369 14.1259 1.48686 13.0827 1.10193C12.4191 0.857036 11.6929 0.883385 11.0442 1.16563C10.137 1.56039 8.66673 2.22475 6.84085 3.13769C4.92474 4.09574 3.32151 5.0927 2.3673 5.72107C1.74941 6.12796 1.33348 6.76614 1.24268 7.50038C1.12792 8.42845 1 9.92692 1 11.9958C1 14.2308 1.14928 15.8001 1.26999 16.7037C1.35048 17.3062 1.6567 17.8472 2.13372 18.224C2.92526 18.8492 4.35518 19.8729 6.31975 20.8552C8.31983 21.8552 10.1064 22.5565 11.1342 22.932C11.7284 23.1491 12.374 23.1491 12.9683 22.9321C13.9964 22.5566 15.7837 21.8553 17.784 20.8552C19.6932 19.9006 21.097 18.9068 21.9007 18.2777C22.422 17.8697 22.7404 17.2713 22.8048 16.6124C22.8944 15.6962 23 14.1573 23 11.9958Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0519 11.9964L12.0519 22.9396"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0519 11.9958C12.0519 11.9958 14.1363 10.6416 17.2629 9.07825C20.3895 7.51494 22.4739 6.7854 22.4739 6.7854"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0521 11.9958C12.0521 11.9958 9.9674 10.6416 6.84079 9.07825C3.71417 7.51494 1.62976 6.7854 1.62976 6.7854"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
