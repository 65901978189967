import React from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import styled, { keyframes } from "styled-components";

import { BezierCurveArrow } from "./BezierCurveArrow";
import { VerseTutorialBoxWithArrowProps } from "./typings";
import { VerseBody1, VerseBox, VerseSubtitle2, VerseIcon } from "..";
import usePortal from "../../utils/usePortal";

/**
 * VerseTutorialBoxWithArrow
 * ---
 * @description Verse UI animated popping tutorial container with arrow,
 * positioning is handled by `react-popper` & `popperjs`
 */
export function VerseTutorialBoxWithArrow({
  children,
  icon,
  open,
  title,
  subtitle,
  placement = "bottom-end",
  disableFlip = false,
}: VerseTutorialBoxWithArrowProps) {
  const [refEl, setRefEl] = React.useState(null);
  const [positionedEl, setPositionedEl] = React.useState<HTMLDivElement | null>(
    null
  );

  const flipOptions = disableFlip ? { fallbackPlacements: [placement] } : {};
  const { styles, attributes, update } = usePopper(refEl, positionedEl, {
    strategy: "fixed",
    modifiers: [
      { name: "offset", options: { offset: [0, 16] } },
      { name: "flip", options: { ...flipOptions } },
    ],
    placement,
  });

  React.useEffect(() => {
    // to make sure position is updated to ref new position
    update && update();
  }, [open, update, refEl]);

  const portalRef = usePortal({ portalId: "tutorial-portal" });

  const flexDirection = React.useMemo(() => {
    switch (placement) {
      case "right":
      case "right-start":
      case "right-end":
        return "row";
      case "bottom":
      case "bottom-start":
      case "bottom-end":
        return "column";
      case "left":
      case "left-start":
      case "left-end":
        return "row-reverse";
      case "top":
      case "top-start":
      case "top-end":
        return "column-reverse";
      default:
        return null;
    }
  }, [placement]);

  const extraSpaceWidth = React.useMemo(() => {
    switch (placement) {
      case "right":
      case "right-start":
      case "right-end":
      case "left":
      case "left-start":
      case "left-end":
        return 580;
      case "top":
      case "top-start":
      case "top-end":
      case "bottom":
      case "bottom-start":
      case "bottom-end":
        return 0;
      default:
        return ``;
    }
  }, [placement]);

  const extraSpaceHeight = React.useMemo(() => {
    switch (placement) {
      case "right":
      case "right-start":
      case "right-end":
      case "left":
      case "left-start":
      case "left-end":
        return 0;
      case "top":
      case "top-start":
      case "top-end":
      case "bottom":
      case "bottom-start":
      case "bottom-end":
        return 325;
      default:
        return ``;
    }
  }, [placement]);

  return (
    <VerseBox display="flex" flexDirection={flexDirection}>
      <>
        {React.Children.map(children, (c) =>
          React.cloneElement(c, {
            ref: setRefEl,
          })
        )}

        {disableFlip && open && (
          <VerseBox height={extraSpaceHeight} width={extraSpaceWidth} />
        )}

        {createPortal(
          <PoppingContainer
            ref={setPositionedEl}
            open={open}
            style={styles.popper}
            {...attributes.popper}
          >
            {open && (
              <>
                <BezierCurveArrow placement={placement} size={180} />
                <TutorialPositionContainer>
                  <TutorialAnimatedPresenceContainer>
                    <TutorialMotionContainer>
                      <VerseIcon size={32} {...icon} />
                      <TextContainer>
                        <VerseSubtitle2 html mb={1}>
                          {title}
                        </VerseSubtitle2>
                        <VerseBody1 html>{subtitle}</VerseBody1>
                      </TextContainer>
                    </TutorialMotionContainer>
                  </TutorialAnimatedPresenceContainer>
                </TutorialPositionContainer>
              </>
            )}
          </PoppingContainer>,
          portalRef
        )}
      </>
    </VerseBox>
  );
}

interface PoppingContainerProps {
  open: boolean;
  "data-popper-placement"?: string;
}
const PoppingContainer = styled.div<PoppingContainerProps>`
  display: flex;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndex.level.popup};

  ${({ open }) =>
    open
      ? ` visibility: visible; pointer-events: auto; `
      : ` visibility: hidden; pointer-events: none; `}

  ${(props) => {
    switch (props["data-popper-placement"]) {
      case "right":
        return `
        flex-direction: row;
        align-items: center;
        > svg { transform: rotate(180deg); }
        `;
      case "right-start":
        return `
        flex-direction: row;
        align-items: flex-start;
        > svg { transform: rotate(180deg); }
        > div { transform: translateY(15%); }
        `;
      case "right-end":
        return `
        flex-direction: row;
        align-items: flex-end;
        > svg { transform: rotate(180deg); }
        > div { transform: translateY(-15%); }
        `;
      case "bottom":
        return `
        flex-direction: column;
        > svg { transform: rotate(270deg); }
        `;
      case "bottom-start":
        return `
        flex-direction: column;
        align-items: flex-start;
        > svg { transform: rotate(270deg) translateY(50%); }
        `;
      case "bottom-end":
        return `
        flex-direction: column;
        align-items: flex-end;
        > svg { transform: rotate(270deg) translateY(-50%); }
        `;
      case "left":
        return `
        flex-direction: row-reverse;
        `;
      case "left-start":
        return `
        flex-direction: row-reverse;
        align-items: flex-start;
        > div { transform: translateY(15%); }
        `;
      case "left-end":
        return `
        flex-direction: row-reverse;
        align-items: flex-end;
        > div { transform: translateY(-15%); }
        `;
      case "top":
        return `
          flex-direction: column-reverse;
          > svg { transform: rotate(90deg); }
          `;
      case "top-start":
        return `
          flex-direction: column-reverse;
          align-items: flex-start;
          > svg { transform: rotate(90deg) translateY(-50%); }
          `;
      case "top-end":
        return `
          flex-direction: column-reverse;
          align-items: flex-end;
          > svg { transform: rotate(90deg) translateY(50%); }
          `;
      default:
        return ``;
    }
  }}
`;
const TutorialPositionContainer = styled.div``;

const rotate = keyframes`
  0% {
    transform: rotate(-4deg);
  }
  25% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-4deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(-4deg);
  }
`;

const TutorialMotionContainer = styled.div`
  padding: 16px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.main.PrimaryDark100};
  box-shadow: 4px 4px rgba(0, 0, 0, 0.2);

  background-color: ${({ theme }) => theme.colors.other.White};

  display: flex;
  flex-direction: row;

  animation: ${rotate} 2s 1s infinite;
`;
const TutorialAnimatedPresenceContainer = styled.div`
  max-width: 400px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  flex: 1;
`;
