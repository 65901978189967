import { Tldraw } from "@tldraw/tldraw";
import React from "react";
import { ToolsPanel } from "./components/ToolsPanel";
import { useEventMessageHook } from "src/hooks/useEventMessageHook";

import { useMultiplayerState } from "src/hooks/useMultiplayerState";
import { useParams } from "src/hooks/useParams";
import { TldrawContext } from "./utils/useButterTldrawApp";
import styled from "styled-components";
import { VerseLoader } from "src/verse/src";
import { ButterboardEmptyState } from "./components/ButterboardEmptyState/ButterboardEmptyState";

export const MultiplayerEditor = ({ id }: { id: string }) => {
  const { enableListener, showDefaultToolbar, hideToolbar, readOnly } =
    useParams();

  const { app, loading, ...events } = useMultiplayerState(id);
  const { boardEnabled } = useEventMessageHook({
    app,
    enable: enableListener,
  });

  if (!boardEnabled) {
    return null;
  }

  return (
    <TldrawContext.Provider value={app}>
      {!showDefaultToolbar && app && !hideToolbar ? <ToolsPanel /> : null}
      {loading ? (
        <StyledLoader>
          <VerseLoader size={64} strokePx={5} />
        </StyledLoader>
      ) : app ? (
        <ButterboardEmptyState />
      ) : null}

      <Tldraw
        disableAssets
        showPages={false}
        {...events}
        showMenu={false}
        readOnly={readOnly}
        showUI={hideToolbar ? false : !readOnly}
        showTools={hideToolbar ? false : showDefaultToolbar}
      />
    </TldrawContext.Provider>
  );
};

const StyledLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
