import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const TwitterIcon = ({ stroke }: VerseIconSVGProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5 5.10356C22.7284 5.46036 21.8976 5.70164 21.0262 5.80945C21.9161 5.25501 22.599 4.37585 22.9207 3.32856C22.0874 3.84194 21.1667 4.21542 20.183 4.41563C19.3991 3.54418 18.2787 3 17.0387 3C14.6609 3 12.7319 5.00858 12.7319 7.48562C12.7319 7.83729 12.7689 8.17869 12.8428 8.50725C9.2621 8.31986 6.08813 6.53588 3.96188 3.81883C3.59086 4.48366 3.37885 5.25501 3.37885 6.07641C3.37885 7.63194 4.13938 9.00522 5.29556 9.80994C4.59051 9.78812 3.9249 9.58405 3.3431 9.25036V9.30554C3.3431 11.4797 4.8284 13.2932 6.80058 13.7039C6.43942 13.8091 6.05855 13.8618 5.66535 13.8618C5.38801 13.8618 5.11683 13.8348 4.85429 13.7822C5.4028 15.5636 6.99286 16.8612 8.87876 16.8958C7.40455 18.0997 5.54578 18.8171 3.52799 18.8171C3.1804 18.8171 2.8365 18.7966 2.5 18.7555C4.40685 20.0261 6.67239 20.7692 9.10433 20.7692C17.03 20.7692 21.3626 13.9349 21.3626 8.0067C21.3626 7.81162 21.3589 7.61653 21.3516 7.4253C22.1934 6.79256 22.9244 6.00325 23.5 5.10356Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
  </>
);
