import {
  VersePopoverPlacement,
  VersePopoverTransformVariants,
} from '../typings';

function getPopoverTransformOrigin(
  placement?: VersePopoverPlacement,
): VersePopoverTransformVariants {
  let transformOrigin = {};
  switch (placement) {
    case 'right-start':
      transformOrigin = { originX: 0, originY: 0 };
      break;
    case 'right':
      transformOrigin = { originX: 0, originY: 0.5 };
      break;
    case 'right-end':
      transformOrigin = { originX: 0, originY: 1 };
      break;
    case 'left-start':
      transformOrigin = { originX: 1, originY: 0 };
      break;
    case 'left':
      transformOrigin = { originX: 1, originY: 0.5 };
      break;
    case 'left-end':
      transformOrigin = { originX: 1, originY: 1 };
      break;
    case 'bottom-start':
      transformOrigin = { originX: 0, originY: 0 };
      break;
    case 'bottom':
      transformOrigin = { originX: 0.5, originY: 0 };
      break;
    case 'bottom-end':
      transformOrigin = { originX: 1, originY: 0 };
      break;
    case 'top-start':
      transformOrigin = { originX: 0, originY: 1 };
      break;
    case 'top':
      transformOrigin = { originX: 0.5, originY: 1 };
      break;
    case 'top-end':
      transformOrigin = { originX: 1, originY: 1 };
      break;
    default:
      break;
  }

  return {
    origin: { scale: 0, ...transformOrigin },
    scaled: { scale: 1, ...transformOrigin },
  };
}

export default getPopoverTransformOrigin;
