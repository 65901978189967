export enum SOUNDBOARD_CLIP_AUDIO_ID {
  Airhorn = 'SOUNDBOARD_AIRHORN',
  Trombone = 'SOUNDBOARD_TROMBONE',
  Hallelujah = 'SOUNDBOARD_HALLELUJAH',
  Applause = 'SOUNDBOARD_APPLAUSE',
  Buzzer = 'SOUNDBOARD_BUZZER',
  Crickets = 'SOUNDBOARD_CRICKETS',
  Rimshot = 'SOUNDBOARD_RIMSHOT',
  Dramatic = 'SOUNDBOARD_DRAMATIC',
  BoxingBell = 'SOUNDBOARD_BOXING_BELL',
  SurpriseV1 = 'SOUNDBOARD_SURPRISE_V1',
  SurpriseV2 = 'SOUNDBOARD_SURPRISE_V2',
  SurpriseV3 = 'SOUNDBOARD_SURPRISE_V3',
  SurpriseV4 = 'SOUNDBOARD_SURPRISE_V4',

  /**
   * page 2
   */
  TqDaddy = 'SOUNDBOARD_TQ_DADDY',
  AnimeWow = 'SOUNDBOARD_ANIME_WOW',
  CannedLaugh = 'SOUNDBOARD_CANNED_LAUGH',

  MarioCoin = 'SOUNDBOARD_MARIO_COIN',
  Bruh = 'SOUNDBOARD_BRUH',
}

export const SOUNDBOARD_CLIPS_OBJECT = {
  [SOUNDBOARD_CLIP_AUDIO_ID.Hallelujah]:
    'https://files.butter.us/music/Clips/Halleluja.mp3',
  [SOUNDBOARD_CLIP_AUDIO_ID.Buzzer]:
    'https://files.butter.us/music/Clips/Buzzer.mp3',
  [SOUNDBOARD_CLIP_AUDIO_ID.Applause]:
    'https://files.butter.us/music/Clips/Applause.mp3',
  [SOUNDBOARD_CLIP_AUDIO_ID.Crickets]:
    'https://files.butter.us/music/Clips/Cricket.mp3',
  [SOUNDBOARD_CLIP_AUDIO_ID.Rimshot]:
    'https://files.butter.us/music/Clips/Rimshot.mp3',
  [SOUNDBOARD_CLIP_AUDIO_ID.Trombone]:
    'https://files.butter.us/music/Clips/Sad-trombone.mp3',
  [SOUNDBOARD_CLIP_AUDIO_ID.Airhorn]:
    'https://files.butter.us/music/Clips/Airhorn.mp3',
  [SOUNDBOARD_CLIP_AUDIO_ID.BoxingBell]:
    'https://files.butter.us/music/Clips/boxingBell.mp3',
  [SOUNDBOARD_CLIP_AUDIO_ID.Dramatic]:
    'https://files.butter.us/music/Clips/Horror.mp3',
  [SOUNDBOARD_CLIP_AUDIO_ID.SurpriseV1]:
    'https://files.butter.us/music/Clips/Butter-bingo.mp3',
  [SOUNDBOARD_CLIP_AUDIO_ID.SurpriseV2]:
    'https://files.butter.us/music/Clips/Butter-ooh-yeah.mp3',
  [SOUNDBOARD_CLIP_AUDIO_ID.SurpriseV3]:
    'https://files.butter.us/music/Clips/Butter-smooth.mp3',
  [SOUNDBOARD_CLIP_AUDIO_ID.SurpriseV4]:
    'https://files.butter.us/music/Clips/Butter-smoothlike.mp3',

  /**
   * page 2
   */
  [SOUNDBOARD_CLIP_AUDIO_ID.TqDaddy]:
    'https://files.butter.us/music/Clips/thankyoudaddy.mp3',
  [SOUNDBOARD_CLIP_AUDIO_ID.AnimeWow]:
    'https://files.butter.us/music/Clips/anime-wow.mp3',
  [SOUNDBOARD_CLIP_AUDIO_ID.CannedLaugh]:
    'https://files.butter.us/music/Clips/canned-laughter.mp3',
  [SOUNDBOARD_CLIP_AUDIO_ID.MarioCoin]:
    'https://files.butter.us/music/Clips/mario-coin.mp3',
  [SOUNDBOARD_CLIP_AUDIO_ID.Bruh]:
    'https://files.butter.us/music/Clips/bruh.mp3',
};
