import { keyframes } from 'styled-components';

export interface ShadowPulseKeyframesProps {
  shadowPropsFrom?: string;
  shadowPropsTo?: string;
  color: string;
}

export const shadowPulseKeyframes = ({
  shadowPropsFrom = '0px 0px 2px 1px',
  shadowPropsTo = '0px 0px 8px 4px',
  color,
}: ShadowPulseKeyframesProps) => keyframes`
0% {
  box-shadow: ${shadowPropsFrom} ${color};
}
50% {
  box-shadow: ${shadowPropsTo} ${color};
}
100% {
  box-shadow: ${shadowPropsFrom} ${color};
}
`;
