// DailyEvent type is cloned from daily js library
type DailyEvent =
  | "loading"
  | "load-attempt-failed"
  | "loaded"
  | "started-camera"
  | "camera-error"
  | "joining-meeting"
  | "joined-meeting"
  | "left-meeting"
  | "participant-joined"
  | "participant-updated"
  | "participant-left"
  | "track-started"
  | "track-stopped"
  | "recording-started"
  | "recording-stopped"
  | "recording-stats"
  | "recording-error"
  | "recording-upload-completed"
  | "recording-data"
  | "transcription-started"
  | "transcription-stopped"
  | "transcription-error"
  | "app-message"
  | "local-screen-share-started"
  | "local-screen-share-stopped"
  | "active-speaker-change"
  | "active-speaker-mode-change"
  | "network-quality-change"
  | "network-connection"
  | "fullscreen"
  | "exited-fullscreen"
  | "error"
  | "nonfatal-error"
  | "click"
  | "mousedown"
  | "mouseup"
  | "mouseover"
  | "mousemove"
  | "touchstart"
  | "touchmove"
  | "touchend"
  | "live-streaming-started"
  | "live-streaming-stopped"
  | "live-streaming-error"
  | "lang-updated"
  | "remote-media-player-started"
  | "remote-media-player-stopped"
  | "remote-media-player-updated"
  | "access-state-updated"
  | "meeting-session-updated"
  | "waiting-participant-added"
  | "waiting-participant-updated"
  | "waiting-participant-removed"
  | "theme-updated"
  | "receive-settings-updated"
  | "input-settings-updated"
  | "show-local-video-changed";

export const DAILY_CO_EVENTS: Record<string, DailyEvent> = {
  // Meeting events
  loading: "loading",
  loaded: "loaded",
  loadAttemptFailed: "load-attempt-failed",
  startedCamera: "started-camera",
  cameraError: "camera-error",
  joiningMeeting: "joining-meeting",
  joinedMeeting: "joined-meeting",
  leftMeeting: "left-meeting",
  activeSpeakerChange: "active-speaker-change",
  error: "error",

  // Participant events
  participantJoined: "participant-joined",
  participantUpdated: "participant-updated",
  participantLeft: "participant-left",
  appMessage: "app-message",
  trackStarted: "track-started",
  trackStopped: "track-stopped",

  // Network events
  networkConnection: "network-connection",
  networkQualityChange: "network-quality-change",

  // Recording events
  recordingStarted: "recording-started",
  recordingStopped: "recording-stopped",
  recordingStats: "recording-stats",
  recordingError: "recording-error",

  // Live streaming events
  liveStreamingStarted: "live-streaming-started",
  liveStreamingStopped: "live-streaming-stopped",
  liveStreamingError: "live-streaming-error",

  // Settings and devices updates
  receiveSettingsUpdated: "receive-settings-updated",

  // input setting updated events
  inputSettingsUpdated: "input-settings-updated",

  // non fatal error
  nonfatalError: "nonfatal-error",
};

type DailyCoAppStates =
  | "STATE_IDLE"
  | "STATE_CREATING"
  | "STATE_JOINING"
  | "STATE_JOINED"
  | "STATE_LEAVING"
  | "STATE_ERROR";

export const DAILY_CO_STATES: Record<string, DailyCoAppStates> = {
  STATE_IDLE: "STATE_IDLE",
  STATE_CREATING: "STATE_CREATING",
  STATE_JOINING: "STATE_JOINING",
  STATE_JOINED: "STATE_JOINED",
  STATE_LEAVING: "STATE_LEAVING",
  STATE_ERROR: "STATE_ERROR",
};

export enum MediaDeviceKindEnum {
  audioinput = "audioinput",
  audiooutput = "audiooutput",
  videoinput = "videoinput",
  videoquality = "videoquality",
}

export enum SimulcastLayerEnum {
  "high" = 2,
  "medium" = 1,
  "low" = 0,
}
