import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const CheckV2Icon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M21.9153 3.6556C22.2754 3.9628 22.5428 4.27735 22.7322 4.53977C22.9971 4.90678 22.9254 5.39014 22.633 5.73572L9.59361 21.1502C9.0122 21.8375 7.96051 21.8613 7.34859 21.201L1.2882 14.6614C0.982891 14.3319 0.888997 13.8571 1.13135 13.4789C1.30149 13.2134 1.54364 12.8912 1.87564 12.5619C2.21328 12.227 2.54759 11.9975 2.82257 11.8438C3.20587 11.6296 3.65979 11.7635 3.957 12.0867L7.48646 15.9253C7.8926 16.3671 8.59363 16.354 8.98309 15.8975L19.7482 3.27913C20.0431 2.9334 20.511 2.78484 20.9166 2.98975C21.205 3.13544 21.5565 3.3495 21.9153 3.6556Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
