import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const FacebookIcon = ({ stroke }: VerseIconSVGProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6485 21.5H4.49865C3.45 21.5 2.5 20.55 2.5 19.5013V4.49865C2.5 3.45 3.45 2.5 4.49865 2.5H19.5014C20.55 2.5 21.5 3.45 21.5 4.49865V19.5013C21.5 20.55 20.55 21.5 19.5014 21.5H15.6097V14.1422H18.0794L18.4492 11.2747H15.6097V9.44397C15.6097 8.61376 15.8403 8.048 17.0308 8.048L18.5492 8.04734V5.48264C18.2866 5.4477 17.3852 5.36962 16.3366 5.36962C14.1473 5.36962 12.6485 6.70594 12.6485 9.15998V11.2747H10.1725V14.1422H12.6485V21.5Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
  </>
);
