import {
  easeInKeyframes,
  easeOutKeyframes,
  fadeInKeyframes,
  fadeOutKeyframes,
  opacityGlowKeyframes,
  scalePopKeyframes,
  shadowPulseKeyframes,
  shakeKeyframes,
  spinKeyframes,
  floatingButterKeyframes,
  slideKeyframes,
} from './utils';

export const VerseKeyframes = {
  spinKeyframes,
  shadowPulseKeyframes,
  shakeKeyframes,
  fadeInKeyframes,
  fadeOutKeyframes,
  scalePopKeyframes,
  opacityGlowKeyframes,
  easeInKeyframes,
  easeOutKeyframes,
  floatingButterKeyframes,
  slideKeyframes,
};
