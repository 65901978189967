import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const MegaphoneIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M1.13999 9.24697C1.23505 8.38153 1.89784 7.73014 2.7638 7.64006C3.47749 7.56582 4.45552 7.5 5.68 7.5C7.29546 7.5 8.48198 7.61457 9.19841 7.71188C9.66814 7.77569 9.99353 8.18587 9.98784 8.65988C9.97953 9.35278 9.97 10.4785 9.97 12C9.97 13.3489 9.97749 14.3868 9.98498 15.0877C9.99162 15.7087 9.56459 16.2453 8.94814 16.3203C8.21893 16.409 7.11984 16.5 5.68 16.5C4.45552 16.5 3.47749 16.4342 2.7638 16.3599C1.89784 16.2699 1.23505 15.6185 1.13999 14.753C1.06514 14.0715 1 13.1485 1 12C1 10.8515 1.06514 9.92852 1.13999 9.24697Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M10 8.41433C10 7.91395 10.1876 7.43174 10.5257 7.06288L14.7099 2.49829C15.5897 1.53855 17.1644 2.07835 17.2403 3.37808C17.3685 5.57252 17.5 8.64276 17.5 12C17.5 15.3572 17.3685 18.4275 17.2403 20.6219C17.1644 21.9216 15.5897 22.4614 14.7099 21.5017L10.5257 16.9371C10.1876 16.5683 10 16.0861 10 15.5857V8.41433Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M20.4758 12.4157L22.9758 12.4013M19.9586 9.41862L22.4499 7.90427M19.9931 15.4185L22.5016 16.9041"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.89216 17.2353C3.71453 16.9022 3.95587 16.5 4.33333 16.5H6.5C6.86931 16.5 7.20856 16.7035 7.38235 17.0294L8.71569 19.5294C9.07094 20.1955 8.58825 21 7.83333 21H6.5C6.13069 21 5.79144 20.7965 5.61765 20.4706L3.89216 17.2353Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
