import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const LockIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M6 8.5V7.13636V7C6 3.68629 8.68629 1 12 1V1V1C15.3137 1 18 3.68629 18 7V7.13636V8.5"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
    />
    <path
      d="M2.22717 11.3441C2.43656 9.6033 3.82505 8.3831 5.57228 8.23719C7.07232 8.11193 9.19696 8 12 8C14.803 8 16.9277 8.11193 18.4277 8.23719C20.1749 8.3831 21.5634 9.6033 21.7728 11.3441C21.9004 12.4044 22 13.7824 22 15.5C22 17.2176 21.9004 18.5956 21.7728 19.6559C21.5634 21.3967 20.1749 22.6169 18.4277 22.7628C16.9277 22.8881 14.803 23 12 23C9.19696 23 7.07232 22.8881 5.57228 22.7628C3.82505 22.6169 2.43656 21.3967 2.22717 19.6559C2.09963 18.5956 2 17.2176 2 15.5C2 13.7824 2.09963 12.4044 2.22717 11.3441Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
    />
    <circle
      cx="12"
      cy="14"
      r="2"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
    />
    <path
      d="M12 16V19"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
      strokeLinecap="round"
    />
  </>
);
