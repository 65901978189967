import React, { useRef } from 'react';
import styled from 'styled-components';
import validator from 'validator';

import {
  VerseBody2,
  VerseHelpText,
  VerseIcon,
  VerseIconIdEnum,
  VerseUserAvatar,
  VerseBody3,
} from '..';
import { useVerseTheme } from '../..';
import { ErrorLabelContainer, ErrorLabelsContainer } from '../VerseInput';
import {
  AssumedEmailContainerProps,
  OuterContainerProps,
  VerseMultiEmailInputComponentProps,
  VerseMultiEmailInputSuggestedListSingleProps,
} from './typings';

export function VerseMultiEmailInput({
  assumedEmailList,
  setAssumedEmailList,
  handleOnChange,
  handleOnSubmit,
  inputPlaceholder,
  duplicateErrorMessage,
  validationKeys,
  errorLabels,
  testId,
  suggestedList,
  autoFocus,
  ...other
}: VerseMultiEmailInputComponentProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useVerseTheme();

  const [inputValue, setInputValue] = React.useState('');
  const [isFocused, setIsFocused] = React.useState(false);
  const [hasDuplicate, setHasDuplicate] = React.useState(false);
  const [cachedSuggestedList, setCachedSuggestedList] = React.useState(
    [] as VerseMultiEmailInputSuggestedListSingleProps[],
  );
  const onFocus = () => setIsFocused(true);
  const onBlur = () => {
    addValueToAssumedList();
    setIsFocused(false);
  };
  const removeAssumedEmail = (email: string) => {
    setAssumedEmailList(old => old.filter(oldEmail => oldEmail !== email));
    if (hasDuplicate) {
      setHasDuplicate(false);
    }
  };
  const addValueToAssumedList = () => {
    const trimmedInputValue = inputValue?.trim() ?? '';
    if (trimmedInputValue.length > 0) {
      setAssumedEmailList(old => [...old, trimmedInputValue]);
      setInputValue('');
    }
  };

  const addSuggestedValueToAssumedList = (email: string) => {
    const isDuplicate = assumedEmailList.some(eachEmail => eachEmail === email);
    setHasDuplicate(isDuplicate);
    if (!isDuplicate) {
      const suggestedItem = (suggestedList ?? []).find(
        eachItem => eachItem?.email === email,
      );
      if (suggestedItem) {
        setCachedSuggestedList(prev => {
          const isExisted = prev.some(eachItem => eachItem?.email === email);

          if (isExisted) {
            return prev;
          }
          return [...prev, suggestedItem];
        });
      }
      setAssumedEmailList(old => [...old, email]);
    }
    setInputValue('');
  };

  const editEmail = (email: string) => {
    setInputValue(email);
    removeAssumedEmail(email);
    inputRef?.current?.focus();
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    function inputExistHandler() {
      switch (e.key) {
        case 'Enter':
        case 'Tab':
        case ' ':
        case ',': {
          e.preventDefault();
          const isDuplicate = assumedEmailList.some(
            email => email === inputValue,
          );
          setHasDuplicate(isDuplicate);
          /** If it's duplicate, don't add it to the list and clear the input */
          if (isDuplicate) {
            setInputValue('');
            return;
          }
          addValueToAssumedList();
          break;
        }
        default:
          break;
      }
    }

    function inputEmptyHandler() {
      if (e.key === 'Enter') {
        e.preventDefault();
        inputRef?.current?.blur();
        if (validationKeys.length < 1) {
          handleOnSubmit && handleOnSubmit();
        }
      }

      if (e.key === 'Backspace' && inputValue.length < 1) {
        const lastEmail = assumedEmailList[assumedEmailList.length - 1];
        lastEmail && editEmail(lastEmail);
      }
    }

    const trimmedInputValue = inputValue?.trim() ?? '';
    if (trimmedInputValue.length > 0) {
      inputExistHandler();
    } else {
      inputEmptyHandler();
    }

    if (hasDuplicate) {
      setHasDuplicate(false);
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e?.target?.value ?? '');
    handleOnChange && handleOnChange(e.target.value);
  };
  const onPaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    // only trigger on create mode
    const pasteString = e.clipboardData.getData('Text');
    if (pasteString) {
      const splittedByCommaStr = pasteString.split(',').map(str => str.trim());
      splittedByCommaStr.forEach(str => {
        const splittedBySpace = str.split(' ');
        setAssumedEmailList(old => [...old, ...splittedBySpace]);
      });
    }
  };

  return (
    <>
      <OuterContainer isFocused={isFocused} {...other}>
        {assumedEmailList.map(email => {
          const suggestedItem = (cachedSuggestedList ?? []).find(
            eachItem => eachItem?.email === email,
          );
          const onRemoveClick = (e?: React.MouseEvent) => {
            e?.stopPropagation();
            removeAssumedEmail(email);
          };
          const onEmailClick = () => {
            editEmail(email);
          };
          return (
            <AssumedEmailOuterContainer key={`assumedEmailItem-${email}`}>
              <AssumedEmailContainer
                isValid={validator.isEmail(email)}
                onClick={onEmailClick}
              >
                <VerseBody2 linkify={false} mr={1}>
                  {suggestedItem ? suggestedItem?.label : email}
                </VerseBody2>
                <VerseIcon
                  iconId={VerseIconIdEnum.CLOSE}
                  size={8}
                  onClick={onRemoveClick}
                />
              </AssumedEmailContainer>
            </AssumedEmailOuterContainer>
          );
        })}

        <StyledInput
          ref={inputRef}
          placeholder={
            assumedEmailList.length < 1
              ? inputPlaceholder || 'Type in emails separated by commas'
              : ''
          }
          value={inputValue}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          data-testid={testId}
          onPaste={onPaste}
          autoFocus={autoFocus}
        />

        {suggestedList?.length && isFocused ? (
          <SuggestedListContainer>
            {suggestedList?.map(each => {
              const handleOnClick = () => {
                addSuggestedValueToAssumedList(each.email);
              };
              return (
                <SuggestedListItem
                  key={each?.email}
                  onMouseDown={handleOnClick}
                >
                  <VerseUserAvatar
                    imgURL={each?.imgUrl}
                    mr={0.75}
                    displayName={each.label}
                  />

                  <VerseBody3>{each.label}</VerseBody3>
                </SuggestedListItem>
              );
            })}
          </SuggestedListContainer>
        ) : null}
      </OuterContainer>
      <ErrorLabelsContainer
        hasErrors={hasDuplicate || validationKeys.length > 0}
        id="verse-multil-email-input-error-labels-container"
      >
        {hasDuplicate ? (
          <ErrorLabelContainer>
            <VerseHelpText color={theme.colors.main.PrimaryDark100} mr={0.5}>
              {duplicateErrorMessage ||
                `You already entered this email address. The duplicate one has
                  been removed.`}
            </VerseHelpText>
          </ErrorLabelContainer>
        ) : null}
        {validationKeys.map(validationKey => (
          <ErrorLabelContainer key={`validation-${validationKey}`}>
            <VerseHelpText color={theme.colors.other.Danger100} mr={0.5}>
              {errorLabels[validationKey]}
            </VerseHelpText>
          </ErrorLabelContainer>
        ))}
      </ErrorLabelsContainer>
    </>
  );
}

const SuggestedListContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.primaryDark10};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.main.PrimaryDark20};
  padding: ${({ theme }) =>
    `${theme.spacing(1.25)}px ${theme.spacing(0.75)}px`};
  background: ${({ theme }) => theme.colors.other.White};
  max-height: 128px;
  overflow: auto;
  z-index: 1;
`;
const SuggestedListItem = styled.div`
  display: flex;
  width: 100%;
  border-radius: 10px;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(0.5)}px ${theme.spacing(1)}px`};
  transition: background-color ${({ theme }) => theme.animationSpeed}ms
    ease-in-out;

  :hover {
    background-color: ${({ theme }) => theme.colors.other.Sand03};
  }

  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
  }
`;
const OuterContainer = styled.div<OuterContainerProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;

  border: 1px solid
    ${({ isFocused, theme }) =>
      isFocused
        ? theme.colors.main.PrimaryDark100
        : theme.colors.main.PrimaryDark30};
  border-radius: 12px;
  padding: 5px 10px;
  min-height: 80px;
  background-color: ${({ theme }) => theme.colors.other.White};

  transition: border-color 0.2s linear;

  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}
  ${({ width }) =>
    width && `width: ${Number.isInteger(width) ? `${width}px` : width};`}
`;
const AssumedEmailOuterContainer = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-right: ${({ theme }) => theme.spacing(1.25)}px;
`;
const AssumedEmailContainer = styled.div<AssumedEmailContainerProps>`
  height: 35px;
  border-radius: 12px;
  padding: 0 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ isValid, theme }) =>
    isValid ? theme.colors.other.Blue10 : theme.colors.other.Danger10};

  cursor: pointer;
  transition: background-color 0.1s linear;
  :hover {
    background-color: ${({ isValid, theme }) =>
      isValid ? theme.colors.other.Blue25 : theme.colors.other.Danger25};
  }
`;

const StyledInput = styled.input`
  flex: 1;
  height: 40px;
  font-size: 15px;
  font-family: GTEestiDisplay;
  font-weight: 300;
  outline: none;
  border: none;
  :disabled {
    background: ${({ theme }) => theme.colors.other.White};
    cursor: not-allowed;
  }
`;
