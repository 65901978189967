import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const QuestionIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M21.1078 18.4473C21.0789 18.3127 21.1073 18.1724 21.1833 18.0576C22.3315 16.3204 23 14.2383 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C13.8668 23 15.625 22.535 17.165 21.7144C17.4197 21.5787 17.7184 21.545 17.9922 21.6363L21.3221 22.7463C21.6586 22.8584 21.9895 22.5622 21.9152 22.2154L21.1078 18.4473Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
    />
    <path
      d="M8.1001 8.9C8.1001 8.9 8.1001 5 12.1001 5C15.4001 5 16.1001 7.8 16.1001 8.9C16.1001 11.1 14.7001 11.8 13.9001 12.5C13.0001 13.3 12.1001 13.8 12.1001 15.3"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12 18.5 T12 18.5"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth) * 1.25}
      strokeLinecap="round"
    />
  </>
);
