export enum VerseTextAreaSizeEnum {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum VerseTextAreaDataValueEnum {
  FOCUS = 'focus',
  INVALID = 'invalid',
  FILLED = 'filled',
}

export const VERSE_TEXT_AREA_ICON_SCALE_VARIANTS = {
  HIDDEN: { scale: 0 },
  VISIBLE: { scale: 1 },
};
