import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const WhiteboardIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M1.27587 4.86391C1.50422 3.13274 2.89687 1.92731 4.6366 1.77747C6.29856 1.63433 8.72949 1.5 12 1.5C15.2705 1.5 17.7014 1.63433 19.3634 1.77747C21.1031 1.92731 22.4958 3.13274 22.7241 4.86391C22.8766 6.02016 23 7.55618 23 9.5C23 11.4438 22.8766 12.9798 22.7241 14.1361C22.4958 15.8673 21.1031 17.0727 19.3634 17.2225C17.7014 17.3657 15.2705 17.5 12 17.5C8.72949 17.5 6.29856 17.3657 4.6366 17.2225C2.89687 17.0727 1.50422 15.8673 1.27587 14.1361C1.12336 12.9798 1 11.4438 1 9.5C1 7.55618 1.12336 6.02016 1.27587 4.86391Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth) * 1.25}
      strokeLinecap="round"
    />
    <path
      d="M5.5 20C5.5 20 8 20.2 12.5 20.2C17 20.2 19.5 20 19.5 20"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth) * 1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.37252 5.29999C7.28078 5.77063 5.84437 9.26347 6.28306 9.38797C6.46797 9.44044 6.69728 9.33904 6.86882 9.27413C7.32333 9.10214 7.78173 8.88948 8.22038 8.67128C9.79143 7.88977 11.3967 7.12441 13.0933 6.78512C13.565 6.69078 14.0478 6.60723 14.5269 6.67645C14.7009 6.70159 15.0623 6.76568 15.1446 6.97917C15.2265 7.19175 15.0675 7.41247 14.9531 7.55614C14.2448 8.44589 13.4439 9.21599 12.6967 10.0607C12.286 10.5249 11.7276 11.0694 11.5913 11.7476C11.507 12.1671 11.5913 12.5818 12.0677 12.9611C12.544 13.3403 15.2808 13.6099 17.3369 12.5818"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth) * 1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 17.5L20 23.5"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth) * 1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 23.5L9 17.5"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth) * 1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
