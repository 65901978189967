import React from 'react';
import styled from 'styled-components';

import { VerseIcon } from '../VerseIcon';
import { VerseButtonTextLarge } from '../VerseTypography';
import { ClickableTabContainerProps, VerseTabComponentProps } from './typings';

export function VerseTab({
  title,
  testId,
  icon,
  ...other
}: VerseTabComponentProps) {
  return (
    <ClickableTabContainer {...other} data-testid={testId}>
      {icon?.iconId ? <VerseIcon size={20} mr={1.25} {...icon} /> : null}
      <VerseButtonTextLarge component="span">{title}</VerseButtonTextLarge>
    </ClickableTabContainer>
  );
}

const ClickableTabContainer = styled.div<ClickableTabContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 40px;
  padding: 0 16px;
  border-radius: 12px;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.other.Sand08RGBA : 'transparent'};
  cursor: pointer;

  transition: all ${({ theme }) => theme.animationSpeed}ms linear;
  > span {
    opacity: ${({ selected }) => (selected ? 1 : 0.7)};
    transition: all ${({ theme }) => theme.animationSpeed}ms linear;
  }

  ${({ m, theme }) => m && `margin: ${theme.spacing(m)}px;`}
  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}
  :hover {
    background-color: ${({ selected, theme }) =>
      selected ? theme.colors.other.Sand05RGBA : 'transparent'};
    > span {
      opacity: 1;
    }
  }
`;
