import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const CSVIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M3 12C3 15.2464 3.14891 17.6656 3.3086 19.3266C3.47797 21.0881 4.73781 22.4664 6.49343 22.6887C7.82354 22.857 9.64596 23 12 23C14.354 23 16.1765 22.8571 17.5066 22.6887C19.2622 22.4664 20.522 21.0881 20.6914 19.3266C20.8511 17.6656 21 15.2464 21 12C21 10.3482 20.9615 8.91053 20.9025 7.67796C20.8512 6.60388 20.3401 5.60828 19.5197 4.91321L16.0183 1.94692C15.3573 1.38697 14.5327 1.05154 13.6668 1.02498C13.144 1.00894 12.5886 1 12 1C9.64596 1 7.82355 1.14295 6.49343 1.31134C4.73781 1.53359 3.47797 2.9119 3.3086 4.67341C3.14891 6.33438 3 8.75355 3 12Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M19.5197 4.91322L15.7639 1.73139C15.2681 1.31134 14.6494 1.06058 14 1.03632V7C14 7.55229 14.4477 8 15 8L20.9173 8.00001C20.9125 7.89124 20.9076 7.78399 20.9026 7.67825C20.8512 6.60417 20.3401 5.60829 19.5197 4.91322Z"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M7.75951 17.1275C7.13618 17.1275 6.58084 17 6.09351 16.745C5.60618 16.4843 5.22651 16.1217 4.95451 15.657C4.68251 15.1867 4.54651 14.6427 4.54651 14.025C4.54651 13.4073 4.68251 12.8662 4.95451 12.4015C5.22651 11.9312 5.60618 11.5685 6.09351 11.3135C6.58084 11.0528 7.13618 10.9225 7.75951 10.9225C8.55284 10.9225 9.26118 11.1407 9.88451 11.577L9.25551 12.529C8.78518 12.2003 8.28368 12.036 7.75101 12.036C7.36568 12.036 7.02001 12.1182 6.71401 12.2825C6.40801 12.4468 6.16718 12.6792 5.99151 12.9795C5.82151 13.2798 5.73651 13.6283 5.73651 14.025C5.73651 14.4217 5.82151 14.7702 5.99151 15.0705C6.16718 15.3708 6.40801 15.6032 6.71401 15.7675C7.02001 15.9318 7.36568 16.014 7.75101 16.014C8.28368 16.014 8.78518 15.8497 9.25551 15.521L9.88451 16.473C9.26118 16.9093 8.55284 17.1275 7.75951 17.1275Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M12.3223 10.9225C12.8833 10.9225 13.4642 11.0613 14.0648 11.339L13.5973 12.3335C13.1213 12.0898 12.6992 11.968 12.3308 11.968C11.9172 11.968 11.7103 12.1125 11.7103 12.4015C11.7103 12.5148 11.7443 12.6168 11.8123 12.7075C11.8803 12.7925 11.9993 12.8945 12.1693 13.0135L13.1978 13.736C13.5435 13.974 13.7985 14.2233 13.9628 14.484C14.1328 14.739 14.2178 15.0478 14.2178 15.4105C14.2178 15.9658 14.0365 16.3908 13.6738 16.6855C13.3168 16.9802 12.821 17.1275 12.1863 17.1275C11.8123 17.1275 11.4468 17.0765 11.0898 16.9745C10.7385 16.8725 10.4183 16.7308 10.1293 16.5495L10.6138 15.555C11.2032 15.9007 11.7387 16.0735 12.2203 16.0735C12.4923 16.0735 12.6992 16.0197 12.8408 15.912C12.9882 15.7987 13.0618 15.6513 13.0618 15.47C13.0618 15.3283 13.0137 15.2008 12.9173 15.0875C12.821 14.9742 12.668 14.841 12.4583 14.688L11.4978 13.9995C11.1805 13.7785 10.9425 13.5433 10.7838 13.294C10.6308 13.039 10.5543 12.7528 10.5543 12.4355C10.5543 11.9652 10.7073 11.5968 11.0133 11.3305C11.325 11.0585 11.7613 10.9225 12.3223 10.9225Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M18.5026 11.05H19.7776L17.5761 17H16.4796L14.2781 11.05H15.5531L17.0151 15.538H17.0406L18.5026 11.05Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
  </>
);
