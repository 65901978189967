import React from 'react';
import styled from 'styled-components';

import { VerseIcon, VerseIconIdEnum } from '..';
import { VerseKeyframes } from '../VerseKeyframes/VerseKeyframes';
import { VerseLoaderComponentProps, VerseLoaderContainerProps } from './typings';

export const VerseLoader = ({
  size = 24,
  strokePx,
  stroke,
  ...other
}: VerseLoaderComponentProps) => {
  return (
    <StyledTrayButton {...other}>
      <VerseIcon
        iconId={VerseIconIdEnum.LOADER}
        size={size}
        strokePx={strokePx}
        stroke={stroke}
      />
    </StyledTrayButton>
  );
};
const loadingAnimation = VerseKeyframes.spinKeyframes({
  fromDeg: 0,
  toDeg: 360,
});
const StyledTrayButton = styled.div<VerseLoaderContainerProps>`
  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}

  svg {
    animation: ${loadingAnimation} 1s infinite;
  }
`;
