import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const FigmaIcon = () => (
  <>
    <path
      d="M8.66617 22.9999C10.6899 22.9999 12.3323 21.3574 12.3323 19.3337V15.6675H8.66617C6.64245 15.6675 5 17.3099 5 19.3337C5 21.3574 6.64245 22.9999 8.66617 22.9999Z"
      fill="#0ACF83"
    />
    <path
      d="M5.00146 11.9984C5.00146 9.97471 6.6439 8.33228 8.66761 8.33228H12.3338V15.6646H8.66761C6.6439 15.6646 5.00146 14.0221 5.00146 11.9984Z"
      fill="#A259FF"
    />
    <path
      d="M5.00159 4.66617C5.00159 2.64245 6.64402 1 8.66773 1H12.3339V8.33235H8.66773C6.64402 8.33235 5.00159 6.6899 5.00159 4.66617Z"
      fill="#F24E1E"
    />
    <path
      d="M12.3337 1H15.9999C18.0237 1 19.6661 2.64245 19.6661 4.66617C19.6661 6.6899 18.0237 8.33235 15.9999 8.33235H12.3337V1Z"
      fill="#FF7262"
    />
    <path
      d="M19.6661 11.9984C19.6661 14.0221 18.0237 15.6646 15.9999 15.6646C13.9762 15.6646 12.3337 14.0221 12.3337 11.9984C12.3337 9.97471 13.9762 8.33228 15.9999 8.33228C18.0237 8.33228 19.6661 9.97471 19.6661 11.9984Z"
      fill="#1ABCFE"
    />
  </>
);
