// @refresh reset
import './quillSnow.css';

import loadable, { LoadableComponent } from '@loadable/component';
import React from 'react';

export const VerseWysiwyg = loadable(() => import('./VerseWysiwygComponent'), {
  resolveComponent: (component: any) => component.VerseWysiwygComponent,
  fallback: <></>,
}) as LoadableComponent<any>;

export const LOADER_CONTAINER_MOTION_PROPS = {
  variants: {
    hidden: { opacity: 0 },
    visible: { opacity: 0.5 },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
  transition: { type: 'linear', duration: 0.25 },
};
