import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const SoundOffIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M17 12C17 7.49234 16.6667 4.3269 16.416 2.58389C16.2822 1.65323 15.4766 1 14.5364 1C13.929 1 13.3541 1.27425 12.9719 1.7463L8.61269 7.13085C8.23296 7.59989 7.66174 7.87239 7.05825 7.87239H6.97154C6.06987 7.87239 5.28687 8.4788 5.16867 9.37268C5.07802 10.0583 5 10.9526 5 12C5 13.0474 5.07802 13.9417 5.16867 14.6273C5.28687 15.5212 6.06987 16.1276 6.97154 16.1276H7.05825C7.66174 16.1276 8.23296 16.4001 8.61269 16.8692L12.9719 22.2537C13.3541 22.7258 13.929 23 14.5364 23C15.4766 23 16.2822 22.3468 16.416 21.4161C16.6667 19.6731 17 16.5077 17 12Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M23 23L1 1"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
