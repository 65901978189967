import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const HeartIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M12 7C12 5 9.5 2.5 6.5 2.5C3.5 2.5 1 5 1 8C1 12.7978 8.03364 18.8745 10.8522 21.117C11.5308 21.6569 12.4692 21.6569 13.1478 21.117C15.9664 18.8745 23 12.7978 23 8C23 5 20.5 2.5 17.5 2.5C14.5 2.5 12 5 12 7Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </>
);
