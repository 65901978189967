import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const PasteIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M1.16238 4.61135C1.32377 2.73167 2.73118 1.32426 4.61087 1.16287C5.68469 1.07067 7.05782 1.00049 8.74994 1.00049C10.4421 1.00049 11.8152 1.07067 12.889 1.16287C14.7687 1.32426 16.1761 2.73167 16.3375 4.61135C16.4297 5.68518 16.4999 7.05831 16.4999 8.75043C16.4999 10.4426 16.4297 11.8157 16.3375 12.8895C16.1761 14.7692 14.7687 16.1766 12.889 16.338C11.8152 16.4302 10.4421 16.5004 8.74994 16.5004C7.05782 16.5004 5.68469 16.4302 4.61087 16.338C2.73118 16.1766 1.32377 14.7692 1.16238 12.8895C1.07018 11.8157 1 10.4426 1 8.75043C1 7.0583 1.07018 5.68518 1.16238 4.61135Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
    />
    <path
      d="M7.66214 11.1112C7.82353 9.23155 9.23094 7.82414 11.1106 7.66275C12.1844 7.57055 13.5576 7.50037 15.2497 7.50037C16.9418 7.50037 18.3149 7.57055 19.3888 7.66275C21.2685 7.82414 22.6759 9.23155 22.8373 11.1112C22.9295 12.1851 22.9996 13.5582 22.9996 15.2503C22.9996 16.9424 22.9295 18.3156 22.8373 19.3894C22.6759 21.2691 21.2685 22.6765 19.3888 22.8379C18.3149 22.9301 16.9418 23.0002 15.2497 23.0002C13.5576 23.0002 12.1844 22.9301 11.1106 22.8379C9.23094 22.6765 7.82353 21.2691 7.66214 19.3894C7.56994 18.3156 7.49976 16.9424 7.49976 15.2503C7.49976 13.5582 7.56994 12.1851 7.66214 11.1112Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
    />
  </>
);
