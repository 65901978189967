export enum VerseCheckboxSizeEnum {
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum VerseCheckboxVariantEnum {
  DARK = 'dark',
  BLUE = 'blue',
  YELLOW = 'yellow',
}
