import React from 'react';
import styled from 'styled-components';
import { components } from 'react-select';

import {
  VerseSelectMenuListContainerProps,
  VerseSelectMenuListComponentProps,
} from './typings';
import { isOptionsGrouped } from './helpers';

export function VerseSelectMenuList({
  options,
  ...other
}: VerseSelectMenuListComponentProps) {
  return (
    <ListContainer
      onGroupedList={isOptionsGrouped(options)}
      options={options}
      {...other}
    />
  );
}

const ListContainer = styled(
  components.MenuList,
)<VerseSelectMenuListContainerProps>`
  && {
    padding: ${({ onGroupedList }) => (onGroupedList ? '10px 0' : '10px 6px')};
  }
`;
