import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const TimerIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <circle
      cx="12"
      cy="12.25"
      r="10.75"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M11 0.75 T13 0.75"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth) * 1.25}
      strokeLinecap="round"
    />
    <path
      d="M12 12L17 5.5"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
