export type VerseWysiwygComponentProps = {
  value?: any /** Node[] if v1, string | Delta if v2 */;
  onChange?: (value: any) => void;
  onFocus?: () => void;
  onBlur?: (range: any, source: any) => void;
  onErrorChange?: (errorType: VerseWysiwygErrorChangeEnum) => void;
  readOnly?: boolean;
  speechToText?: boolean;
  placeholder?: string;
  maxLength?: number;
  modules?: Array<VerseWysiwygModulesEnum>;
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  debounceMs?: number;
};

export enum VerseWysiwygModulesEnum {
  Heading1 = 'heading1',
  Heading2 = 'heading2',
  Bold = 'bold',
  Italic = 'italic',
  Underline = 'underline',
  Strike = 'strike',
  OrderedList = 'orderedList',
  BulletList = 'bulletList',
  Blockquote = 'blockquote',
  Code = 'code',
  Link = 'link',
  Separator = 'separator',
}

export enum VerseWysiwygErrorChangeEnum {
  MAX_LENGTH = 'maxLength',
  NONE = 'none',
}
