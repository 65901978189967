import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const PencilIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M16.0269 2.94455L2.72815 16.2433C2.42103 16.5504 2.21169 16.9416 2.12651 17.3675L1.32359 21.382C1.16965 22.1517 1.84826 22.8303 2.61796 22.6764L6.63254 21.8735C7.05844 21.7883 7.4496 21.579 7.75672 21.2718L21.0555 7.97312C22.1294 6.89917 22.1294 5.15797 21.0555 4.08403L19.916 2.94454C18.842 1.8706 17.1008 1.8706 16.0269 2.94455Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M21.0555 4.08409L19.916 2.94461C18.842 1.87066 17.1008 1.87066 16.0269 2.94461L15.8497 3.12181C15.4592 3.51234 15.4592 4.1455 15.8497 4.53603L19.464 8.15039C19.8546 8.54091 20.4877 8.54091 20.8783 8.15039L21.0555 7.97318C22.1294 6.89923 22.1294 5.15803 21.0555 4.08409Z"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
