import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const TadaaIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M3 6.5C3.82843 6.5 4.5 5.82843 4.5 5C4.5 4.17157 3.82843 3.5 3 3.5C2.17157 3.5 1.5 4.17157 1.5 5C1.5 5.82843 2.17157 6.5 3 6.5Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M19.8939 19.5L18.4797 20.9142L19.8939 22.3284L21.3081 20.9142L19.8939 19.5Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M21.8001 15.4C22.7001 14.5 20.6001 10.8 17.0001 7.2C13.4001 3.6 9.80006 1.5 8.80006 2.4C6.40006 4.9 2.70006 16.8 1.60006 21.3C1.30006 22.2 2.10006 23 3.00006 22.7C7.50006 21.6 19.4001 17.8 21.8001 15.4Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
    />
    <path
      d="M21.7099 15.3726C22.6472 14.4353 20.5261 10.7947 16.9724 7.24092C13.4186 3.68718 9.77797 1.5661 8.84072 2.50335C7.90347 3.4406 10.0245 7.08126 13.5783 10.635C17.132 14.1887 20.7727 16.3098 21.7099 15.3726Z"
      fill={Array.isArray(fill) ? fill[1] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
    />
    <path
      d="M6.30005 7C7.00005 8.6 8.70005 11 11 13.3C13.3 15.6 15.6 17.3 17.3 18"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
    />
    <path
      d="M15.5 8.5C17 6.66667 20.5 3.5 23 3M12.5 6.5C13.5 5.66667 15 3 15 1M17.5 11C18.3333 10.1667 20.5 8.5 23 10"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
