import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ResetIcon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C8.31954 1 5.06125 2.80754 3.06442 5.58333M3.06442 5.58333L2.83333 2.83333M3.06442 5.58333H6.04167"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
