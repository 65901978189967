import React from 'react';
import styled from 'styled-components';

import { VerseBody2, VerseIcon } from '..';
import { DashedContainerProps, VerseClickableItemPlaceholderComponentProps } from './typings';

export function VerseClickableItemPlaceholder({
  title,
  icon,
  testId,
  ...other
}: VerseClickableItemPlaceholderComponentProps) {
  return (
    <DashedContainer data-testid={testId} {...other}>
      {icon && <VerseIcon size={16} mr={0.75} {...icon} />}
      <VerseBody2>{title}</VerseBody2>
    </DashedContainer>
  );
}

const DashedContainer = styled.div<DashedContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  box-sizing: border-box;

  cursor: pointer;
  transition: ${({ theme }) => theme.animationSpeed}ms all linear;
  border: 1px dashed ${({ theme }) => theme.colors.main.PrimaryDark30};

  ${({ width }) =>
    width && `width: ${Number.isInteger(width) ? `${width}px` : width};`}
  ${({ height }) =>
    height && `height: ${Number.isInteger(height) ? `${height}px` : height};`}
  border-radius: 12px;

  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}

  box-shadow: ${({ theme }) => theme.shadows.primaryDark07};
  background: ${({ bgcolor, theme }) =>
    bgcolor ? bgcolor : theme.colors.other.Sand03};

  :hover {
    box-shadow: ${({ theme }) => theme.shadows.primaryDark10};
    background: ${({ bgcolor, theme }) =>
      bgcolor ? bgcolor : theme.colors.other.Sand05};
  }
`;
