import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const FullscreenV2Icon = ({
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M15 2H22M22 2V9M22 2L15 9"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 22L2 22M2 22L2 15M2 22L9 15"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
