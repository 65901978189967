import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ToolboxIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <rect width="24" height="24" fill="white" />
    <path
      d="M1.33547 10.6733C1.44635 9.7042 2.27403 9 3.24949 9C4.88934 9 7.75758 9 12 9C16.2424 9 19.1107 9 20.7505 9C21.726 9 22.5537 9.70422 22.6646 10.6734C22.8206 12.0368 23 14.1162 23 16.4667C23 18.2793 22.8933 19.6577 22.7729 20.6359C22.6317 21.7838 21.7103 22.6018 20.5578 22.6985C18.8902 22.8384 16.0809 23 12 23C7.91914 23 5.1098 22.8384 3.4422 22.6985C2.28975 22.6017 1.36837 21.7838 1.22711 20.636C1.10672 19.6577 1 18.2793 1 16.4667C1 14.1161 1.17946 12.0368 1.33547 10.6733Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M2 1.8C2 1.35817 2.35817 1 2.8 1H3.2C3.64183 1 4 1.35817 4 1.8V9H2V1.8Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M20 1.8C20 1.35817 20.3582 1 20.8 1H21.2C21.6418 1 22 1.35817 22 1.8V9H20V1.8Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M3.40002 3.8C3.40002 3.35817 3.7582 3 4.20002 3H19.8C20.2419 3 20.6 3.35817 20.6 3.8V4.2C20.6 4.64183 20.2419 5 19.8 5H4.20002C3.75819 5 3.40002 4.64183 3.40002 4.2V3.8Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M9.99998 22.93L12.6452 20.3197C14.4521 21.1105 16.6183 20.739 18.0234 19.2364C19.4285 17.7337 19.654 15.5475 18.7439 13.7976L16.2924 16.4192C16.2924 16.4192 15.7548 16.2162 14.9704 15.4827C14.2993 14.8551 13.9474 14.2264 13.9474 14.2264L16.3989 11.6048C14.5919 10.814 12.4257 11.1855 11.0206 12.6882C9.6155 14.1908 9.39004 16.377 10.3001 18.1269L5.81506 22.82"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
