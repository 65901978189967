import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const PDFIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M3 12C3 15.2464 3.14891 17.6656 3.3086 19.3266C3.47797 21.0881 4.73781 22.4664 6.49343 22.6887C7.82354 22.857 9.64596 23 12 23C14.354 23 16.1765 22.8571 17.5066 22.6887C19.2622 22.4664 20.522 21.0881 20.6914 19.3266C20.8511 17.6656 21 15.2464 21 12C21 10.3482 20.9615 8.91053 20.9025 7.67796C20.8512 6.60388 20.3401 5.60828 19.5197 4.91321L16.0183 1.94692C15.3573 1.38697 14.5327 1.05154 13.6668 1.02498C13.144 1.00894 12.5886 1 12 1C9.64596 1 7.82355 1.14295 6.49343 1.31134C4.73781 1.53359 3.47797 2.9119 3.3086 4.67341C3.14891 6.33438 3 8.75355 3 12Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M19.5197 4.91322L15.7639 1.73139C15.2681 1.31134 14.6494 1.06058 14 1.03632V7C14 7.55229 14.4477 8 15 8L20.9173 8.00001C20.9125 7.89124 20.9076 7.78399 20.9026 7.67825C20.8512 6.60417 20.3401 5.60829 19.5197 4.91322Z"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M7.13504 11.05C7.78104 11.05 8.27687 11.2228 8.62254 11.5685C8.9682 11.9085 9.14104 12.3533 9.14104 12.903C9.14104 13.4697 8.9682 13.9287 8.62254 14.28C8.27687 14.6257 7.78104 14.7985 7.13504 14.7985H6.14904V17H5.06104V11.05H7.13504ZM7.01604 13.7955C7.31637 13.7955 7.55154 13.7218 7.72154 13.5745C7.89154 13.4215 7.97654 13.2005 7.97654 12.9115C7.97654 12.6282 7.89154 12.4157 7.72154 12.274C7.55154 12.1267 7.31637 12.053 7.01604 12.053H6.14904V13.7955H7.01604Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M11.6965 11.05C12.2971 11.05 12.8298 11.1718 13.2945 11.4155C13.7648 11.6535 14.1303 11.9963 14.391 12.444C14.6573 12.8917 14.7905 13.4187 14.7905 14.025C14.7905 14.6313 14.6573 15.1583 14.391 15.606C14.1303 16.0537 13.7648 16.3993 13.2945 16.643C12.8298 16.881 12.2971 17 11.6965 17H9.79248V11.05H11.6965ZM10.8805 12.053V15.997H11.7475C12.1101 15.997 12.4303 15.9205 12.708 15.7675C12.9913 15.6145 13.2123 15.3907 13.371 15.096C13.5353 14.8013 13.6175 14.4443 13.6175 14.025C13.6175 13.6057 13.5353 13.2487 13.371 12.954C13.2123 12.6593 12.9913 12.4355 12.708 12.2825C12.4303 12.1295 12.1101 12.053 11.7475 12.053H10.8805Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M19.1987 11.05V12.07H16.6827V13.532H18.9437V14.535H16.6827V17H15.5947V11.05H19.1987Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
  </>
);
