import React from 'react';

import { VerseIconSVGProps } from '../typings';

export const CheckV1Icon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M2,16L6.2962,20.0275C6.8278,20.6056 7.7452,20.5885 8.2549,19.9911L15.1349,11.9267L22,4"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
