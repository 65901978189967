import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const HomeV2Icon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M1.42263 9.17473C1.65902 8.29328 2.22556 7.55452 2.96372 7.0179C5.18213 5.40515 10.092 2 12 2C13.908 2 18.8179 5.40515 21.0363 7.0179C21.7744 7.55452 22.341 8.29328 22.5774 9.17473C22.7888 9.96299 23 11.0007 23 12C23 15.1026 22.8338 17.2532 22.6487 18.6861C22.4455 20.2593 21.2441 21.3821 19.6704 21.5809C18.0065 21.7911 15.478 22 12 22C8.52201 22 5.9935 21.7911 4.32956 21.5809C2.75587 21.3821 1.55454 20.2593 1.35133 18.6861C1.16623 17.2532 1 15.1026 1 12C1 11.0007 1.21124 9.96299 1.42263 9.17473Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M19 12C19 15.914 15.8387 18.9247 12.0753 18.9247C8.31183 18.9247 5 15.914 5 12"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
