import React from 'react';
import styled from 'styled-components';

import { VerseBody1, VerseH4, VerseLinkButton } from '..';
import { useVerseTheme } from '../..';
import {
  VerseComicPopoverComponentProps,
  VerseComicPopoverInnerContainerProps,
} from './typings';
import { VerseBasePopover } from './VerseBasePopover';

export const VersePopover = ({
  title,
  description,
  footerButton,
  imgSrc,
  descriptionHtml,
  ...baseProps
}: VerseComicPopoverComponentProps) => {
  const theme = useVerseTheme();
  return (
    <StyledBasePopover hasFooter={Boolean(footerButton)} {...baseProps}>
      {title && (
        <VerseH4 mt={0} mb={1.5}>
          {title}
        </VerseH4>
      )}
      <VerseBody1
        color={theme.colors.main.PrimaryDark80}
        mb={imgSrc ? 1.5 : undefined}
        html={descriptionHtml}
      >
        {description}
      </VerseBody1>
      {imgSrc && <img src={imgSrc} width={'100%'} />}

      {footerButton && (
        <StyledFooterContainer>
          <VerseLinkButton {...footerButton} />
        </StyledFooterContainer>
      )}
    </StyledBasePopover>
  );
};

const StyledFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  background: ${({ theme }) => theme.colors.other.White};
  margin-top: ${({ theme }) => theme.spacing(2.5)}px;
`;

const StyledBasePopover = styled(
  VerseBasePopover,
)<VerseComicPopoverInnerContainerProps>`
  ${({ hasFooter }) =>
    hasFooter
      ? `padding: 26px 16px 16px 24px;`
      : `padding: 26px 24px 24px 24px;`}

  border-radius: 24px;
`;
