import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const OpenIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M5.24986 4.54073C5.43155 2.76482 6.76482 1.43155 8.54073 1.24986C9.86664 1.11421 11.6737 1 14 1C16.3263 1 18.1334 1.11421 19.4593 1.24986C21.2352 1.43155 22.5685 2.76482 22.7501 4.54073C22.8858 5.86664 23 7.67374 23 10C23 12.3263 22.8858 14.1334 22.7501 15.4593C22.5685 17.2352 21.2352 18.5685 19.4593 18.7501C18.1334 18.8858 16.3263 19 14 19C11.6737 19 9.86664 18.8858 8.54073 18.7501C6.76482 18.5685 5.43155 17.2352 5.24986 15.4593C5.11421 14.1334 5 12.3263 5 10C5 7.67374 5.11421 5.86664 5.24986 4.54073Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M5.29778 4.21027C5.27735 4.31854 5.26132 4.42876 5.24986 4.54073C5.11421 5.86663 5 7.67374 5 10C5 12.3263 5.11421 14.1334 5.24986 15.4593C5.43155 17.2352 6.76482 18.5685 8.54073 18.7501C9.86664 18.8858 11.6737 19 14 19C16.3263 19 18.1334 18.8858 19.4593 18.7501C19.5712 18.7387 19.6815 18.7227 19.7897 18.7022C19.7536 19.1089 19.715 19.4794 19.6758 19.8143C19.4964 21.3456 18.3456 22.4964 16.8143 22.6758C15.3802 22.8438 13.2951 23 10.5 23C7.70485 23 5.61979 22.8438 4.18571 22.6758C2.65443 22.4964 1.50356 21.3456 1.3242 19.8143C1.15622 18.3802 1 16.2951 1 13.5C1 10.7049 1.15622 8.61979 1.3242 7.18571C1.50356 5.65443 2.65443 4.50356 4.18571 4.3242C4.52064 4.28496 4.89108 4.24637 5.29778 4.21027Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M10.7573 13.4601L19.2426 4.97477M19.2426 4.97477L13.432 4.82105M19.2426 4.97477L19.3963 10.7853"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
