import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const H1Icon = ({ stroke }: VerseIconSVGProps) => (
  <>
    <path
      d="M12.4993 10.6968V5.76337H14.8295V18.5066H12.4993V12.9178H6.45543V18.5066H4.12524V5.76337H6.45543V10.6968H12.4993Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M17.1547 6.21848H20.1403V18.5066H17.8829V8.40303H17.1547V6.21848Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
  </>
);
