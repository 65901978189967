import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const MuralIcon = () => (
  <>
    <path
      d="M2 3C2 2.44772 2.44772 2 3 2H21C21.5523 2 22 2.44772 22 3V16L16.2929 21.7071C16.1054 21.8946 15.851 22 15.5858 22H3C2.44772 22 2 21.5523 2 21V3Z"
      fill="#FF0061"
    />
    <path
      d="M9.7 15H8.3C8.13431 15 8 14.8657 8 14.7V9.3C8 9.13431 8.13431 9 8.3 9H9.83944C9.93975 9 10.0334 9.05013 10.0891 9.13359L12 12L13.9109 9.13359C13.9666 9.05013 14.0602 9 14.1606 9H15.7C15.8657 9 16 9.13431 16 9.3V14.7C16 14.8657 15.8657 15 15.7 15H14.3C14.1343 15 14 14.8657 14 14.7V12L12 14.5L10 12V14.7C10 14.8657 9.86569 15 9.7 15Z"
      fill="white"
    />
    <path
      d="M10.5 22L16 22L16.5 20.5L16.5 19L18 16L17.1213 16C16.7235 16 16.342 16.158 16.0607 16.4393L10.5 22Z"
      fill="#B20044"
    />
    <path d="M22 16H17C16.4477 16 16 16.4477 16 17V22L22 16Z" fill="#FF5BA0" />
  </>
);
