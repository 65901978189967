import React from 'react';
import styled from 'styled-components';

import { VerseBody3, VerseIcon, VerseIconIdEnum } from '..';
import { useVerseTheme } from '../..';
import {
  ClickableLabelWrapperProps,
  VerseNavigationBreadcrumbsComponentProps,
} from './typings';

export function VerseNavigationBreadcrumbs({
  links,
  maxVisible = 5,
}: VerseNavigationBreadcrumbsComponentProps) {
  const splicedLinks = links.slice(-maxVisible);
  const exceedMaxVisible = links.length > maxVisible;
  const theme = useVerseTheme();
  return (
    <BreadcrumbsContainer>
      {links.length > maxVisible ? (
        <VerseBody3 component="span" mr={0.5} opacity={0.4}>
          ...
        </VerseBody3>
      ) : null}

      {splicedLinks.map((link, index) => {
        const isLastItem = index === splicedLinks.length - 1;

        return (
          <React.Fragment key={link.id}>
            {index > 0 || exceedMaxVisible ? (
              <VerseIcon
                iconId={VerseIconIdEnum.CHEVRON_RIGHT}
                size={16}
                mr={0.5}
                ml={0.5}
                stroke={theme.colors.main.PrimaryDark40RGBA}
              />
            ) : null}

            <ClickableWrapper
              isActive={isLastItem}
              key={`breadcrumb-${link.id}`}
              onClick={link.onClick}
              data-testid={link?.testId}
              disabled={isLastItem}
            >
              <VerseBody3 component="span" linkify={false}>
                {link.label}
              </VerseBody3>
            </ClickableWrapper>
          </React.Fragment>
        );
      })}
    </BreadcrumbsContainer>
  );
}

const BreadcrumbsContainer = styled.div`
  height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
`;
const ClickableWrapper = styled.div<ClickableLabelWrapperProps>`
  cursor: pointer;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.4)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;

  transition: opacity 0.2s linear;
  :hover {
    opacity: 0.8;
  }

  ${({ disabled }) => disabled && `pointer-events: none;`}
`;
