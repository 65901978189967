import { keyframes } from 'styled-components';

export interface ScalePopKeyframesProps {
  from: number;
  to: number;
  otherTransforms?: string;
}

export const scalePopKeyframes = ({
  from,
  to,
  otherTransforms,
}: ScalePopKeyframesProps) => keyframes`
0% {
  transform: scale(${from}) ${otherTransforms};
}
100% {
  transform: scale(${to}) ${otherTransforms};
}
`;
