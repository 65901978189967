import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const CommandIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M12 14.7273C17.303 14.7273 19.6515 15.3333 19.6515 15.3333C21.2424 15.7879 22 17.0758 22 18.5909C22 20.4848 20.4848 22 18.5909 22C17.0758 22 16.0152 21.7727 15.3333 19.7273C15.3333 19.7273 14.7273 17.303 14.7273 12C14.7273 6.69697 15.3333 4.27273 15.3333 4.27273C15.7879 2.60606 17.0758 2 18.5909 2C20.4848 2 22 3.51515 22 5.40909C22 6.92424 21.4697 8.13636 19.6515 8.66667C19.6515 8.66667 17.303 9.27273 12 9.27273C6.69697 9.27273 4.34848 8.66667 4.34848 8.66667C2.68182 8.21212 2 6.92424 2 5.40909C2 3.51515 3.51515 2 5.40909 2C6.92424 2 8.13636 2.60606 8.66667 4.27273C8.66667 4.27273 9.27273 6.54545 9.27273 12C9.27273 17.303 8.66667 19.7273 8.66667 19.7273C8.13636 21.5455 6.92424 22 5.40909 22C3.51515 22 2 20.4848 2 18.5909C2 17.0758 2.83333 15.7879 4.34848 15.3333C4.34848 15.3333 6.69697 14.7273 12 14.7273Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
