import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const GoogleIcon = () => (
  <>
    <path
      d="M22.7649 12.2529C22.7649 11.5052 22.7043 10.7534 22.575 10.0178H12.1997V14.2536H18.1411C17.8946 15.6197 17.1024 16.8282 15.9424 17.5962V20.3446H19.487C21.5686 18.4288 22.7649 15.5995 22.7649 12.2529Z"
      fill="#4285F4"
    />
    <path
      d="M12.1995 23.0001C15.1662 23.0001 17.668 22.026 19.4909 20.3446L15.9462 17.5962C14.96 18.2671 13.6869 18.6471 12.2035 18.6471C9.33386 18.6471 6.90071 16.7111 6.02769 14.1082H2.36987V16.9414C4.23718 20.6558 8.04049 23.0001 12.1995 23.0001Z"
      fill="#34A853"
    />
    <path
      d="M6.02382 14.1083C5.56305 12.7422 5.56305 11.2629 6.02382 9.89677V7.06348H2.37005C0.809919 10.1716 0.809919 13.8335 2.37005 16.9416L6.02382 14.1083Z"
      fill="#FBBC04"
    />
    <path
      d="M12.1995 5.35374C13.7677 5.32949 15.2834 5.91959 16.4191 7.00279L19.5596 3.86232C17.571 1.99502 14.9317 0.968407 12.1995 1.00074C8.04049 1.00074 4.23718 3.34497 2.36987 7.06341L6.02364 9.8967C6.89263 7.28975 9.32982 5.35374 12.1995 5.35374Z"
      fill="#EA4335"
    />
  </>
);
