import React, { useMemo } from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import uniqueId from 'lodash/uniqueId';

import { VerseIcon, VerseIconIdEnum } from '..';
import { useDelayUnmount } from '../../utils';
import { VerseKeyframes } from '../VerseKeyframes';
import { VerseButtonTextMedium } from '../VerseTypography/Internal';
import {
  ButtonSelectorBaseComponentProps,
  VerseButtonSelectorComponentProps,
  VerseButtonSelectorWrapperProps,
} from './typings';

export const VerseButtonSelector = (
  props: VerseButtonSelectorComponentProps,
) => {
  const { control, defaultValue, ...other } = props;

  if (control) {
    return <ControlledButtonSelector {...props} />;
  }
  return (
    <ButtonSelectorBase
      checked={defaultValue as boolean}
      readOnly={true}
      {...other}
    />
  );
};
export const ControlledButtonSelector = ({
  onChange,
  name,
  control,
  defaultValue = false,
  rules,
  onFocus,
  ...other
}: VerseButtonSelectorComponentProps) => {
  const [localChecked, setLocalChecked] = React.useState<boolean>(
    defaultValue as boolean,
  );

  const {
    field: { ref, value, onChange: defaultOnChange, ...inputProps },
  } = useController({ name, control, defaultValue, rules, onFocus });

  const combinedOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    defaultOnChange(e.target.checked);
    setLocalChecked(e.target.checked);
    onChange && onChange(e);
  };

  return (
    <ButtonSelectorBase
      inputRef={ref}
      checked={localChecked}
      onChange={combinedOnChange}
      inputProps={inputProps}
      {...other}
    />
  );
};
const ButtonSelectorBase = ({
  title,
  onChange,
  readOnly,
  inputRef,
  checked = false,
  inputProps,
  disabled,
  ...other
}: ButtonSelectorBaseComponentProps) => {
  const readOnlyOnChange = () => false;
  const shoulderRenderChecked = useDelayUnmount({
    isMounted: checked,
    delayTime: 100,
  });
  const selectorId = useMemo(uniqueId, []);

  return (
    <ButtonWrapper {...other}>
      <HiddenInput
        id={`verse-button-selector-${selectorId}`}
        type="checkbox"
        onChange={readOnly ? readOnlyOnChange : onChange}
        checked={checked}
        readOnly={readOnly}
        disabled={disabled}
      />

      <Button
        htmlFor={`verse-button-selector-${selectorId}`}
        ref={inputRef}
        {...inputProps}
      >
        <div>
          {shoulderRenderChecked && (
            <PoppingCheckIconContainer checked={checked}>
              <VerseIcon iconId={VerseIconIdEnum.CHECK_V2} size={20} mr={1} />
            </PoppingCheckIconContainer>
          )}
        </div>
        <VerseButtonTextMedium t={title} />
      </Button>
    </ButtonWrapper>
  );
};

const Button = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  border-radius: 12px;
  padding: 10px;

  background-color: ${({ theme }) => theme.colors.other.White};
  border: 1px solid ${({ theme }) => theme.colors.main.PrimaryDark100};

  cursor: pointer;
  transition: all ${({ theme }) => theme.animationSpeed}ms linear;
`;
const HiddenInput = styled.input`
  display: none;

  :hover + ${Button} {
    ${({ theme }) => theme.helpers.hover.elevate};
  }

  :checked + ${Button} {
    background-color: ${({ theme }) => theme.colors.other.Blue50};
  }

  :disabled + ${Button} {
    opacity: 0.5;
  }

  ${({ readOnly }) => readOnly && `+ ${Button}{pointer-events: none;}`}
`;

const ButtonWrapper = styled.div<VerseButtonSelectorWrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: none;
  padding: 0;

  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}
  ${({ width }) =>
    width && `width: ${Number.isInteger(width) ? `${width}px` : width};`}
`;

const easeOut = VerseKeyframes.fadeOutKeyframes();
const easeIn = VerseKeyframes.fadeInKeyframes();

const PoppingCheckIconContainer = styled.div<{ checked: boolean }>`
  animation-name: ${({ checked }) => (checked ? easeIn : easeOut)};
  animation-duration: 0.2s;
  display: flex;
`;
