import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const PlayPreviousIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M23 12C23 7.11096 22.8522 3.80947 22.7314 1.92665C22.6794 1.11673 21.7514 0.758909 21.1691 1.32422C20.0957 2.36624 18.5437 3.93967 17 5.73913C15.2441 7.78594 13.7084 9.74919 12.8173 10.9151C12.319 11.5671 12.2865 12.4553 12.7445 13.1363C13.6232 14.4428 15.1953 16.6789 17 18.7826C18.5815 20.6261 20.1717 22.0629 21.247 22.9578C21.8316 23.4443 22.6643 23.0757 22.7154 22.3168C22.8388 20.4819 23 17.1071 23 12Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
    />
    <path
      d="M12 12C12 7.11096 11.8522 3.80947 11.7314 1.92665C11.6794 1.11673 10.7514 0.758909 10.1691 1.32422C9.09571 2.36624 7.54369 3.93967 6 5.73913C4.24412 7.78594 2.70845 9.74919 1.81731 10.9151C1.31899 11.5671 1.28653 12.4553 1.7445 13.1363C2.62324 14.4428 4.19531 16.6789 6 18.7826C7.58146 20.6261 9.17166 22.0629 10.247 22.9578C10.8316 23.4443 11.6643 23.0757 11.7154 22.3168C11.8388 20.4819 12 17.1071 12 12Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
    />
  </>
);
