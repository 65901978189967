import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const MiroIcon = () => (
  <>
    <circle cx="17" cy="7" r="1" fill="white" />
    <path
      d="M2 8.93333C2 6.50646 2 5.29299 2.4723 4.36604C2.88776 3.55067 3.55067 2.88776 4.36604 2.4723C5.29299 2 6.50646 2 8.93333 2H15.0667C17.4935 2 18.707 2 19.634 2.4723C20.4493 2.88776 21.1122 3.55067 21.5277 4.36604C22 5.29299 22 6.50646 22 8.93333V15.0667C22 17.4935 22 18.707 21.5277 19.634C21.1122 20.4493 20.4493 21.1122 19.634 21.5277C18.707 22 17.4935 22 15.0667 22H8.93333C6.50646 22 5.29299 22 4.36604 21.5277C3.55067 21.1122 2.88776 20.4493 2.4723 19.634C2 18.707 2 17.4935 2 15.0667V8.93333Z"
      fill="#FFD02F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6284 4.6665H13.4934L15.3887 7.60458L11.3817 4.6665H9.24667L11.1419 8.51608L7.13496 4.6665H5L6.86417 9.63558L5 19.3332H7.13496L11.1419 8.93517L9.24667 19.3332H11.3817L15.3887 8.02367L13.4934 19.3332H15.6284L19.6664 6.91033L15.6284 4.6665Z"
      fill="#050038"
    />
  </>
);
