import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const BulletsIcon = ({ stroke }: VerseIconSVGProps) => (
  <>
    <path
      d="M20.4791 6.00003L8.47913 6.00003"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="4.78288"
      cy="6.06828"
      r="1.26421"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <circle
      cx="4.78276"
      cy="12.1365"
      r="1.26421"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <circle
      cx="4.78276"
      cy="18.2046"
      r="1.26421"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M20.4791 12L8.47913 12"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.4791 18L8.47913 18"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
