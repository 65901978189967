import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const BreakoutIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M2.2607 5.29863C2.14318 6.30207 2.0311 7.6057 1.96835 9.22751C1.94844 9.74221 2.36313 10.1667 2.87822 10.1667H9.25002C9.75629 10.1667 10.1667 9.75629 10.1667 9.25002V2.87823C10.1667 2.36313 9.74221 1.94844 9.22751 1.96835C7.6057 2.0311 6.30207 2.14318 5.29863 2.2607C3.67332 2.45106 2.45106 3.67332 2.2607 5.29863Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M2.2607 18.7014C2.14318 17.6979 2.0311 16.3943 1.96835 14.7725C1.94844 14.2578 2.36313 13.8333 2.87822 13.8333H9.25002C9.75629 13.8333 10.1667 14.2437 10.1667 14.75V21.1218C10.1667 21.6369 9.74221 22.0516 9.22751 22.0316C7.6057 21.9689 6.30207 21.8568 5.29863 21.7393C3.67332 21.5489 2.45106 20.3267 2.2607 18.7014Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M21.7393 5.29863C21.8568 6.30207 21.9689 7.6057 22.0316 9.22751C22.0516 9.74221 21.6369 10.1667 21.1218 10.1667H14.75C14.2437 10.1667 13.8333 9.75629 13.8333 9.25002V2.87823C13.8333 2.36313 14.2578 1.94844 14.7725 1.96835C16.3943 2.0311 17.6979 2.14318 18.7014 2.2607C20.3267 2.45106 21.5489 3.67332 21.7393 5.29863Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M21.7393 18.7014C21.8568 17.6979 21.9689 16.3943 22.0316 14.7725C22.0516 14.2578 21.6369 13.8333 21.1218 13.8333H14.75C14.2437 13.8333 13.8333 14.2437 13.8333 14.75V21.1218C13.8333 21.6369 14.2578 22.0516 14.7725 22.0316C16.3943 21.9689 17.6979 21.8568 18.7014 21.7393C20.3267 21.5489 21.5489 20.3267 21.7393 18.7014Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
