import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const PointerIcon = ({
  stroke,
  strokeWidth,
  fill = 'white',
}: VerseIconSVGProps) => (
  <>
    <circle
      cx="10"
      cy="10"
      r="9"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      fill={Array.isArray(fill) ? fill[0] : fill}
      strokeWidth={strokeWidth}
    />
    <circle
      cx="10"
      cy="10"
      r="5"
      stroke={Array.isArray(stroke) ? stroke[1] : stroke}
      fill={Array.isArray(fill) ? fill[1] : fill}
      strokeWidth={strokeWidth}
    />
    <path
      d="M10.4203 10.6354C10.2987 10.2273 10.7104 9.86371 11.1004 10.0348L22.7086 15.1264C23.1561 15.3227 23.0962 16.0244 22.6299 16.1703C21.2939 16.5882 19.3617 17.3479 18.0098 18.542C16.6579 19.7362 15.6656 21.5599 15.086 22.8341C14.8837 23.2788 14.1799 23.2516 14.0404 22.7834L10.4203 10.6354Z"
      fill={Array.isArray(fill) ? fill[2] : fill}
      stroke={Array.isArray(stroke) ? stroke[2] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
