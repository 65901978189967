import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ArrowRightV1Icon = ({
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M13.25 3.24994L22 11.9999L13.25 20.7499"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 12H2"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
