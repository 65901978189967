import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const GoogleCalendarIcon = () => (
  <>
    <g clipPath="url(#clip0)">
      <path
        d="M19.8078 1.75H4.19243C3.29992 1.75 2.57703 2.47289 2.57703 3.36538L2.60799 13.5961H21.4232V3.36538C21.4232 2.47289 20.7003 1.75 19.8078 1.75Z"
        fill="#C2C2C2"
      />
      <rect
        x="-0.048584"
        y="3.90479"
        width="24.0966"
        height="11.8566"
        fill="url(#pattern0)"
      />
      <path
        d="M20.8846 4.98096H3.11538C2.22289 4.98096 1.5 5.70385 1.5 6.59636C1.5 6.67174 1.5054 6.74579 1.5148 6.81712L2.57691 13.5963H21.4231L22.4852 6.81712C22.4946 6.74443 22.5 6.67038 22.5 6.59636C22.5 5.70385 21.7771 4.98096 20.8846 4.98096Z"
        fill="#3367D6"
      />
      <path
        opacity="0.5"
        d="M20.8846 4.98096H3.11538C2.22289 4.98096 1.5 5.70385 1.5 6.59636C1.5 6.67174 1.5054 6.74579 1.5148 6.81712L2.57691 13.5963H21.4231L22.4852 6.81712C22.4946 6.74443 22.5 6.67038 22.5 6.59636C22.5 5.70385 21.7771 4.98096 20.8846 4.98096Z"
        fill="#2A56C6"
      />
      <path
        d="M21.9575 20.4842L21.423 13.5959H2.57689L2.04249 20.4842C2.03981 20.5219 2.03845 20.5583 2.03845 20.596C2.03845 21.4885 2.76135 22.2113 3.65383 22.2113H20.3461C21.2386 22.2113 21.9615 21.4884 21.9615 20.596C21.9615 20.5583 21.9602 20.5219 21.9575 20.4842Z"
        fill="#4285F4"
      />
      <path
        d="M2.57703 13.5959L11.1924 22.2113H21.9616V13.5959H2.57703Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M3.11538 5.11509H20.8846C21.7583 5.11509 22.469 5.8097 22.4973 6.67662C22.4987 6.64969 22.5 6.62277 22.5 6.59585C22.5 5.70333 21.7771 4.98047 20.8846 4.98047H3.11538C2.22289 4.98047 1.5 5.70336 1.5 6.59588C1.5 6.6228 1.50136 6.64972 1.50268 6.67665C1.53096 5.8097 2.24174 5.11509 3.11538 5.11509Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M20.3461 22.0762H3.65383C2.78019 22.0762 2.06942 21.3816 2.04114 20.5146C2.03978 20.5416 2.03845 20.5685 2.03845 20.5954C2.03845 21.4879 2.76135 22.2108 3.65383 22.2108H20.3461C21.2386 22.2108 21.9615 21.4879 21.9615 20.5954C21.9615 20.5685 21.9602 20.5416 21.9588 20.5146C21.9305 21.3816 21.2198 22.0762 20.3461 22.0762Z"
        fill="#1A237E"
        fillOpacity="0.2"
      />
      <path
        d="M19.8078 1.75H4.19243C3.29992 1.75 2.57703 2.47289 2.57703 3.36538V3.49327C2.58107 2.6048 3.3026 1.88462 4.19243 1.88462H19.8078C20.7003 1.88462 21.4232 2.60751 21.4232 3.49999V3.36538C21.4232 2.47289 20.7003 1.75 19.8078 1.75Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M10.4197 13.4812C11.0322 13.2281 11.8211 12.555 11.8211 11.5414C11.8211 9.97983 10.8182 9.02002 9.12876 9.02002C7.62376 9.02002 6.41087 10.0054 6.38935 11.4431H7.48242C7.49858 10.6031 8.06665 9.91253 9.12743 9.91253C10.1492 9.91253 10.7253 10.4442 10.7253 11.5171C10.7253 12.59 9.90417 13.0854 8.90264 13.0854C8.50955 13.0854 8.13937 13.0854 8.13937 13.0854V13.9416C8.13937 13.9416 8.56207 13.9416 8.90264 13.9416C10.3753 13.9416 10.8599 14.7062 10.8599 15.5933C10.8599 16.7537 10.1546 17.2895 9.13283 17.2895C8.24303 17.2895 7.43264 16.8143 7.4084 15.7521H6.30725C6.35437 17.4631 7.79476 18.1752 9.13283 18.1752C10.6782 18.1752 11.953 17.2747 11.953 15.5691C11.9557 14.1933 10.9757 13.644 10.4197 13.4812ZM16.6686 9.02002L13.4809 10.1643V11.1927L15.7693 10.4281V18.1738H16.8463V9.02002H16.6686Z"
        fill="#E1E1E1"
      />
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M10.4197 13.4812C11.0322 13.2281 11.8211 12.555 11.8211 11.5414C11.8211 9.97983 10.8182 9.02002 9.12876 9.02002C7.62376 9.02002 6.41087 10.0054 6.38935 11.4431H7.48242C7.49858 10.6031 8.06665 9.91253 9.12743 9.91253C10.1492 9.91253 10.7253 10.4442 10.7253 11.5171C10.7253 12.59 9.90417 13.0854 8.90264 13.0854C8.50955 13.0854 8.13937 13.0854 8.13937 13.0854V13.9416C8.13937 13.9416 8.56207 13.9416 8.90264 13.9416C10.3753 13.9416 10.8599 14.7062 10.8599 15.5933C10.8599 16.7537 10.1546 17.2895 9.13283 17.2895C8.24303 17.2895 7.43264 16.8143 7.4084 15.7521H6.30725C6.35437 17.4631 7.79476 18.1752 9.13283 18.1752C10.6782 18.1752 11.953 17.2747 11.953 15.5691C11.9557 14.1933 10.9757 13.644 10.4197 13.4812ZM16.6686 9.02002L13.4809 10.1643V11.1927L15.7693 10.4281V18.1738H16.8463V9.02002H16.6686Z"
          fill="#C2C2C2"
        />
      </g>
      <path
        d="M10.4197 13.4812C11.0322 13.2281 11.8211 12.555 11.8211 11.5414C11.8211 9.97983 10.8182 9.02002 9.12876 9.02002C7.62376 9.02002 6.41087 10.0054 6.38935 11.4431H7.48242C7.49858 10.6031 8.06665 9.91253 9.12743 9.91253C10.1492 9.91253 10.7253 10.4442 10.7253 11.5171C10.7253 12.59 9.90417 13.0854 8.90264 13.0854C8.50955 13.0854 8.13937 13.0854 8.13937 13.0854V13.9416C8.13937 13.9416 8.56207 13.9416 8.90264 13.9416C10.3753 13.9416 10.8599 14.7062 10.8599 15.5933C10.8599 16.7537 10.1546 17.2895 9.13283 17.2895C8.24303 17.2895 7.43264 16.8143 7.4084 15.7521H6.30725C6.35437 17.4631 7.79476 18.1752 9.13283 18.1752C10.6782 18.1752 11.953 17.2747 11.953 15.5691C11.9557 14.1933 10.9757 13.644 10.4197 13.4812ZM16.6686 9.02002L13.4809 10.1643V11.1927L15.7693 10.4281V18.1738H16.8463V9.02002H16.6686Z"
        fill="#EEEEEE"
      />
      <path
        d="M7.3571 4.98057L6.31384 3.93598L7.45539 2.79443L9.64289 4.98057H7.3571Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M6.88461 4.17302C7.33069 4.17302 7.69231 3.8114 7.69231 3.36532C7.69231 2.91924 7.33069 2.55762 6.88461 2.55762C6.43853 2.55762 6.0769 2.91924 6.0769 3.36532C6.0769 3.8114 6.43853 4.17302 6.88461 4.17302Z"
        fill="#EEEEEE"
      />
      <path
        d="M6.88461 2.69272C7.30866 2.69272 7.6546 3.01849 7.68827 3.4331C7.69096 3.41022 7.69231 3.38869 7.69231 3.36581C7.69231 2.92023 7.33019 2.55811 6.88461 2.55811C6.43903 2.55811 6.0769 2.92023 6.0769 3.36581C6.0769 3.38869 6.07826 3.41022 6.08094 3.4331C6.11462 3.01849 6.46059 2.69272 6.88461 2.69272Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M7.68827 3.43384C7.65463 3.84845 7.3073 4.17422 6.88461 4.17422C6.46191 4.17422 6.11462 3.84845 6.08094 3.43384C6.07826 3.45672 6.0769 3.47825 6.0769 3.50113C6.0769 3.94671 6.43903 4.30884 6.88461 4.30884C7.33019 4.30884 7.69231 3.94671 7.69231 3.50113C7.69231 3.47825 7.69096 3.45672 7.68827 3.43384Z"
        fill="#212121"
        fillOpacity="0.1"
      />
      <path
        d="M21.5578 13.5957H2.44238V13.7303H21.5578V13.5957Z"
        fill="white"
        fillOpacity="0.05"
      />
      <path
        d="M21.5578 13.4612H2.44238V13.5958H21.5578V13.4612Z"
        fill="#1A237E"
        fillOpacity="0.05"
      />
      <path
        d="M17.588 4.98057L16.5447 3.93598L17.6862 2.79443L19.3353 4.98057H17.588Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M17.1154 4.17302C17.5615 4.17302 17.9231 3.8114 17.9231 3.36532C17.9231 2.91924 17.5615 2.55762 17.1154 2.55762C16.6694 2.55762 16.3077 2.91924 16.3077 3.36532C16.3077 3.8114 16.6694 4.17302 17.1154 4.17302Z"
        fill="#EEEEEE"
      />
      <path
        d="M17.1154 2.69272C17.5395 2.69272 17.8854 3.01849 17.9191 3.4331C17.9205 3.41022 17.9231 3.38869 17.9231 3.36581C17.9231 2.92023 17.561 2.55811 17.1154 2.55811C16.6699 2.55811 16.3077 2.92023 16.3077 3.36581C16.3077 3.38869 16.3091 3.41022 16.3118 3.4331C16.3454 3.01849 16.6914 2.69272 17.1154 2.69272Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        d="M17.9191 3.43384C17.8855 3.84845 17.5381 4.17422 17.1154 4.17422C16.6927 4.17422 16.3455 3.84845 16.3118 3.43384C16.3104 3.45672 16.3077 3.47825 16.3077 3.50113C16.3077 3.94671 16.6699 4.30884 17.1154 4.30884C17.561 4.30884 17.9231 3.94671 17.9231 3.50113C17.9231 3.47825 17.9218 3.45672 17.9191 3.43384Z"
        fill="#212121"
        fillOpacity="0.1"
      />
      <path
        d="M22.5 6.59616C22.5 5.89347 22.0504 5.29711 21.4231 5.075V3.36538C21.4231 2.47289 20.7002 1.75 19.8077 1.75H4.19232C3.2998 1.75 2.57691 2.47289 2.57691 3.36538L2.58095 5.07229C1.9523 5.29307 1.5 5.89076 1.5 6.59616C1.5 6.67154 1.5054 6.74558 1.5148 6.81692L2.57691 13.5961L2.04251 20.4844C2.03983 20.5221 2.03847 20.5585 2.03847 20.5961C2.03847 21.4887 2.76136 22.2115 3.65385 22.2115H20.3462C21.2387 22.2115 21.9615 21.4886 21.9615 20.5961C21.9615 20.5585 21.9602 20.5221 21.9575 20.4844L21.4231 13.5961L22.4852 6.81692C22.4946 6.74558 22.5 6.67154 22.5 6.59616Z"
        fill="url(#paint3_radial)"
        fillOpacity="0.05"
      />
    </g>
    <defs>
      <pattern
        id="pattern0"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#image0" />
      </pattern>
      <linearGradient
        id="paint0_linear"
        x1="7.46549"
        y1="8.79435"
        x2="16.2917"
        y2="17.6205"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1A237E" stopOpacity="0.2" />
        <stop offset="1" stopColor="#1A237E" stopOpacity="0.02" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="6.87107"
        y1="3.35439"
        x2="7.91828"
        y2="4.40159"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#212121" stopOpacity="0.2" />
        <stop offset="1" stopColor="#212121" stopOpacity="0.02" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="17.1018"
        y1="3.35594"
        x2="18.02"
        y2="4.27419"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#212121" stopOpacity="0.2" />
        <stop offset="1" stopColor="#212121" stopOpacity="0.02" />
      </linearGradient>
      <radialGradient
        id="paint3_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(2.94184 2.20212) scale(26.9559)"
      >
        <stop offset="0.33" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0">
        <rect
          width="21"
          height="20.4615"
          fill="white"
          transform="translate(1.5 1.75)"
        />
      </clipPath>
      <image
        id="image0"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAvQAAAF0CAYAAABMhK1TAAAACXBIWXMAAAp1AAAKdQFKJd39AAAA"
      />
    </defs>
  </>
);
