import React from 'react';

import { WrappedVerseTypographyComponentProps } from './typings';
import { VerseLinkify } from './VerseLinkify';
import { VerseTypography } from './VerseTypography';

export const WrappedVerseTypography = ({
  html,
  variantProps,
  testId,
  t,
  children,
  linkify = true,
  linkifyComponentDecorator,
  ...other
}: WrappedVerseTypographyComponentProps) => {
  const childrenToRender = React.useMemo(() => {
    return children ?? t;
  }, [t, children]);

  const renderText = () => {
    return html ? (
      <VerseTypography
        {...variantProps}
        {...other}
        data-testid={testId}
        dangerouslySetInnerHTML={{
          __html: `${childrenToRender}`,
        }}
      />
    ) : (
      <VerseTypography {...variantProps} {...other} data-testid={testId}>
        {childrenToRender}
      </VerseTypography>
    );
  };

  if (linkify) {
    return (
      <VerseLinkify customDecorator={linkifyComponentDecorator}>
        {renderText()}
      </VerseLinkify>
    );
  }

  return renderText();
};
