import React, { useCallback } from 'react';

interface UseHoverFNProps {
  onHover?: (callback: boolean) => void;
  onHoverEvent?: (e?: React.MouseEvent<HTMLElement, MouseEvent> | null) => void;
}

const useHover = ({ onHover, onHoverEvent }: UseHoverFNProps) => {
  const start = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      onHover && onHover(true);
      onHoverEvent && onHoverEvent(e);
    },
    [onHover, onHoverEvent],
  );
  const clear = useCallback(() => {
    onHover && onHover(false);
    onHoverEvent && onHoverEvent(null);
  }, [onHover, onHoverEvent]);

  return {
    onMouseLeave: () => clear(),
    onMouseEnter: (e: React.MouseEvent<HTMLElement, MouseEvent>) => start(e),
  };
};

export default useHover;
