import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ItalicIcon = ({ stroke }: VerseIconSVGProps) => (
  <>
    <path
      d="M11.6059 18.5066H10.1677L12.6617 5.76337H14.0999L11.6059 18.5066Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
  </>
);
