import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ThumbsUpIcon = ({ fill, stroke }: VerseIconSVGProps) => (
  <>
    <path
      d="M13.5435 9.59618C13.7965 7.73867 14.0887 5.06247 14.0887 3.94797C14.0887 3.05358 13.6488 1.17194 12.0258 0.888981C11.9165 0.86993 11.7796 0.861303 11.6312 0.858822C11.0108 0.848452 10.4463 1.38816 10.3233 1.99639C10.241 2.40336 10.1564 2.80212 10.1286 2.97881C9.66386 5.94179 7.83534 9.17562 6.71375 11.3376V20.3929C8.82089 21.1143 10.5081 22.8309 13.5435 22.8309C14.934 22.8309 17.9518 23.1742 19.0816 22.1344C20.5993 20.3929 22.8685 12.1878 21.1125 9.44119C20.3229 8.20599 16.7055 9.1318 13.5435 9.59618Z"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinejoin="round"
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <rect
      x="2.5"
      y="10.4706"
      width="4.21428"
      height="10.9864"
      rx="1"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
  </>
);
