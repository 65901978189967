import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ReplyV2Icon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M1.50022 9.07159L5.85115 4.44441M1.50022 9.07159L5.85115 13.6988M1.50022 9.07159C1.50022 9.07159 6.47211 8.79628 11.756 8.79628C14.117 8.79628 16.4158 8.85125 18.2365 8.91206C20.472 8.98673 22.2844 10.5939 22.4362 12.8255C22.5457 14.4346 22.6333 16.6216 22.6333 19.5"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
  </>
);
