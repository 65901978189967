import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const MultipleChoiceIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M23.1652 6.79694L8.16516 6.79694"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M4.92632 4.76593L3.50871 6.4276L2.2645 7.88602C2.17232 7.99407 2.0064 7.99715 1.91027 7.8926L0.960792 6.85995"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
      strokeLinecap="round"
    />
    <path
      d="M23.1652 12.7969L8.16516 12.7969"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M1.20252 11.9304C1.23339 11.6073 1.47548 11.3652 1.79858 11.3343C2.01507 11.3136 2.3022 11.2969 2.66516 11.2969C3.02812 11.2969 3.31525 11.3136 3.53174 11.3343C3.85485 11.3652 4.09694 11.6073 4.1278 11.9304C4.14848 12.1468 4.16516 12.434 4.16516 12.7969C4.16516 13.1599 4.14848 13.447 4.1278 13.6635C4.09694 13.9866 3.85485 14.2287 3.53174 14.2596C3.31525 14.2803 3.02812 14.2969 2.66516 14.2969C2.3022 14.2969 2.01507 14.2803 1.79858 14.2596C1.47548 14.2287 1.23339 13.9866 1.20252 13.6635C1.18184 13.447 1.16516 13.1599 1.16516 12.7969C1.16516 12.434 1.18184 12.1468 1.20252 11.9304Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M23.1652 18.7969L8.16516 18.7969"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M1.20252 17.9304C1.23339 17.6073 1.47548 17.3652 1.79858 17.3343C2.01507 17.3136 2.3022 17.2969 2.66516 17.2969C3.02812 17.2969 3.31525 17.3136 3.53174 17.3343C3.85485 17.3652 4.09694 17.6073 4.1278 17.9304C4.14848 18.1468 4.16516 18.434 4.16516 18.7969C4.16516 19.1599 4.14848 19.447 4.1278 19.6635C4.09694 19.9866 3.85485 20.2287 3.53174 20.2596C3.31525 20.2803 3.02812 20.2969 2.66516 20.2969C2.3022 20.2969 2.01507 20.2803 1.79858 20.2596C1.47548 20.2287 1.23339 19.9866 1.20252 19.6635C1.18184 19.447 1.16516 19.1599 1.16516 18.7969C1.16516 18.434 1.18184 18.1468 1.20252 17.9304Z"
      fill={Array.isArray(fill) ? fill[1] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
