import React from 'react';

export const ButterIcon = () => (
  <>
    <path
      d="M19.5319 1.1795L15.8809 0.183758C14.5277 -0.173689 13.0468 0.00503418 11.7702 0.592268L3.42128 4.60078C1.35319 5.54546 0 7.71567 0 10.0135V12.5923C0 14.7625 1.17447 16.7795 3.06383 17.8263L7.07234 20.0731C8.01702 20.6093 9.01277 20.8391 10.0085 20.8391C11.1319 20.8391 12.2553 20.5327 13.2511 19.8944L21.2426 14.7114C22.9787 13.588 24 11.6987 24 9.6561V7.00078C24 4.24333 22.1617 1.84333 19.5319 1.1795Z"
      fill="#0A0B1E"
    />
    <path
      d="M4.70283 7.30691C3.65602 7.76648 2.99219 8.83882 2.99219 10.0133V12.592C2.99219 13.6388 3.57942 14.7112 4.5241 15.2473L8.53261 17.4941C9.47729 18.0303 10.7028 18.0303 11.5964 17.3665L19.5879 12.2601C20.4815 11.7239 20.9922 10.7282 20.9922 9.73244V7.07712C20.9922 5.72393 20.0475 4.54946 18.7454 4.19201L15.0943 3.24733C14.3794 3.06861 13.6901 3.11967 13.0262 3.42605L4.70283 7.30691Z"
      fill="#FFFD63"
    />
  </>
);
