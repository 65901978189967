import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ThumbsUpV2Icon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M6.81739 20.8505C7.12425 20.9272 9.96276 24.0726 16.0234 22.615C16.944 22.2314 17.4043 20.9272 17.7878 19.1627C18.1714 17.3983 18.0334 9.4947 15.87 9.11292C13.2616 8.65262 13.1082 7.42516 13.0315 6.50456C12.9547 5.58396 13.415 3.12901 11.7272 1.59467C10.377 0.36718 8.84448 1.28846 8.81204 2.13176C8.73533 4.12635 9.58788 6.55976 8.50515 7.80871C7.67101 8.7709 6.54197 10.2845 6.12694 10.8774C5.58995 11.6445 4.05562 11.2609 3.51858 11.4911C2.31987 12.0048 1.75412 12.872 1.52395 16.0941C1.29333 19.3225 2.78977 20.4669 3.36515 20.6971C4.13231 21.004 6.51052 20.7738 6.81739 20.8505Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M20.4323 9.20673C18.1657 8.97724 15.049 9.20671 13.4624 9.4477C11.0824 9.80918 12.1023 12.6409 13.4624 12.6409C15.0018 12.6409 17.8508 12.6405 20.659 12.1589C22.4156 11.8577 21.6824 9.3333 20.4323 9.20673Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M20.2801 12.5824C18.2615 12.3529 15.4858 12.5824 14.0728 12.8234C11.9532 13.1849 12.8615 16.0166 14.0728 16.0166C15.4438 16.0166 17.9811 16.0162 20.4821 15.5346C22.0465 15.2333 21.3399 12.7029 20.2801 12.5824Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M20.1329 15.9591C18.4026 15.7296 16.0235 15.9591 14.8123 16.2001C12.9955 16.5616 13.774 19.3933 14.8123 19.3933C15.9874 19.3933 18.1623 19.3929 20.306 18.9113C21.6469 18.61 21.0412 16.0796 20.1329 15.9591Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M20.1127 19.3338C18.6899 19.1043 16.7336 19.3338 15.7376 19.5748C14.0291 19.9882 14.5461 22.768 15.7376 22.768C16.7039 22.768 18.4923 22.7676 20.255 22.286C21.3576 21.9847 20.9713 19.4723 20.1127 19.3338Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M6.3567 10.5709C6.04984 10.9289 5.32869 12.2894 5.51281 13.9464"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
