/** delay shortcut prefix unmount to make more delightful UI. */
export const KEYPRESS_PREFIX_CONTAINER_MOTION_PROPS = {
  variants: {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
    },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
  transition: { type: 'linear', duration: 0.1 },
};

export enum VerseShortcutBadgeDirectionEnum {
  ROW = 'row',
  COLUMN = 'column',
}
