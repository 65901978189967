import * as React from "react";

import { styled } from "@stitches/react";
import styledComponent from "styled-components";
import { VerseKeyframes } from "src/verse/src";
export const StyledToolsPanelContainer = styled("div", {
  position: "absolute",
  minWidth: 0,
  maxWidth: "100%",
  display: "grid",
  gridTemplateColumns: "auto auto auto",
  gridTemplateRows: "auto auto",
  justifyContent: "space-between",
  padding: "0",
  gap: "$1",
  zIndex: 200,
  pointerEvents: "none",
  "& > div > *": {
    pointerEvents: "all",
  },
  width: "min-content",
  height: "100%",
  left: 10,
  variants: {
    debug: {
      true: {},
      false: {},
    },
    bp: {
      mobile: {},
      small: {},
      medium: {},
      large: {},
    },
  },
  compoundVariants: [
    {
      bp: "large",
      css: {
        top: 10,
      },
    },
    {
      debug: true,
      css: {
        bottom: 40,
      },
    },
  ],
});

export const StyledCenterWrap = styled("div", {
  gridRow: 1,
  gridColumn: 2,
  display: "flex",
  width: "fit-content",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "$4",
  position: "relative",
});
const fadeInAnimation = VerseKeyframes.fadeInKeyframes();

export const StyledToolbar = styledComponent.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 4px;
  position: relative;

  width: 56px;
  height: min-content;
${({ isSelected, theme }) =>
  isSelected ? `border: 1px solid ${theme.colors.other.Blue100};` : ""}

  background: ${({ theme }) => theme.colors.other.White};

  box-shadow: ${({ theme }) => theme.shadows.cardShadowWithBorder};
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;


  animation: ${fadeInAnimation} ${({ theme }) => theme.animationSpeed}ms 1;
`;
