import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const SendIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M2.52192 22.5113L22.6137 12.9021C23.3718 12.5396 23.3718 11.4604 22.6137 11.0979L2.60462 1.5283C2.03396 1.25537 1.36832 1.5772 1.27144 2.2023C1.13972 3.0522 1 4.29631 1 5.75C1 7.13224 1.12632 8.325 1.25197 9.16955C1.37233 9.97846 2.03134 10.5725 2.84165 10.683L11.7734 11.9009C11.8887 11.9166 11.8887 12.0834 11.7734 12.0991L2.73347 13.3318C1.97832 13.4348 1.35802 13.9624 1.24065 14.7154C1.11884 15.497 0.999999 16.6655 0.999999 18.25C0.999999 19.9111 1.13061 21.115 1.25833 21.8952C1.3555 22.4888 1.97929 22.7708 2.52192 22.5113Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
