import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import styled, { keyframes } from 'styled-components';
import { useVerseTheme } from '../../hooks';
import { VerseTheme } from '../../theme';
import { useHover } from '../../utils';

import usePortal from '../../utils/usePortal';
import { VerseBox } from '../VerseBox';
import { VerseButton } from '../VerseButton';
import { VerseButtonVariantEnum } from '../VerseButton/consts';

import { VerseBasePopover } from '../VersePopover';
import { VerseBasePopoverVariantEnum } from '../VersePopover/consts';
import { Body1, H4 } from '../VerseTypography';
import {
  VersePulseHelpComponentProps,
  VersePulseHelpInnerContainerProps,
} from './typings';

export const VersePulseHelp = ({
  anchorEl,
  open,
  placement = 'bottom',
  testId,
  className,
  zIndex,
  popoverEnabled = true,
  primaryButton,
  secondaryButton,
  title,
  description,
  offsetX = -15,
  offsetY = 0,
  popoverPlacement = 'bottom',
  zIndexPulseContainer = zIndex,
}: VersePulseHelpComponentProps) => {
  const [positionedEl, setPositionedEl] = useState<HTMLElement | null>(null);
  const popoverTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const theme = useVerseTheme();

  const { styles, attributes, update } = usePopper(anchorEl, positionedEl, {
    strategy: 'fixed',
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [offsetY, offsetX],
        },
      },
    ],
  });

  useEffect(() => {
    update && update();
  }, [open, update]);

  const portalRef = usePortal({ portalId: 'verse-pulse-help-portal' });

  const [delayedOpen, setDelayedOpen] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => {
      setDelayedOpen(Boolean(open));
    }, 1);
  }, [open]);

  const handleOnHover = (
    event?: React.MouseEvent<HTMLElement, MouseEvent> | null,
  ) => {
    if (!popoverEnabled) {
      setIsHovered(false);
      return null;
    }

    if (event) {
      if (popoverTimeout.current) {
        clearTimeout(popoverTimeout.current);
      }
      event.preventDefault();
      setIsHovered(true);
    } else {
      if (popoverTimeout.current) {
        clearTimeout(popoverTimeout.current);
      }
      popoverTimeout.current = setTimeout(() => {
        setIsHovered(false);
      }, theme.animationSpeed);
    }
  };

  const hoverEvents = useHover({
    onHoverEvent: handleOnHover,
  });

  function onPopoverCombinedMouseLeave() {
    setIsHovered(false);
  }
  function onPopoverCombinedMouseEnter() {
    if (popoverTimeout.current) {
      clearTimeout(popoverTimeout.current);
    }
  }

  if (open) {
    return (
      <>
        <VerseBasePopover
          anchorEl={positionedEl}
          open={isHovered}
          zIndex={(zIndex ?? theme.zIndex.level.popup) + 1}
          placement={popoverPlacement}
          variant={VerseBasePopoverVariantEnum.BLUE}
          padding={`${theme.spacing(3)}px`}
          onMouseEnter={onPopoverCombinedMouseEnter}
          onMouseLeave={onPopoverCombinedMouseLeave}
          maxWidth={376}
          width="100%"
          borderRadius={24}
          ariaLive="polite"
        >
          <H4 mb={1.5}>{title}</H4>
          <Body1 html>{description}</Body1>

          {secondaryButton || primaryButton ? (
            <VerseBox
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              width="100%"
              mt={3}
            >
              {secondaryButton ? (
                <VerseButton
                  variant={VerseButtonVariantEnum.SECONDARY_CTA}
                  mr={1}
                  testId={`versePulseHelp-secondaryButton`}
                  {...secondaryButton}
                />
              ) : null}
              {primaryButton ? (
                <VerseButton
                  variant={VerseButtonVariantEnum.PRIMARY_CTA}
                  testId={`versePulseHelp-primaryButton`}
                  {...primaryButton}
                />
              ) : null}
            </VerseBox>
          ) : null}
        </VerseBasePopover>

        {createPortal(
          <RootContainer
            ref={setPositionedEl}
            style={styles.popper}
            data-testid={testId}
            zIndex={zIndexPulseContainer}
            className={className}
            {...attributes.popper}
          >
            <AnimatedContainer
              open={Boolean(open && delayedOpen)}
              {...hoverEvents}
            >
              <PulseContainer />
            </AnimatedContainer>
          </RootContainer>,
          portalRef,
        )}
      </>
    );
  }
  return null;
};

const customScaleKeyframe = keyframes`
  0% {
    transform: scale(1);
  }
  85% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const customPulseKeyframe = keyframes`
  0% {
    background: ${VerseTheme.colors.other.Blue75RGBA};
    transform: scale(1);
    opacity: 0;
  }

  12% {
    background: ${VerseTheme.colors.other.Blue25RGBA};
    transform: scale(3);
    opacity: 1;
  }

  25% {
    background: ${VerseTheme.colors.other.Blue25RGBA};
    transform: scale(4);
    opacity: 0.5;
  }

  50% {
    background: ${VerseTheme.colors.other.Blue10RGBA};
    transform: scale(4);
    opacity: 0;
  }

  100% {
    background: ${VerseTheme.colors.other.Blue75RGBA};
    transform: scale(1);
    opacity: 0;
  }
`;

const PulseContainer = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  animation: 2s linear infinite ${customPulseKeyframe};
  border-radius: 50%;
  z-index: -1;
`;

const AnimatedContainer = styled.div<{
  open: boolean;
}>`
  opacity: 0;
  opacity: ${({ open }) => (open ? '1' : '0')};
  transition: opacity ${({ theme }) => theme.animationSpeed}ms linear;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(3.5)}px;

  &:after {
    background: ${({ theme }) => theme.colors.other.Blue100};
    position: absolute;
    content: '';
    width: 15px;
    height: 15px;
    animation: 2s linear infinite ${customScaleKeyframe};
    border-radius: 50%;
  }

  &:before {
    background: ${({ theme }) => theme.colors.other.Blue75RGBA};
    position: absolute;
    content: '';
    width: 35px;
    height: 35px;
    border-radius: 50%;
    opacity: 0.5;
  }
`;

const RootContainer = styled.div<VersePulseHelpInnerContainerProps>`
  && {
    z-index: ${({ zIndex, theme }) =>
      zIndex ? zIndex : theme.zIndex.level.popup};
  }
`;
