import React from 'react';

// viewBox of 0 0 20 20
export const LoveThisEmoji = () => (
  <>
    <circle cx="10.3298" cy="10.3298" r="7.32976" fill="#FFFD63" />
    <circle
      cx="10.3298"
      cy="10.3298"
      r="7.32976"
      fill="#FFFD63"
      stroke="black"
      strokeWidth="0.5"
    />
    <path
      d="M4.83231 7.03136C4.6868 7.97718 5.40822 9.17794 7.81658 10.225C9.5443 8.34025 9.85333 7.0563 9.23018 6.03661C8.65426 5.0942 7.24066 5.5654 6.97888 6.35074C6.7171 5.87953 5.04174 5.67011 4.83231 7.03136Z"
      fill="#FF5A52"
      stroke="black"
      strokeWidth="0.5"
    />
    <path
      d="M15.8615 7.03136C16.007 7.97718 15.2856 9.17794 12.8773 10.225C11.1495 8.34025 10.8405 7.0563 11.4637 6.03661C12.0396 5.0942 13.4532 5.5654 13.715 6.35074C13.9767 5.87953 15.6521 5.67011 15.8615 7.03136Z"
      fill="#FF5A52"
      stroke="black"
      strokeWidth="0.5"
    />
    <path
      d="M14.6751 12.3716C15.251 11.2722 14.6227 11.2721 13.8897 11.1675C12.4238 11.0104 8.18298 10.8533 6.55997 11.2198C5.57763 11.4416 5.71527 11.6411 5.87935 12.1099C6.24584 13.1569 7.71181 15.2512 10.2772 15.2512C12.8426 15.2512 14.0992 13.4711 14.6751 12.3716Z"
      fill="black"
      stroke="black"
      strokeWidth="0.5"
    />
    <path
      d="M10.3295 14.0993C9.44994 14.0993 8.75885 14.4658 8.49707 14.8847C9.07298 15.1464 9.6803 15.2512 10.4342 15.2512C11.1881 15.2512 11.97 14.9719 12.2667 14.8323C11.7955 14.4135 11.2091 14.0993 10.3295 14.0993Z"
      fill="#FF5A52"
    />
    <path
      d="M10.2771 12.2669C8.68554 12.2669 7.13582 12.0051 5.93164 11.4292C6.50755 11.0104 8.32952 10.958 10.1724 10.958C12.0153 10.958 14.5703 10.958 14.8321 11.4292C13.5755 12.0051 11.8688 12.2669 10.2771 12.2669Z"
      fill="white"
      stroke="black"
      strokeWidth="0.5"
    />
  </>
);
