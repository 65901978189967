import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const RefreshIcon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C15.6805 1 18.9388 2.80754 20.9356 5.58333M20.9356 5.58333L21.1667 2.83333M20.9356 5.58333H17.9583"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
