import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const HighlightEndIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <circle
      cx="12"
      cy="12"
      r="6"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      fill={Array.isArray(fill) ? fill[0] : fill}
      strokeWidth={strokeWidth}
    />
    <path
      d="M1.16301 7.00061C2.05206 6.93857 2.82068 6.86429 3.47302 6.7879C5.24609 6.58026 6.58014 5.24621 6.78777 3.47314C6.86416 2.8208 6.93845 2.05219 7.00049 1.16315"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
      strokeWidth={strokeWidth}
      fill="none"
    />
    <path
      d="M22.8384 7.00061C21.9493 6.93857 21.1807 6.86429 20.5284 6.7879C18.7553 6.58026 17.4212 5.24621 17.2136 3.47314C17.1372 2.8208 17.0629 2.05217 17.0009 1.16312"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
      strokeWidth={strokeWidth}
      fill="none"
    />
    <path
      d="M7.00073 22.837C6.93869 21.948 6.86441 21.1794 6.78802 20.527C6.58039 18.754 5.24633 17.4199 3.47326 17.2123C2.82091 17.1359 2.05228 17.0616 1.16321 16.9996"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
      strokeWidth={strokeWidth}
      fill="none"
    />
    <path
      d="M22.8372 16.9995C21.9481 17.0616 21.1795 17.1359 20.5271 17.2123C18.754 17.4199 17.42 18.7539 17.2124 20.527C17.136 21.1794 17.0617 21.948 16.9996 22.837"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
      strokeWidth={strokeWidth}
      fill="none"
    />
  </>
);
