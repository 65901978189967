import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const LightningIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M20.0436 10.811C19.3152 10.7197 16.7533 10.6608 15.262 10.6343C14.6742 10.6239 14.2183 10.1136 14.3041 9.53197C14.4359 8.63919 14.6286 7.55181 14.8214 6.31187C15.0079 4.89787 15.1944 3.03396 15.1011 2.0056C15.0079 1.04151 13.8888 0.655874 13.2361 1.36287L3.35116 11.2609C2.69838 11.9679 2.97814 13.0605 4.00393 13.189C4.7323 13.2803 7.29427 13.3392 8.7855 13.3657C9.37335 13.3761 9.82821 13.8858 9.75267 14.4689C9.60411 15.6156 9.3874 17.0789 9.31941 17.6881C9.22615 18.6522 8.85314 20.966 8.94639 21.9944C9.03965 22.9585 10.1587 23.3441 10.8115 22.6371L20.6031 12.7391C21.3492 12.0321 20.9761 10.9395 20.0436 10.811Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
