import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const PinIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M8.61199 4.35071L7.85727 9.63372C7.79993 10.0351 7.62197 10.4095 7.34698 10.7074L5.96693 12.2025C5.54331 12.6614 5.78962 13.3826 6.40993 13.4553C7.58776 13.5932 9.48038 13.75 12 13.75C14.5196 13.75 16.4122 13.5932 17.5901 13.4553C18.2104 13.3826 18.4567 12.6614 18.0331 12.2025L16.653 10.7074C16.378 10.4095 16.2001 10.0351 16.1427 9.63372L15.388 4.35071C15.2902 3.66624 15.5529 2.97981 16.0827 2.53549L16.414 2.25764C16.9145 1.83784 16.7194 1.07034 16.0714 0.98782C15.1227 0.867009 13.7517 0.75 12 0.75C10.2483 0.75 8.87733 0.867009 7.9286 0.98782C7.28057 1.07034 7.0855 1.83784 7.58603 2.25764L7.91731 2.53549C8.44708 2.97981 8.70977 3.66624 8.61199 4.35071Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
    />
    <path
      d="M11 13.75L12 23.25L13 13.75"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
