import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const BaseAllIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M1.37539 4.68872C1.58307 2.91566 2.91566 1.58307 4.68872 1.37539C6.34923 1.18088 8.76351 1 12 1C15.2365 1 17.6508 1.18088 19.3113 1.37539C21.0843 1.58307 22.4169 2.91566 22.6246 4.68872C22.8191 6.34923 23 8.76351 23 12C23 15.2365 22.8191 17.6508 22.6246 19.3113C22.4169 21.0843 21.0843 22.4169 19.3113 22.6246C17.6508 22.8191 15.2365 23 12 23C8.76351 23 6.34923 22.8191 4.68872 22.6246C2.91566 22.4169 1.58307 21.0843 1.37539 19.3113C1.18088 17.6508 1 15.2365 1 12C1 8.76351 1.18088 6.34923 1.37539 4.68872Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M4.26804 6.76212C4.42141 5.42734 5.42734 4.42141 6.76212 4.26804C7.97668 4.12849 9.7153 4 12 4C14.2994 4 16.0456 4.13015 17.2612 4.27073C18.5833 4.42364 19.5809 5.41545 19.7334 6.7376C19.8724 7.94334 20 9.68162 20 12C20 14.3184 19.8724 16.0567 19.7334 17.2624C19.5809 18.5846 18.5833 19.5764 17.2612 19.7293C16.0456 19.8699 14.2994 20 12 20C9.7153 20 7.97668 19.8715 6.76212 19.732C5.42734 19.5786 4.42141 18.5727 4.26804 17.2379C4.12849 16.0233 4 14.2847 4 12C4 9.7153 4.12849 7.97668 4.26804 6.76212Z"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M7.16647 8.7862C7.26404 7.92078 7.92078 7.26404 8.7862 7.16647C9.55619 7.07966 10.6347 7 12 7C13.3653 7 14.4438 7.07966 15.2138 7.16647C16.0792 7.26404 16.736 7.92078 16.8335 8.7862C16.9203 9.55619 17 10.6347 17 12C17 13.3653 16.9203 14.4438 16.8335 15.2138C16.736 16.0792 16.0792 16.736 15.2138 16.8335C14.4438 16.9203 13.3653 17 12 17C10.6347 17 9.55619 16.9203 8.7862 16.8335C7.92078 16.736 7.26404 16.0792 7.16647 15.2138C7.07966 14.4438 7 13.3653 7 12C7 10.6347 7.07966 9.55619 7.16647 8.7862Z"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M10.0675 10.7866C10.1085 10.4044 10.4044 10.1085 10.7866 10.0675C11.1139 10.0324 11.5407 10 12 10C12.4593 10 12.8861 10.0324 13.2134 10.0675C13.5956 10.1085 13.8915 10.4044 13.9325 10.7866C13.9676 11.1139 14 11.5407 14 12C14 12.4593 13.9676 12.8861 13.9325 13.2134C13.8915 13.5956 13.5956 13.8915 13.2134 13.9325C12.8861 13.9676 12.4593 14 12 14C11.5407 14 11.1139 13.9676 10.7866 13.9325C10.4044 13.8915 10.1085 13.5956 10.0675 13.2134C10.0324 12.8861 10 12.4593 10 12C10 11.5407 10.0324 11.1139 10.0675 10.7866Z"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
