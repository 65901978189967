import styled from "styled-components";

export const StyledToolButton = styled.div<{
  isActive?: boolean;
  disabled?: boolean;
  isActiveColor?: string;
  hoverColor?: string;
}>`
  ${({ theme, isActive, isActiveColor, hoverColor }) =>
    isActive
      ? `background-color: ${isActiveColor ?? theme.colors.other.Blue100}`
      : `
    :hover {
        background: ${hoverColor ?? theme.colors.other.Blue25};
      }
    `};

  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.other.White : theme.colors.main.PrimaryDark100};
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  border-radius: 8px;
  & svg {
    width: 16px;
    height: 16px;
  }

  ${({ disabled }) => (disabled ? `opacity: 0.6;` : "")}
`;
