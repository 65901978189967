import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const HighlightIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M6.99995 1.16254C6.1109 1.22458 5.34228 1.29886 4.68995 1.37525C2.91688 1.58288 1.58282 2.91694 1.37519 4.69001C1.2988 5.34234 1.22452 6.11096 1.16248 7M17 1.16254C17.889 1.22458 18.6576 1.29886 19.31 1.37525C21.083 1.58288 22.4171 2.91694 22.6247 4.69001C22.7011 5.34235 22.7754 6.11097 22.8374 7.00003M1.16248 17C1.22452 17.8891 1.2988 18.6577 1.37519 19.31C1.58282 21.0831 2.91688 22.4171 4.68995 22.6248C5.3423 22.7012 6.11093 22.7755 7 22.8375M16.9999 22.8375C17.889 22.7755 18.6576 22.7012 19.31 22.6248C21.083 22.4171 22.4171 21.0831 22.6247 19.31C22.7011 18.6577 22.7754 17.8891 22.8374 17"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
      strokeWidth={strokeWidth}
      fill="none"
    />
    <circle
      cx="12"
      cy="12"
      r="6"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      fill={Array.isArray(fill) ? fill[0] : fill}
      strokeWidth={strokeWidth}
    />
  </>
);
