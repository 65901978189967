import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const FunnelIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M9.5 13.8366V22.3499C9.5 22.9683 10.2431 23.2797 10.6872 22.8493L13.8919 19.7432C14.2806 19.3665 14.5 18.8484 14.5 18.3071V13.84C14.5 13.3027 14.7162 12.7881 15.0998 12.4119L21.8815 5.76315C22.3794 5.27504 22.6599 4.60713 22.6599 3.90991C22.6599 2.70177 21.8234 1.66739 20.6259 1.50742C18.8627 1.27189 15.968 1 12 1C8.03203 1 5.13731 1.27189 3.37408 1.50742C2.17658 1.66739 1.34009 2.70177 1.34009 3.90991C1.34009 4.60713 1.62062 5.27504 2.11849 5.76315L8.90015 12.4119C9.28382 12.7881 9.5 13.2993 9.5 13.8366Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
