import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const StrikethroughIcon = ({ stroke }: VerseIconSVGProps) => (
  <>
    <path
      d="M12.328 5.43466C13.36 5.43466 14.428 5.69866 15.532 6.22666L14.884 7.55866C14.02 7.07866 13.162 6.83866 12.31 6.83866C11.686 6.83866 11.212 6.97066 10.888 7.23466C10.576 7.48666 10.42 7.83466 10.42 8.27866C10.42 8.66266 10.528 8.99866 10.744 9.28666C10.972 9.56266 11.314 9.86866 11.77 10.2047L13.84 11.7347C14.548 12.2507 15.076 12.7787 15.424 13.3187C15.772 13.8467 15.946 14.4707 15.946 15.1907C15.946 16.2827 15.592 17.1227 14.884 17.7107C14.176 18.2867 13.228 18.5747 12.04 18.5747C11.332 18.5747 10.63 18.4727 9.93401 18.2687C9.23801 18.0527 8.61401 17.7707 8.06201 17.4227L8.72801 16.1267C9.85601 16.8227 10.96 17.1707 12.04 17.1707C12.832 17.1707 13.432 16.9967 13.84 16.6487C14.26 16.2887 14.47 15.8327 14.47 15.2807C14.47 14.8607 14.344 14.4767 14.092 14.1287C13.84 13.7687 13.42 13.3727 12.832 12.9407L10.78 11.3927C10.18 10.9367 9.71801 10.4747 9.39401 10.0067C9.08201 9.53866 8.92601 8.98066 8.92601 8.33266C8.92601 7.44466 9.22001 6.74266 9.80801 6.22666C10.408 5.69866 11.248 5.43466 12.328 5.43466Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M19.4989 12L4.4989 12"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
    />
  </>
);
