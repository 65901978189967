import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const MP3Icon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M1.37539 4.68872C1.58307 2.91566 2.91566 1.58307 4.68872 1.37539C6.34923 1.18088 8.76351 1 12 1C15.2365 1 17.6508 1.18088 19.3113 1.37539C21.0843 1.58307 22.4169 2.91566 22.6246 4.68872C22.8191 6.34923 23 8.76351 23 12C23 15.2365 22.8191 17.6508 22.6246 19.3113C22.4169 21.0843 21.0843 22.4169 19.3113 22.6246C17.6508 22.8191 15.2365 23 12 23C8.76351 23 6.34923 22.8191 4.68872 22.6246C2.91566 22.4169 1.58307 21.0843 1.37539 19.3113C1.18088 17.6508 1 15.2365 1 12C1 8.76351 1.18088 6.34923 1.37539 4.68872Z"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <path
      d="M8.69999 8.60001H9.50749L11.1225 15.25H10.068L8.96599 10.5H8.94699L7.45549 14.2335H6.71449L5.22299 10.5H5.20399L4.10199 15.25H3.04749L4.66249 8.60001H5.46999L7.07549 12.685H7.09449L8.69999 8.60001Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M14.1088 8.60001C14.5395 8.60001 14.91 8.68867 15.2203 8.86601C15.5307 9.03701 15.765 9.27451 15.9233 9.57851C16.088 9.88251 16.1703 10.2277 16.1703 10.614C16.1703 11.0003 16.088 11.3455 15.9233 11.6495C15.765 11.9535 15.5307 12.1942 15.2203 12.3715C14.91 12.5425 14.5395 12.628 14.1088 12.628H12.8263V15.25H11.8383V8.60001H14.1088ZM13.9758 11.697C14.3242 11.697 14.5997 11.6052 14.8023 11.4215C15.005 11.2315 15.1063 10.9623 15.1063 10.614C15.1063 10.2657 15.005 9.99967 14.8023 9.81601C14.5997 9.62601 14.3242 9.53101 13.9758 9.53101H12.8263V11.697H13.9758Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M18.6785 8.69501C19.2738 8.69501 19.7488 8.84701 20.1035 9.15101C20.4645 9.44867 20.645 9.84451 20.645 10.3385C20.645 10.7122 20.5436 11.032 20.341 11.298C20.1383 11.564 19.8565 11.7382 19.4955 11.8205V11.8395C19.9388 11.8902 20.2776 12.058 20.512 12.343C20.7526 12.6217 20.873 12.9953 20.873 13.464C20.873 14.0657 20.683 14.5375 20.303 14.8795C19.9293 15.2215 19.4131 15.3925 18.7545 15.3925C18.0958 15.3925 17.5765 15.2278 17.1965 14.8985C16.8165 14.5692 16.617 14.1132 16.598 13.5305H17.529C17.5543 13.8408 17.6746 14.0847 17.89 14.262C18.1053 14.433 18.3935 14.5185 18.7545 14.5185C19.1281 14.5185 19.41 14.4267 19.6 14.243C19.7963 14.053 19.8945 13.7743 19.8945 13.407C19.8945 13.0143 19.7741 12.7325 19.5335 12.5615C19.2991 12.3905 18.916 12.3082 18.384 12.3145H18.08V11.4785H18.384C19.2453 11.4785 19.676 11.1523 19.676 10.5C19.676 10.2023 19.5873 9.97434 19.41 9.81601C19.239 9.65134 18.9888 9.56901 18.6595 9.56901C18.3238 9.56901 18.061 9.64817 17.871 9.80651C17.6873 9.95851 17.5923 10.1802 17.586 10.4715H16.655C16.674 9.92684 16.864 9.49617 17.225 9.17951C17.5923 8.85651 18.0768 8.69501 18.6785 8.69501Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
  </>
);
