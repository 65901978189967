import React from 'react';
import styled from 'styled-components';
import { VerseHelpText, VerseIcon, VerseIconIdEnum } from '..';
import { useVerseTheme } from '../..';
import { VerseKeyframes } from '../VerseKeyframes';
import { VerseTextAreaDataValueEnum, VerseTextAreaSizeEnum } from './consts';
import { useVerseTextAreaController } from './hooks';
import {
  VerseTextAreaComponentProps,
  VerseTextAreaWrapperProps,
} from './typings';

export const VerseTextArea = ({
  testId,
  ...other
}: VerseTextAreaComponentProps) => {
  const {
    inputWrapperProps,
    styledInputProps,
    inputIconSize,
    showErrorIcon,
    isPasswordType,
    isPasswordVisible,
    togglePasswordVisibility,
    remainingChar,
  } = useVerseTextAreaController(other);
  const theme = useVerseTheme();
  return (
    <InputWrapper {...inputWrapperProps}>
      <RowWrapper>
        <StyledTextArea
          {...styledInputProps}
          data-testid={testId}
          maxLength={other?.maxLength ?? 250}
        />
        {isPasswordType && (
          <IconContainer onClick={togglePasswordVisibility}>
            <VerseIcon
              iconId={
                isPasswordVisible ? VerseIconIdEnum.HIDE : VerseIconIdEnum.EYE
              }
              stroke={theme.colors.main.PrimaryDark100}
              size={inputIconSize}
              fill="none"
            />
          </IconContainer>
        )}
        {showErrorIcon && (
          <IconContainer id="error_icon_container">
            <VerseIcon
              iconId={VerseIconIdEnum.WARNING_V2}
              stroke={theme.colors.other.Danger100}
              size={inputIconSize}
              fill="none"
            />
          </IconContainer>
        )}
      </RowWrapper>

      <VerseHelpText mt={0.5} id="remaining_char_text">
        {remainingChar}
      </VerseHelpText>
    </InputWrapper>
  );
};
const scaleAnimation = VerseKeyframes.scalePopKeyframes({
  from: 0,
  to: 1,
});
const IconContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing(1)}px;

  :nth-child(2) {
    margin-left: 0;
  }
  animation: ${scaleAnimation} ${({ theme }) => theme.animationSpeed}ms 1;
`;

const StyledTextArea = styled.textarea`
  border: none;
  width: 100%;
  height: 100%;
  background: none;

  font-family: GTEestiDisplay;
  font-weight: 300;
  resize: none;
  overflow-x: hidden;
  overflow-y: auto;

  /* Chrome, Firefox, Opera, Safari 10.1+ */
  ::placeholder {
    color: ${({ theme }) => theme.colors.main.PrimaryDark30};
    opacity: 1;
  }
  /* Internet Explorer 10-11 */
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.main.PrimaryDark30};
  }
  /* Microsoft Edge */
  ::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.main.PrimaryDark30};
  }
`;
const InputWrapper = styled.div<VerseTextAreaWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.colors.other.White};
  border: 1px solid;

  transition: background-color 0.2s linear, border-color 0.2s linear;
  height: 115px;
  ${({ width }) =>
    width && `width: ${Number.isInteger(width) ? `${width}px` : width};`}

  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
    ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
    ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
    ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}

  ${({ dataValues, disabled, theme }) => {
    let cssString = ``;
    if (dataValues?.includes(VerseTextAreaDataValueEnum.FILLED)) {
      if (dataValues?.includes(VerseTextAreaDataValueEnum.FOCUS)) {
        cssString =
          cssString + `border-color:${theme.colors.main.PrimaryDark100};`;
      } else {
        cssString =
          cssString +
          `border-color:${theme.colors.main.PrimaryDark50};:hover{border-color:${theme.colors.main.PrimaryDark70};}`;
      }
    } else {
      if (dataValues?.includes(VerseTextAreaDataValueEnum.FOCUS)) {
        cssString =
          cssString + `border-color:${theme.colors.main.PrimaryDark100};`;
      } else {
        cssString =
          cssString +
          `border-color:${theme.colors.main.PrimaryDark30};:hover{border-color:${theme.colors.main.PrimaryDark50};}`;
      }
    }
    if (disabled) {
      cssString =
        cssString +
        `border-color:${theme.colors.main.PrimaryDark20}!important;background-color:${theme.colors.other.Sand03}!important;`;
    } else if (dataValues?.includes(VerseTextAreaDataValueEnum.INVALID)) {
      cssString =
        cssString +
        `border-color:${theme.colors.other.Danger50}!important;background-color:${theme.colors.other.Danger10}!important;`;
    }
    return cssString;
  }}

  ${({ size, theme }) => {
    switch (size) {
      case VerseTextAreaSizeEnum.SMALL:
        return `
                border-radius: 10px;
                padding: ${theme.spacing(1)}px ${theme.spacing(
          1.5,
        )}px ${theme.spacing(1.5)}px;
                ${StyledTextArea} {font-size: 14px;}
            `;
      case VerseTextAreaSizeEnum.MEDIUM:
        return `
                border-radius: 12px;
                padding: ${theme.spacing(1)}px ${theme.spacing(
          2,
        )}px ${theme.spacing(1.5)}px;
                ${StyledTextArea} {font-size: 15px;}
            `;
      case VerseTextAreaSizeEnum.LARGE:
        return `
                border-radius: 16px;
                padding: ${theme.spacing(1.25)}px ${theme.spacing(
          2.5,
        )}px ${theme.spacing(1.5)}px;
                ${StyledTextArea} {font-size: 16px;}
            `;
      default:
        return ``;
    }
  }}
`;
const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
`;
