import React from 'react';
import styled from 'styled-components';
import { components, MenuProps } from 'react-select';
import { VerseSelectOptionObj } from '..';

export const VerseCreatableSelectMenu = (
  props: MenuProps<VerseSelectOptionObj, true>,
) => {
  return (
    <StyledVerseCreatableSelectMenu>
      <components.Menu {...props}>{props.children}</components.Menu>
    </StyledVerseCreatableSelectMenu>
  );
};

const StyledVerseCreatableSelectMenu = styled.div`
  font-family: GTEestiDisplay;
  font-size: 14px;
  font-weight: 300;
`;
