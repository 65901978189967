export enum PrimaryAppTypesEnum {
  MIRO = "miro",
  GARTIC = "gartic",
  CODENAMES = "codenames",
  YOUTUBE = "youtube",
  SCREENSHARE = "screenshare",
  WHITEBOARD = "whiteboard",
  PDF = "pdf",
  EMBED = "embed",
  FIGMA = "figma",
  GDRIVE = "google-drive",
  CARDDECK = "card-deck",
  BUTTERBOARD = "butterboard",
  FLASHCARD = "flashcard",
  MURAL = "mural",
}

export const PRIMARY_APP_TEST_IDS = {
  [PrimaryAppTypesEnum.MIRO]: "primaryApp-miroContainer",
  [PrimaryAppTypesEnum.GARTIC]: "primaryApp-garticContainer",
  [PrimaryAppTypesEnum.CODENAMES]: "primaryApp-codenamesContainer",
  [PrimaryAppTypesEnum.YOUTUBE]: "primaryApp-youtubeContainer",
  [PrimaryAppTypesEnum.SCREENSHARE]: "primaryApp-screenshareContainer",
  [PrimaryAppTypesEnum.WHITEBOARD]: "primaryApp-whiteboardContainer",
  [PrimaryAppTypesEnum.PDF]: "primaryApp-pdfContainer",
  [PrimaryAppTypesEnum.EMBED]: "primaryApp-embedContainer",
  [PrimaryAppTypesEnum.FIGMA]: "primaryApp-figmaContainer",
  [PrimaryAppTypesEnum.GDRIVE]: "primaryApp-googleDriveContainer",
  [PrimaryAppTypesEnum.CARDDECK]: "primaryApp-cardDeckContainer",
  [PrimaryAppTypesEnum.FLASHCARD]: "primaryApp-flashcardContainer",
  [PrimaryAppTypesEnum.MURAL]: "primaryApp-muralContainer",
};

export enum GDriveTypesEnum {
  SLIDES = "google-slides",
  DOCS = "google-docs",
  SHEETS = "google-sheets",
}

export enum MiscToolboxItemTypeEnum {
  FLASHCARD = "flashcard",
  BUTTERBOARD = "butterboard",
  EMBED = "embed",
}

export const FLASHCARD_TEST_IDS = {
  INPUT: {
    NAME: "flashcard-nameInput",
  },
  ICON_BUTTON: {
    PREVIOUS: "flashcard-previousIconButton",
    NEXT: "flashcard-nextIconButton",
    CLOSE: "flashcard-closeIconButton",
  },
  BUTTON: {
    CLOSE: "flashcard-closeButton",
    START_AGAIN: "flashcard-startAgainButton",
  },
  TAB: {
    BASIC: "flashcard-basicTab",
    INTERACTIVE: "flashcard-interactiveTab",
  },
  TYPE_SELECTION: {
    TEXT_ONLY: "flashcard-textOnly",
    TEXT_AND_IMAGE: "flashcard-textAndImage",
    IMAGE_AND_TEXT: "flashcard-imageAndText",
    IMAGE_VIDEO_AND_TEXT: "flashcard-imageVideoAndText",
    VIDEO_ONLY: "flashcard-videoOnly",
    IMAGE_GIF_ONLY: "flashcard-imageGifOnly",
    EITHER_OR_IMAGE: "flashcard-eitherOrImage",
    EITHER_OR_TEXT: "flashcard-eitherOrText",
  },
  FORM: {
    INPUT: {
      CARD_NAME: "flashcardForm-cardNameInput",
      CARD_NAME2: "flashcardForm-cardName2Input",
      RENAME: "flashcardForm-renameInput",
    },
    BUTTON: {
      ADD_NEW_CARD: "flashcardForm-addNewCardButton",
      UPLOAD_VIDEO: "flashcardForm-uploadVideoButton",
      CHOOSE_IMAGE: "flashcardForm-chooseImageButton",
      CHOOSE_IMAGE1: "flashcardForm-chooseImage1Button",
      CHOOSE_IMAGE2: "flashcardForm-chooseImage2Button",
      SETUP_NEXT: "flashcardForm-setupNextButton",
      SETUP_CANCEL: "flashcardForm-setupCancelButton",
      SETUP_FORM_CANCEL: "flashcardForm-setupFormCancelButton",
      SETUP_SAVE: "flashcardForm-setupFormSaveButton",
    },
    CARD_ITEM: {
      INPUT: {
        RENAME: "flashcardFormCardsItem-renameInput",
      },
      ICON_BUTTON: {
        MORE: "flashcardFormCardsItem-moreIconButton",
      },
      BUTTON: {
        RENAME: "flashcardFormCardsItemMenu-renameButton",
        EDIT: "flashcardFormCardsItemMenu-editButton",
        DELETE: "flashcardFormCardsItemMenu-deleteButton",
        RENAME_SAVE: "flashcardFormCardsItem-renameSaveButton",
      },
    },
  },
};
