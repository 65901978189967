import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const AnnotateIcon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M2 13.7587C5.53058 8.81113 18.0347 -1.55002 19.1828 1.57769C20.4517 5.03443 6.50391 14.5747 8.8979 18.2246C10.8235 21.1604 18.6037 9.86476 20.5429 12.6196C22.482 15.3745 15.0807 21.878 17.4371 22.9051C19.2045 23.6753 20.2353 19.4546 22 21.1604"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
