import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ReplyV1Icon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M4 4C4 4 6.22222 3.5 12.3889 3.5C14.6123 3.5 16.323 3.565 17.6015 3.64813C19.4684 3.7695 20.7778 5.32917 20.7778 7.2V13M20.7778 13L23 11M20.7778 13L18.5556 11"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 20C20 20 17.5 20.5 11.6111 20.5C9.46459 20.5 7.76833 20.4336 6.47928 20.3491C4.57663 20.2245 3.22222 18.6275 3.22222 16.7208L3.22222 11M3.22222 11L1 13M3.22222 11L5.44444 13"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
