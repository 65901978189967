import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ClipboardIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M7 2.20001C7 2.30001 7 2.40001 7 2.50001V3.30001C7 4.00001 7.5 4.60001 8.2 4.70001C9 4.80001 10.3 4.90001 12 4.90001C13.7 4.90001 15 4.80001 15.8 4.70001C16.5 4.60001 17 4.00001 17 3.30001V2.60001C17 2.50001 17 2.40001 17 2.30001C17.1368 2.30001 17.3746 2.38608 17.5 2.40001C19.3 2.60001 20.5 4.00001 20.7 5.70001C20.9 7.30001 21 9.50001 21 12.6C21 15.7 20.9 17.9 20.7 19.5C20.5 21.1 19.3 22.5 17.5 22.7C16.2 22.9 14.4 23 12 23C9.7 23 7.8 22.9 6.5 22.7C4.7 22.5 3.5 21.1 3.3 19.4C3.1 17.8 3 15.5 3 12.5C3 9.50001 3.1 7.20001 3.3 5.60001C3.5 3.90001 4.7 2.50001 6.4 2.30001C6.6 2.30001 6.9 2.30001 7 2.20001Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M7 2.6C7 1.9 7.5 1.3 8.2 1.2C9 1.1 10.3 1 12 1C13.7 1 15 1.1 15.8 1.2C16.5 1.3 17 1.9 17 2.6V3.4C17 4.1 16.5 4.7 15.8 4.8C15 4.9 13.7 5 12 5C10.3 5 9 4.9 8.2 4.8C7.5 4.7 7 4.1 7 3.4V2.6Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M7 18H12"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeMiterlimit="10"
    />
    <path
      d="M7 10H17"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeMiterlimit="10"
    />
    <path
      d="M7 14H17"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeMiterlimit="10"
    />
  </>
);
