import React from 'react';

export const YoutubeIcon = () => (
  <>
    <path
      d="M23.4704 6.23214C23.3327 5.7234 23.0641 5.25959 22.6915 4.88692C22.3188 4.51424 21.855 4.2457 21.3462 4.10804C19.4838 3.6001 11.9879 3.6001 11.9879 3.6001C11.9879 3.6001 4.49191 3.61549 2.62947 4.12343C2.12072 4.26109 1.65692 4.52964 1.28424 4.90231C0.911568 5.27499 0.643027 5.73879 0.505362 6.24754C-0.0579885 9.55684 -0.276556 14.5993 0.520754 17.7762C0.658419 18.285 0.926961 18.7488 1.29964 19.1214C1.67231 19.4941 2.13612 19.7627 2.64486 19.9003C4.5073 20.4083 12.0033 20.4083 12.0033 20.4083C12.0033 20.4083 19.4992 20.4083 21.3616 19.9003C21.8704 19.7627 22.3342 19.4941 22.7069 19.1214C23.0795 18.7488 23.3481 18.285 23.4857 17.7762C24.0799 14.4623 24.2631 9.42292 23.4704 6.23214Z"
      fill="#FF0000"
    />
    <path
      d="M9.60205 15.6058L15.8205 12.0041L9.60205 8.40234V15.6058Z"
      fill="white"
    />
  </>
);
