import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const BoldIcon = ({ stroke }: VerseIconSVGProps) => (
  <>
    <path
      d="M14.6023 11.7527C15.3912 11.874 16.0162 12.2017 16.4774 12.7357C16.9507 13.2576 17.1873 13.9797 17.1873 14.9021C17.1873 16.0793 16.799 16.9774 16.0223 17.5963C15.2455 18.2031 14.2625 18.5066 13.0731 18.5066H7.68457V5.76337H12.7272C13.8802 5.76337 14.8026 6.0425 15.4943 6.60078C16.1982 7.14691 16.5502 7.93578 16.5502 8.96737C16.5502 9.65914 16.3803 10.2417 16.0405 10.715C15.7128 11.1883 15.2334 11.5221 14.6023 11.7163V11.7527ZM10.0148 7.9115V10.9699H12.2721C12.8668 10.9699 13.3219 10.8364 13.6375 10.5694C13.953 10.3024 14.1108 9.92007 14.1108 9.42248C14.1108 8.93703 13.953 8.56687 13.6375 8.312C13.3219 8.045 12.8971 7.9115 12.3631 7.9115H10.0148ZM12.6726 16.3584C13.3037 16.3584 13.8013 16.2128 14.1654 15.9215C14.5295 15.6181 14.7115 15.1994 14.7115 14.6654C14.7115 14.1314 14.5295 13.7188 14.1654 13.4275C13.8013 13.1241 13.2734 12.9724 12.5816 12.9724H10.0148V16.3584H12.6726Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
  </>
);
