/* eslint-disable @typescript-eslint/ban-ts-comment */
import { WebsocketProvider } from "y-websocket";
import { TDBinding, TDShape } from "@tldraw/tldraw";
import * as Y from "yjs";

export const useTLDrawStore = (roomId: string) => {
  // Create the doc
  const doc = new Y.Doc();
  const wssHost: string = process.env.REACT_APP_TLDRAW_WSS ?? "";
  // Create a websocket provider
  const provider = new WebsocketProvider(wssHost, roomId, doc, {
    connect: true,
  });

  // Export the provider's awareness API
  const awareness = provider.awareness;

  const yShapes: Y.Map<TDShape> = doc.getMap("shapes");
  const yBindings: Y.Map<TDBinding> = doc.getMap("bindings");

  // Create an undo manager for the shapes and binding maps
  const undoManager = new Y.UndoManager([yShapes, yBindings]);

  return { awareness, provider, doc, undoManager, yShapes, yBindings };
};
