export const FEEDBACK_REMOTE_CONFIG = {
  FEEDBACK_FORM_SPREADSHEET_ID: "FEEDBACK_FORM_SPREADSHEET_ID",
  MIXPANEL_PROJECT_ID: "MIXPANEL_PROJECT_ID",
};

export enum FeedbackTypeEnum {
  DURING_SESSION = "During session feedback",
  AFTER_SESSION = "After session feedback",
  DASHBOARD = "Dashboard feedback",
  PRICING = "Pricing question",
}
