import React from 'react';
import styled from 'styled-components';

import { AvatarContainerProps, AvatarStarIconContainerProps } from './typings';

export const AvatarContainer = styled.div<AvatarContainerProps>`
  position: relative;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ?? theme.colors.main.PrimaryDark90};

  ${({ width }) => `width: ${Number.isInteger(width) ? `${width}px` : width};`}
  ${({ height }) =>
    `height: ${Number.isInteger(height) ? `${height}px` : height};`}

  ${({ imgURL }) =>
    imgURL
      ? `
    background: url('${imgURL}');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    `
      : ''};

  ${({ borderRadius = 8 }) =>
    `border-radius: ${
      Number.isInteger(borderRadius) ? `${borderRadius}px` : borderRadius
    };`}

  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}
  
  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    ${({ borderRadius = 8 }) =>
      `border-radius: ${
        Number.isInteger(borderRadius) ? `${borderRadius}px` : borderRadius
      };`}
    border: ${({ border, theme }) =>
      border ? `${border}` : `1px solid ${theme.colors.main.PrimaryDark20}`};
  }
`;
export const AdaptiveTextSizer = styled.div<{
  fontSize?: number;
  color?: string;
}>`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ theme, color }) => color ?? theme.colors.other.White};
  span,
  && p {
    text-transform: uppercase;
    font-size: ${({ fontSize }) => fontSize}px;
  }
`;
export const StarIconAbsoluteContainer = styled.div<AvatarStarIconContainerProps>`
  position: absolute;
  bottom: 1px;
  ${({ placement }) => {
    switch (placement) {
      case 'left-end':
        return `left: 0; transform: translateX(-25%);`;
      case 'right-end':
      default:
        return `right: 0; transform: translateX(25%);`;
    }
  }}
`;

export const SmallImageAbsoluteContainer = styled.div<AvatarStarIconContainerProps>`
  position: absolute;
  bottom: -8px;
  ${({ placement }) => {
    switch (placement) {
      case 'left-end':
        return `left: -8px;`;
      case 'right-end':
      default:
        return `right: -8px;`;
    }
  }}
`;
