import { ButterBoardParamsEnum } from "src/butter-const";
import React from "react";

export const useParams = () => {
  const params = new URLSearchParams(window.location.search);
  const id = params.get(ButterBoardParamsEnum.ID);
  const readOnly = Boolean(
    params.get(ButterBoardParamsEnum.READ_ONLY) === "true"
  );
  const enableTemplates = Boolean(
    params.get(ButterBoardParamsEnum.ENABLE_TEMPLATES) === "true"
  );
  const enableListener = Boolean(
    params.get(ButterBoardParamsEnum.ENABLE_LISTENER) === "true"
  );
  const showDefaultToolbar = Boolean(
    params.get(ButterBoardParamsEnum.SHOW_DEFAULT_TOOLBAR) === "true"
  );
  const hideToolbar = Boolean(
    params.get(ButterBoardParamsEnum.HIDE_TOOLBAR) === "true"
  );

  return {
    id,
    readOnly,
    enableTemplates,
    enableListener,
    showDefaultToolbar,
    hideToolbar,
  };
};
