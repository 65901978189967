import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const EmojiAddV2Icon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M14.5 1.28533C13.6969 1.09868 12.8599 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 11.1401 22.9013 10.3031 22.7147 9.5"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M8.5 9 T8.5 9, M15.5 9 T15.5 9"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth) * 1.25}
      strokeLinecap="round"
    />
    <path
      d="M17 4H23"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 7L20 1"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 20C15.735 20 18.8721 17.4405 19.7531 13.9798C20.0257 12.9093 19.1046 12 18 12H6C4.89543 12 3.97435 12.9093 4.24685 13.9798C5.12788 17.4405 8.26504 20 12 20Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M11.9761 19.8999C13.3824 19.8999 14.7039 19.5371 15.8521 18.8999L13.7807 18.0983C12.6196 17.6489 11.3326 17.6489 10.1715 18.0983L8.1001 18.8999C9.24835 19.5371 10.5699 19.8999 11.9761 19.8999Z"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
