export const S3_FILES_URL = "https://files.butter.us";

export const S3_ANIMATION_JSON_URL = `${S3_FILES_URL}/lottieAnimationJsonV2`;
export enum ReactionVariationsEnum {
  THUMBS_UP = "thumbsUp",
  THUMBS_DOWN = "thumbsDown",
  BRAIN = "brain",
  PRAISE = "praise",
  LAUGH = "laugh",
  THINKING = "thinking",
  HUG = "hug",
  LOVE = "love",
  CONFETTI = "confetti",
  ON_FIRE = "onFire",
}

export const REACTION_TIMESTAMP_TIMEOUT_MS = 10000;

export const REACTION_LOTTIE_JSON_V2_URL = [
  { id: "thumbsUp", src: "/agree_tileV2.json", type: "json" },
  { id: "thumbsDown", src: "/disagree_tileV2.json", type: "json" },
  { id: "brain", src: "/bigBrain_tileV2.json", type: "json" },
  { id: "praise", src: "/excellent_tileV2.json", type: "json" },
  { id: "laugh", src: "/hahaha_tileV2.json", type: "json" },
  { id: "thinking", src: "/hmm_tileV2.json", type: "json" },
  { id: "hug", src: "/hug_tileV2.json", type: "json" },
  { id: "love", src: "/loveIt_tileV2.json", type: "json" },
  { id: "onFire", src: "/onFire_tileV2.json", type: "json" },
  { id: "confetti", src: "/woohoo_tileV2.json", type: "json" },
].map((obj) => ({ ...obj, src: `${S3_ANIMATION_JSON_URL}${obj.src}` }));

export enum BigReactionLevelEnum {
  LEVEL_1 = 1,
  LEVEL_2 = 2,
  LEVEL_3 = 3,
}

export const BIG_EMOJI_MIN_COUNT = {
  [BigReactionLevelEnum.LEVEL_1]: 3,
  [BigReactionLevelEnum.LEVEL_2]: 5,
  [BigReactionLevelEnum.LEVEL_3]: 7,
};

export const BIG_EMOJI_MIN_REACTORS_COUNT = {
  [BigReactionLevelEnum.LEVEL_1]: 1,
  [BigReactionLevelEnum.LEVEL_2]: 5,
  [BigReactionLevelEnum.LEVEL_3]: 7,
};

export const SOCKET_NEW_REACTIONS_EVENT_V1 = "newReaction";
export const SOCKET_SEND_REACTIONS_EVENT_V2 = "sendReactionV2";
