import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const PlaceholderIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M9.17157 2.82843C10.7337 1.26633 13.2663 1.26633 14.8284 2.82843L21.1716 9.17157C22.7337 10.7337 22.7337 13.2663 21.1716 14.8284L14.8284 21.1716C13.2663 22.7337 10.7337 22.7337 9.17157 21.1716L2.82843 14.8284C1.26633 13.2663 1.26633 10.7337 2.82843 9.17157L9.17157 2.82843Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
