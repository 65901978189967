import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const QnAIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      fill={Array.isArray(fill) ? fill[0] : fill}
      d="M8.2999 15.7C7.0999 15.7 5.9999 15.4 4.9999 14.9C4.6999 14.8 4.3999 14.7 4.1999 14.8L2.0999 15.5C1.8999 15.6 1.6999 15.4 1.6999 15.1L2.1999 12.7C2.1999 12.6 2.1999 12.4 2.0999 12.3C1.3999 11.2 0.899902 9.8 0.899902 8.3C0.899902 4.2 4.1999 1 8.1999 1C12.1999 1 15.4999 4.2 15.4999 8.2C11.5999 8.2 8.2999 11.5 8.2999 15.7ZM8.2999 15.7C8.2999 15.6 8.2999 15.6 8.2999 15.7Z"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
      strokeLinecap="round"
    />
    <path
      d="M21.7998 19.9C21.7998 19.8 21.7998 19.6 21.8998 19.5C22.5998 18.4 23.0998 17 23.0998 15.5C23.0998 11.4 19.7998 8.20001 15.7998 8.20001C11.7998 8.20001 8.2998 11.5 8.2998 15.6C8.2998 19.7 11.5998 22.9 15.5998 22.9C16.7998 22.9 17.8998 22.6 18.8998 22.1C19.1998 22 19.4998 21.9 19.6998 22L21.7998 22.7C21.9998 22.8 22.1998 22.6 22.1998 22.3L21.7998 19.9Z"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <path
      d="M13.5 13.4444C13.5 13.4444 13.5 11.2778 15.7222 11.2778C17.5556 11.2778 17.9444 12.8333 17.9444 13.4444C17.9444 14.6667 17.1667 15.0555 16.7222 15.4444C16.2222 15.8889 15.7222 16.1667 15.7222 17"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M8.3 15.7 T8.3 15.7"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
      strokeLinecap="round"
    />
    <path
      d="M8 4V8.2"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M8 10.35 T8 10.35"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
      strokeLinecap="round"
    />
    <path
      d="M15.8 18.95 T15.8 18.95"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
      strokeLinecap="round"
    />
  </>
);
