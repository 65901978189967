import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ArrowDownV1Icon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M3.25 13.25L12 22L20.75 13.25"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 22L12 2"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
