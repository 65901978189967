import React from 'react';
import styled from 'styled-components';

import {
  VerseBody3,
  VerseDelayState,
  VerseDelayUnmount,
  VerseDelayUnmountEnum,
} from '..';
import { VerseKeyframes } from '../VerseKeyframes';
import { VerseBadgeComponentProps, VerseBadgeContainerProps } from './typings';

export function VerseBadge({
  invisible,
  children,
  offsetX,
  offsetY,
  content,
  max,
  textColor,
  width,
  ...props
}: VerseBadgeComponentProps) {
  return (
    <OuterContainer width={width}>
      {children}

      <VerseDelayUnmount isMounted={!invisible}>
        {({ state }) => (
          <Badge state={state} offsetX={offsetX} offsetY={offsetY} {...props}>
            <BadgeText textColor={textColor}>
              {max ? Math.min(content, max) : content}
            </BadgeText>
          </Badge>
        )}
      </VerseDelayUnmount>
    </OuterContainer>
  );
}
const OuterContainer = styled.div<{ width?: string | number }>`
  position: relative;

  width: ${({ width }) =>
    width ? (typeof width === 'string' ? width : `${width}px`) : 'fit-content'};
`;

const fadeInAnimation = VerseKeyframes.fadeInKeyframes();
const fadeOutAnimation = VerseKeyframes.fadeOutKeyframes();
interface BadgeProps extends VerseBadgeContainerProps {
  state: VerseDelayState;
}
const Badge = styled.div<BadgeProps>`
  position: absolute;
  ${({ size = 16, offsetY = 0, offsetX = 0 }) => `
    width: auto;
    padding: 0 6px;
    height: ${size}px;
    border-radius: ${size / 2}px;
    top: ${-(size / 2) + offsetY}px;
    right: ${-(size / 2) + offsetX}px;
  `}

  animation: ${({ state }) => {
    switch (state) {
      case VerseDelayUnmountEnum.MOUNTED:
      case VerseDelayUnmountEnum.MOUNTING: {
        return fadeInAnimation;
      }
      default: {
        return fadeOutAnimation;
      }
    }
  }}
  ${({ theme }) => theme.animationSpeed}ms 1;

  background-color: ${({ color, theme }) =>
    color ?? theme.colors.other.Danger100};
  box-shadow: ${({ theme }) => theme.shadows.primaryDark07};

  display: flex;
  justify-content: center;
  align-items: center;
`;
const BadgeText = styled(VerseBody3)<{ textColor?: string }>`
  && {
    font-size: 10px;
    line-height: 10px;
    color: ${({ textColor, theme }) => textColor ?? theme.colors.other.White};
  }
`;
