import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const HandUpIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M12.1814 23.5C16.2315 23.5 19.5147 20.2168 19.5147 16.1667V13.875L4.7564 13.875L4.7563 16.3431C4.7563 20.6765 8.13131 23.5 12.1814 23.5Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M4.85784 11.3158C5.06314 10.4557 5.68084 9.70173 6.56451 9.66883C6.60259 9.66741 6.64154 9.66667 6.68136 9.66667C7.82526 9.66667 8.51469 10.8012 8.51469 11.9451V12.8882C8.51469 14.0321 7.82526 15.1667 6.68136 15.1667C5.45159 15.1667 4.67993 14.0393 4.67993 12.8095C4.67993 12.2444 4.75739 11.7366 4.85784 11.3158Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M8.51465 11.0388C8.51465 9.89203 9.20123 8.75 10.348 8.75C11.4947 8.75 12.1813 9.89202 12.1813 11.0388V12.8779C12.1813 14.0246 11.4947 15.1667 10.348 15.1667C9.20123 15.1667 8.51465 14.0246 8.51465 12.8779V11.0388Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M12.1814 10.1302C12.1814 8.9812 12.8658 7.83333 14.0147 7.83333C15.0849 7.83333 15.697 8.89959 15.7246 9.96937L15.7994 12.8705C15.8288 14.0137 15.1584 15.1667 14.0147 15.1667C12.8658 15.1667 12.1814 14.0188 12.1814 12.8698V10.1302Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M15.6862 2.60449C15.6727 1.57329 16.1917 0.5 17.223 0.5C18.2766 0.5 18.8736 1.54256 18.9838 2.59041C19.0916 3.61512 19.2237 5.12815 19.3313 7.14583C19.4943 10.2014 19.5169 12.9931 19.5173 14.2692C19.5174 14.7191 19.2619 15.1313 18.8321 15.2644C18.5037 15.3661 18.0925 15.4583 17.6813 15.4583C17.2693 15.4583 16.8574 15.3658 16.5286 15.2638C16.0998 15.1309 15.8443 14.7181 15.8384 14.2692L15.6862 2.60449Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M9.84052 14.2328C9.77517 14.7059 9.87007 15.0639 9.97597 15.2984C10.0799 15.5285 10.3106 15.6562 10.5562 15.7149L13.5514 16.4312C14.0873 16.5593 14.5548 16.8853 14.8604 17.3437C15.1105 17.7188 15.605 17.8419 16.0018 17.6279L19.02 16L19.02 15.52L16.7457 13.9522C16.1547 13.5447 15.4803 13.2741 14.7716 13.16L11.3697 12.6121C10.8664 12.5311 10.3545 12.7465 10.1476 13.2124C10.0166 13.5076 9.89132 13.865 9.84052 14.2328Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      strokeWidth={strokeWidth}
    />
    <path
      d="M15.4617 18.0276L15.1049 17.4659C14.778 16.9514 14.252 16.5958 13.6527 16.4844L11.8249 16.1445C10.9762 15.9866 10.193 15.4198 10.2318 14.5575C10.2357 14.4709 10.2435 14.3816 10.2562 14.2902C10.2637 14.2357 10.2728 14.1814 10.2832 14.1275C10.4846 13.0878 11.6091 12.6263 12.6581 12.7712L15.2428 13.1282C15.923 13.2222 16.5752 13.46 17.1562 13.8258L19.4267 15.2555"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
      strokeWidth={strokeWidth}
    />
  </>
);
