export enum ButterBoardEventsEnum {
  GET_CONTENT = "butterboardGetContent",
  CONTENT_DATA = "butterboardContentData",
  ENABLE_BOARD = "enableButterboard",
  INSERT_CONTENT = "butterboardInsertContent",
  INSERTED_CONTENT = "butterboardInsertedContent",
  CLICKED_TEMPLATES_BUTTON = "clickedTemplatesButton",
  REPLACE_CONTENT = 'butterboardReplaceContent'
}

export enum ButterBoardParamsEnum {
  SHOW_DEFAULT_TOOLBAR = "showDefaultToolbar",
  ENABLE_TEMPLATES = "enableTemplates",
  ENABLE_LISTENER = "enableListener",
  READ_ONLY = "readOnly",
  HIDE_TOOLBAR = "hideToolbar",
  ID = "id",
}

export enum ButterBoardErrorEnum {
  EMPTY_BOARD = "emptyBoard",
}
