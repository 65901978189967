import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const GIFIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M1.37539 4.68872C1.58307 2.91566 2.91566 1.58307 4.68872 1.37539C6.34923 1.18088 8.76351 1 12 1C15.2365 1 17.6508 1.18088 19.3113 1.37539C21.0843 1.58307 22.4169 2.91566 22.6246 4.68872C22.8191 6.34923 23 8.76351 23 12C23 15.2365 22.8191 17.6508 22.6246 19.3113C22.4169 21.0843 21.0843 22.4169 19.3113 22.6246C17.6508 22.8191 15.2365 23 12 23C8.76351 23 6.34923 22.8191 4.68872 22.6246C2.91566 22.4169 1.58307 21.0843 1.37539 19.3113C1.18088 17.6508 1 15.2365 1 12C1 8.76351 1.18088 6.34923 1.37539 4.68872Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M8.59995 11.78H11.92V12.2C11.92 12.8267 11.7866 13.4133 11.52 13.96C11.26 14.5067 10.8633 14.95 10.33 15.29C9.80329 15.63 9.15995 15.8 8.39995 15.8C7.69995 15.8 7.06662 15.65 6.49995 15.35C5.93995 15.05 5.49995 14.6267 5.17995 14.08C4.85995 13.5267 4.69995 12.89 4.69995 12.17C4.69995 11.4567 4.85328 10.8233 5.15995 10.27C5.47328 9.71667 5.90995 9.28333 6.46995 8.97C7.03662 8.65667 7.68328 8.5 8.40995 8.5C8.96995 8.5 9.47329 8.58667 9.91995 8.76C10.3733 8.93333 10.7666 9.16 11.1 9.44L10.39 10.31C10.1433 10.09 9.84662 9.91 9.49995 9.77C9.15329 9.63 8.77328 9.56 8.35995 9.56C7.85995 9.56 7.41995 9.67333 7.03995 9.9C6.65995 10.1267 6.36328 10.4367 6.14995 10.83C5.94328 11.2233 5.83995 11.6667 5.83995 12.16C5.83995 12.66 5.94662 13.1067 6.15995 13.5C6.37995 13.8867 6.68328 14.19 7.06995 14.41C7.46328 14.63 7.91662 14.74 8.42995 14.74C8.90995 14.74 9.32329 14.6433 9.66995 14.45C10.0233 14.2567 10.2933 14.01 10.48 13.71C10.6666 13.4033 10.7733 13.0867 10.8 12.76H8.59995V11.78Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M12.8722 8.65H13.9322V15.65H12.8722V8.65Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M19.3832 8.65V9.63H16.3732V11.6H19.1532V12.58H16.3732V15.65H15.3332V8.65H19.3832Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
  </>
);
