import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const OrderedListIcon = ({ stroke }: VerseIconSVGProps) => (
  <>
    <path
      d="M20.9631 6.00003L8.96313 6.00003"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
    />
    <path
      d="M20.9631 12L8.96313 12"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
    />
    <path
      d="M20.9631 18L8.96313 18"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
    />
    <path
      d="M3.84033 5.05688H4.89691V10.1511H4.15731V5.79649H3.84033V5.05688Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M2.99683 19.2075V18.6641L4.75527 16.6717C5.00181 16.3949 5.17287 16.166 5.26847 15.9849C5.3691 15.8038 5.41941 15.6277 5.41941 15.4566C5.41941 15.2252 5.34645 15.0415 5.20055 14.9057C5.05967 14.7648 4.86596 14.6944 4.61943 14.6944C4.3578 14.6944 4.149 14.7698 3.99303 14.9208C3.83706 15.0667 3.75656 15.2704 3.75152 15.5321H3.01192C3.02198 15.0591 3.17292 14.6868 3.46474 14.4151C3.76159 14.1384 4.15655 14 4.64962 14C5.12759 14 5.50243 14.1258 5.77412 14.3774C6.05084 14.6289 6.1892 14.9761 6.1892 15.4189C6.1892 15.6805 6.12631 15.9371 6.00053 16.1887C5.87977 16.4402 5.67097 16.7295 5.37413 17.0566L4.07605 18.498H6.18165V19.2075H2.99683Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
  </>
);
