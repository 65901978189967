import { TrashIcon } from "@radix-ui/react-icons";
import * as React from "react";
import { useButterTldrawApp } from "src/utils/useButterTldrawApp";
import {
  useVerseTheme,
  VerseIcon,
  VerseIconIdEnum,
  VerseTooltip,
} from "src/verse/src";
import { StyledToolButton } from "../StyledToolButton";

export function ToolPanelDeleteButton() {
  const app = useButterTldrawApp();
  const theme = useVerseTheme();
  const handleDelete = React.useCallback(() => {
    app?.delete();
  }, [app]);

  const hasSelection = app?.useStore(
    (s) =>
      s.appState.status === "idle" &&
      s.document.pageStates[s.appState.currentPageId].selectedIds.length > 0
  );

  return (
    <VerseTooltip title="Delete" placement="right">
      <StyledToolButton
        hoverColor={theme.colors.other.Danger25}
        disabled={!hasSelection}
        onClick={handleDelete}
      >
        <VerseIcon fill="transparent" iconId={VerseIconIdEnum.TRASH} />
      </StyledToolButton>
    </VerseTooltip>
  );
}
