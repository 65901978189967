import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ClipIcon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M4.5 10.3C4.5 10.3 4.5 11.5 4.5 15.6C4.5 16.8716 4.54809 18.0181 4.61445 18.9948C4.75781 21.1052 6.39553 22.7306 8.50565 22.8773C9.52131 22.9479 10.7085 23 12 23C13.273 23 14.4447 22.9494 15.4506 22.8803C17.5821 22.734 19.1995 21.0788 19.3234 18.946C19.4197 17.2891 19.5 15.006 19.5 12C19.5 8.8122 19.4097 6.43737 19.3058 4.76032C19.1827 2.77349 17.7421 1.18787 15.7557 1.05737C15.2213 1.02225 14.6329 1 14 1C13.1704 1 12.402 1.03823 11.7244 1.09356C9.97395 1.23646 8.71186 2.69475 8.63407 4.44928C8.56474 6.01301 8.5 8.07019 8.5 10.3C8.5 13.2253 8.61142 15.7048 8.69846 17.1955C8.75054 18.0875 9.40426 18.8228 10.2896 18.9441C10.8979 19.0274 11.5888 19.1 12.15 19.1C12.6373 19.1 13.154 19.0453 13.6116 18.9763C14.4507 18.8499 15.0452 18.1351 15.0881 17.2876C15.1417 16.2267 15.2 14.6205 15.2 12.65C15.2 9 15.1 6.6 15.1 6.6"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </>
);
