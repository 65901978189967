import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ChatIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M21.1078 18.4473C21.0789 18.3127 21.1073 18.1724 21.1833 18.0576C22.3315 16.3204 23 14.2383 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C13.8668 23 15.625 22.535 17.165 21.7144C17.4197 21.5787 17.7184 21.545 17.9922 21.6363L21.3221 22.7463C21.6586 22.8584 21.9895 22.5622 21.9152 22.2154L21.1078 18.4473Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M6.5 10H17.5M6.5 14H11.9167"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
