import React from 'react';
import styled from 'styled-components';

import { VerseBody1, VerseIcon, VerseIconIdEnum } from '..';
import { useVerseTheme } from '../..';
import { VerseKeyframes } from '../VerseKeyframes/VerseKeyframes';
import { MenuButtonContainerProps, VerseMenuButtonComponentProps } from './typings';

export function VerseMenuButton({
  children,
  onClick,
  allowDefault,
  testId,
  width = 'auto',
  height = 40,
  borderRadius = 8,
  bgColor,
  hoverBgColor,
  highlightBgColor,
  highlightHoverBgColor,
  padding = '0 12px',
  loading,
  loadingText = 'Loading...',
  ...other
}: VerseMenuButtonComponentProps) {
  const theme = useVerseTheme();
  const defaultPreventedOnClick = React.useCallback(
    (e: any) => {
      if (onClick) {
        if (!allowDefault) {
          // prevent default behaviour of button that might cause page reload
          e.preventDefault();
        }
        onClick(e);
      }
    },
    [onClick]
  );

  return (
    <StyledMenuButton
      onClick={defaultPreventedOnClick}
      width={width}
      height={height}
      borderRadius={borderRadius}
      bgColor={bgColor ?? theme.colors.other.White}
      hoverBgColor={hoverBgColor ?? theme.colors.other.Sand05}
      highlightBgColor={highlightBgColor ?? theme.colors.main.PrimaryYellow100}
      highlightHoverBgColor={
        highlightHoverBgColor ?? theme.colors.main.PrimaryYellow50
      }
      padding={padding}
      data-testid={testId}
      loading={loading}
      {...other}
    >
      {loading ? (
        <>
          <VerseIcon iconId={VerseIconIdEnum.REFRESH} size={20} mr={1} />
          <VerseBody1>{loadingText}</VerseBody1>
        </>
      ) : (
        children
      )}
    </StyledMenuButton>
  );
}
const spinAnimation = VerseKeyframes.spinKeyframes({
  fromDeg: 0,
  toDeg: 360,
});
export const StyledMenuButton = styled.div<MenuButtonContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}
  padding: ${({ padding }) => padding};

  ${({ width }) => `width: ${Number.isInteger(width) ? `${width}px` : width};`}
  ${({ height }) =>
    `height: ${Number.isInteger(height) ? `${height}px` : height};`}
    
  ${({ borderRadius }) =>
    `border-radius: ${
      Number.isInteger(borderRadius) ? `${borderRadius}px` : borderRadius
    };`}
  background-color: ${({ highlighted, highlightBgColor, bgColor }) =>
    highlighted ? highlightBgColor : bgColor};

  ${({ disabled, loading }) => {
    if (disabled || loading) return `opacity: 0.2; pointer-events:none;`;
  }}

  transition: all ${({ theme }) => theme.animationSpeed}ms linear;
  :hover {
    background-color: ${({
      highlighted,
      highlightHoverBgColor,
      hoverBgColor,
    }) => (highlighted ? highlightHoverBgColor : hoverBgColor)};
  }

  svg {
    animation: ${({ loading }) => (loading ? spinAnimation : null)} 1s infinite;
  }
`;
