import { keyframes } from 'styled-components';

export interface SlideKeyframesProps {
  from: { x: number | string; y: number | string };
  to: { x: number | string; y: number | string };
}

export const slideKeyframes = ({ from, to }: SlideKeyframesProps) => keyframes`
  from {
    transform: translate(
        ${
          from.x
            ? typeof from.x === 'string'
              ? `${from.x}`
              : `${from.x}px`
            : '0px'
        }
       , ${
         from.y
           ? typeof from.y === 'string'
             ? `${from.y}`
             : `${from.y}px`
           : '0px'
       });
  }

  to {
    transform: translate(
        ${to.x ? (typeof to.x === 'string' ? `${to.x}` : `${to.x}px`) : '0px'}
       , ${
         to.y ? (typeof to.y === 'string' ? `${to.y}` : `${to.y}px`) : '0px'
       });
  }
`;
