import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const WifiIcon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M12 19 T12 19"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth) * 2}
      strokeLinecap="round"
    />
    <path
      d="M1.48071 8.71364C4.11058 6.08377 7.90928 4.33052 12.0002 4.33052C16.0911 4.33052 19.8898 5.93766 22.5197 8.71364"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M19.159 12.2201C17.4058 10.4669 14.922 9.29807 12.146 9.29807C9.37007 9.29807 6.8863 10.4669 5.13306 12.2201"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M8.20142 15.4344C9.22414 14.4117 10.5391 13.8273 12.0001 13.8273C13.4612 13.8273 14.7761 14.4117 15.7988 15.4344"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
