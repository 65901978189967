import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const LoaderIcon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M 23 12 C 23 18.0751 18.0751 23 12 23 C 5.9249 23 1 18.0751 1 12 C 1 5.9249 5.9249 1 12 1"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
