import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const RecordV3Icon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <circle
      cx="12"
      cy="12"
      r="11"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M7.51005 15.5L5.47005 12.59H4.84005V15.5H3.80005V8.49998H6.19005C6.60338 8.49998 6.97005 8.58331 7.29005 8.74998C7.61672 8.91664 7.87005 9.15664 8.05005 9.46998C8.23672 9.77664 8.33005 10.14 8.33005 10.56C8.33005 11.0866 8.18005 11.5266 7.88005 11.88C7.58672 12.2333 7.19338 12.4566 6.70005 12.55L8.82005 15.5H7.51005ZM4.84005 9.47998V11.61H6.02005C6.42005 11.61 6.71672 11.5166 6.91005 11.33C7.11005 11.1366 7.21005 10.88 7.21005 10.56C7.21005 10.2333 7.11005 9.97331 6.91005 9.77998C6.71672 9.57998 6.42005 9.47998 6.02005 9.47998H4.84005Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M10.6604 12.12V14.52H13.6704V15.5H9.62036V8.49998H13.6204V9.47998H10.6604V11.14H13.3904V12.12H10.6604Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M18.118 15.65C17.3847 15.65 16.7347 15.4966 16.168 15.19C15.6014 14.8833 15.158 14.4566 14.838 13.91C14.5247 13.3566 14.368 12.72 14.368 12C14.368 11.28 14.5247 10.6466 14.838 10.1C15.158 9.54664 15.6014 9.11664 16.168 8.80998C16.7347 8.50331 17.3847 8.34998 18.118 8.34998C19.0514 8.34998 19.8747 8.60664 20.588 9.11998L19.948 10.03C19.3814 9.61664 18.758 9.40998 18.078 9.40998C17.5914 9.40998 17.1514 9.51664 16.758 9.72998C16.3714 9.94331 16.0647 10.2466 15.838 10.64C15.618 11.0333 15.508 11.4866 15.508 12C15.508 12.5133 15.618 12.9666 15.838 13.36C16.0647 13.7533 16.3714 14.0566 16.758 14.27C17.1514 14.4833 17.5914 14.59 18.078 14.59C18.758 14.59 19.3814 14.3833 19.948 13.97L20.588 14.88C19.8747 15.3933 19.0514 15.65 18.118 15.65Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
  </>
);
