import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const UserIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M1.19971 20.5C1.25332 19.8193 1.31364 19.2166 1.37533 18.6898C1.58297 16.9167 2.91573 15.5831 4.68879 15.3754C6.3493 15.1809 8.76359 15 12.0001 15C15.2366 15 17.6508 15.1809 19.3114 15.3754C21.0844 15.5831 22.4172 16.9167 22.6248 18.6898C22.6865 19.2166 22.7468 19.8193 22.8004 20.5"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <circle
      cx="12"
      cy="6.75"
      r="5.75"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
