import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ProductHuntIcon = ({ stroke }: VerseIconSVGProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3998 14.1H10.425V17.25H8.32499V6.75H13.3998C15.4296 6.75 17.0748 8.39522 17.0748 10.425C17.0748 12.4548 15.4296 14.1 13.3998 14.1ZM12 1.5C6.20072 1.5 1.5 6.20072 1.5 12C1.5 17.7993 6.20072 22.5 12 22.5C17.7986 22.5 22.5 17.7993 22.5 12C22.5 6.20072 17.7986 1.5 12 1.5ZM13.3998 8.84999H10.425V12H13.3998C14.27 12 14.9748 11.2945 14.9748 10.425C14.9748 9.55547 14.27 8.84999 13.3998 8.84999Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
  </>
);
