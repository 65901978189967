import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const NotionIcon = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.12124 4.88804C5.80212 5.44122 6.05755 5.39902 7.33614 5.3137L19.3899 4.58993C19.6456 4.58993 19.433 4.33489 19.3477 4.29252L17.3459 2.84532C16.9623 2.54751 16.4512 2.20647 15.4718 2.29179L3.80009 3.14308C3.37442 3.18528 3.28941 3.39812 3.45892 3.5687L5.12124 4.88804ZM5.84493 7.69717V20.3798C5.84493 21.0614 6.18554 21.3164 6.95216 21.2742L20.1993 20.5077C20.9663 20.4655 21.0517 19.9967 21.0517 19.443V6.84549C21.0517 6.29269 20.8391 5.99458 20.3695 6.03713L6.52619 6.84549C6.0153 6.88842 5.84488 7.14398 5.84488 7.69717H5.84493ZM18.9224 8.37748C19.0074 8.7609 18.9224 9.14398 18.5383 9.18709L17.9 9.31426V18.6774C17.3459 18.9752 16.8348 19.1455 16.409 19.1455C15.7272 19.1455 15.5564 18.9326 15.0458 18.2945L10.8707 11.7403V18.0817L12.1919 18.3798C12.1919 18.3798 12.1919 19.1455 11.126 19.1455L8.18757 19.316C8.10221 19.1455 8.18757 18.7203 8.48563 18.6351L9.25243 18.4226V10.038L8.18778 9.95268C8.10238 9.56926 8.31504 9.01642 8.91182 8.97352L12.0641 8.76103L16.409 15.4006V9.52705L15.3012 9.39992C15.2162 8.93119 15.5564 8.59083 15.9825 8.54863L18.9224 8.37748ZM2.82011 1.99403L14.9605 1.09997C16.4514 0.972105 16.835 1.05776 17.772 1.73843L21.6474 4.46224C22.2869 4.93063 22.5 5.05815 22.5 5.56874V20.5077C22.5 21.444 22.1589 21.9977 20.9665 22.0824L6.8678 22.9338C5.97266 22.9764 5.54665 22.8489 5.07787 22.2527L2.22399 18.5499C1.71266 17.8683 1.5 17.3584 1.5 16.7618V3.48309C1.5 2.71745 1.84117 2.07878 2.82011 1.99403Z"
      fill="#0A0B1E"
    />
  </>
);
