import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const SpeakerIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M3.3086 4.67341C3.47797 2.9119 4.73781 1.53359 6.49343 1.31134C7.82355 1.14295 9.64596 1 12 1C14.354 1 16.1765 1.14295 17.5066 1.31134C19.2622 1.53359 20.522 2.9119 20.6914 4.67341C20.8511 6.33438 21 8.75355 21 12C21 15.2464 20.8511 17.6656 20.6914 19.3266C20.522 21.0881 19.2622 22.4664 17.5066 22.6887C16.1765 22.8571 14.354 23 12 23C9.64596 23 7.82354 22.8571 6.49343 22.6887C4.73781 22.4664 3.47797 21.0881 3.3086 19.3266C3.14891 17.6656 3 15.2464 3 12C3 8.75355 3.14891 6.33438 3.3086 4.67341Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <circle
      cx="12"
      cy="14.5"
      r="6"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <circle
      cx="16.5"
      cy="5.5"
      r="2"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
