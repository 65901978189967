import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const HandsIllustration = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => {
  return (
    <>
      <path
        d="M12.9772 13.6417C12.5316 14.1229 12.7825 14.8433 13.5426 16.0448C14.3027 17.2463 14.0735 17.2314 14.8791 19.0224C15.7016 20.8507 16.7296 22 19.2483 22C21.3558 22 22.171 20.0672 22.5381 18.7612C22.8465 17.6642 23.0007 13.5895 23.0007 12.7015C23.0007 11.8134 22.8979 10.6642 22.0755 10.6642C21.582 10.6642 21.3044 11.3433 21.253 11.6567C21.1502 10.8209 21.4587 9.09699 20.3278 9.09699C19.7932 9.09699 19.6596 9.46266 19.5054 10.1418C19.5054 9.61937 19.4025 8.20892 18.3745 8.20892C17.8399 8.20892 17.6377 9.25371 17.6035 9.67161C17.5007 9.20146 16.4337 8.10445 15.9072 10.2985C15.6645 11.3097 15.8558 15.9403 15.7016 15.8358C15.4877 15.6909 13.7997 12.7537 12.9772 13.6417Z"
        fill={Array.isArray(fill) ? fill[0] : fill}
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M17.6045 9.67169V14.4777"
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M19.5059 10.1418V14.5299"
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M21.2529 11.3955V14.8433"
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M11.0238 13.6417C11.4694 14.1229 11.2184 14.8433 10.4584 16.0448C9.69827 17.2463 9.92747 17.2314 9.12186 19.0224C8.29941 20.8507 7.27136 22 4.75264 22C2.64513 22 1.83002 20.0672 1.46286 18.7612C1.15445 17.6642 1.00024 13.5895 1.00024 12.7015C1.00024 11.8134 1.10305 10.6642 1.92549 10.6642C2.41895 10.6642 2.69653 11.3433 2.74794 11.6567C2.85074 10.8209 2.54233 9.09699 3.67317 9.09699C4.20776 9.09699 4.34142 9.46266 4.49562 10.1418C4.49562 9.61937 4.59843 8.20892 5.62647 8.20892C6.16106 8.20892 6.36325 9.25371 6.39752 9.67161C6.50032 9.20146 7.56724 8.10445 8.0938 10.2985C8.33649 11.3097 8.14519 15.9403 8.29941 15.8358C8.51326 15.6909 10.2013 12.7537 11.0238 13.6417Z"
        fill={Array.isArray(fill) ? fill[0] : fill}
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M6.39746 9.67169V14.4777"
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M4.49609 10.1418V14.5299"
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M2.74902 11.3955V14.8433"
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <rect
        width="3.91223"
        height="1.03645"
        rx="0.518224"
        transform="matrix(-0.701378 0.71279 0.701378 0.71279 18.4131 3.00916)"
        fill={Array.isArray(fill) ? fill[0] : fill}
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={strokeWidth}
      />
      <rect
        width="3.93203"
        height="1.03118"
        rx="0.515588"
        transform="matrix(-0.425186 0.905106 0.899699 0.436511 14.7715 1.02374)"
        fill={Array.isArray(fill) ? fill[0] : fill}
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={strokeWidth}
      />
      <rect
        width="3.91223"
        height="1.03645"
        rx="0.518224"
        transform="matrix(0.701378 0.71279 -0.701378 0.71279 6.1123 3.00916)"
        fill={Array.isArray(fill) ? fill[0] : fill}
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={strokeWidth}
      />
      <rect
        width="3.93568"
        height="1.0302"
        rx="0.515099"
        transform="matrix(0.352012 0.935996 -0.932152 0.362067 10.0713 1)"
        fill={Array.isArray(fill) ? fill[0] : fill}
        stroke={Array.isArray(stroke) ? stroke[0] : stroke}
        strokeWidth={strokeWidth}
      />
    </>
  );
};
