import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ArrowRightV2Icon = ({
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M2.60864 12.0174L21.3913 12.0174M21.3913 12.0174L17.3913 7.8M21.3913 12.0174L17.3913 16.2348"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
