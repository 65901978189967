import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const FlashcardIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M0.981995 9.46045C0.87921 7.91822 1.87502 6.62821 3.36939 6.23333C4.51165 5.93149 6.08688 5.56837 8.14156 5.20608C10.1962 4.84378 11.8007 4.64624 12.9773 4.5392C14.5166 4.39916 15.8935 5.27078 16.3244 6.75517C16.5772 7.62584 16.8528 8.75577 17.1024 10.1713C17.352 11.5868 17.4794 12.7429 17.5397 13.6475C17.6425 15.1897 16.6467 16.4797 15.1523 16.8746C14.0101 17.1764 12.4348 17.5396 10.3802 17.9019C8.32549 18.2642 6.72106 18.4617 5.54445 18.5687C4.00515 18.7088 2.62819 17.8371 2.1973 16.3528C1.94456 15.4821 1.66895 14.3522 1.41935 12.9366C1.16976 11.5211 1.04228 10.3651 0.981995 9.46045Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M4.44294 5.77987C4.60952 4.24322 5.81421 3.14573 7.35444 3.01634C8.53176 2.91744 10.1461 2.83337 12.2325 2.83337C14.3189 2.83337 15.9332 2.91744 17.1106 3.01634C18.6508 3.14573 19.8555 4.24322 20.0221 5.77987C20.1198 6.68121 20.195 7.84183 20.195 9.27919C20.195 10.7166 20.1198 11.8772 20.0221 12.7785C19.8555 14.3152 18.6508 15.4127 17.1106 15.542C15.9332 15.6409 14.3189 15.725 12.2325 15.725C10.1461 15.725 8.53176 15.6409 7.35444 15.542C5.81421 15.4127 4.60952 14.3152 4.44294 12.7785C4.34523 11.8772 4.27002 10.7166 4.27002 9.27919C4.27002 7.84183 4.34523 6.6812 4.44294 5.77987Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M7.51543 10.281C7.81531 8.7647 9.11107 7.77638 10.6567 7.78172C11.8382 7.78581 13.4537 7.84276 15.5322 8.0246C17.6106 8.20644 19.2115 8.43089 20.3757 8.63202C21.8988 8.89516 23.0032 10.0935 23.0353 11.6388C23.0541 12.5452 23.0278 13.708 22.9025 15.1399C22.7773 16.5718 22.6012 17.7214 22.4253 18.6108C22.1254 20.1271 20.8297 21.1154 19.284 21.1101C18.1026 21.106 16.487 21.049 14.4086 20.8672C12.3301 20.6853 10.7293 20.4609 9.56503 20.2598C8.04193 19.9966 6.93748 18.7983 6.90546 17.253C6.88668 16.3466 6.91291 15.1838 7.03819 13.7519C7.16346 12.32 7.33954 11.1704 7.51543 10.281Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
