import React from 'react';

export const SmileEmoji = () => {
  return (
    <>
      <defs>
        <clipPath id="__lottie_element_2">
          <rect width="500" height="500" x="0" y="0"></rect>
        </clipPath>
      </defs>
      <g clipPath="url(#__lottie_element_2)">
        <g transform="matrix(1,0,0,1,49.75,49.75)" opacity="1">
          <path
            strokeLinecap="butt"
            strokeLinejoin="miter"
            fillOpacity="0"
            strokeMiterlimit="4"
            stroke="rgb(10,11,30)"
            strokeOpacity="1"
            strokeWidth="18"
            d=" M400.25,200.25 C400.25,310.71 310.71,400.25 200.25,400.25 C89.79,400.25 0.25,310.71 0.25,200.25 C0.25,89.79 89.79,0.25 200.25,0.25 C310.71,0.25 400.25,89.79 400.25,200.25z"
          ></path>
          <g opacity="1" transform="matrix(1,0,0,1,200.25,200.25)">
            <path
              fill="rgb(255,253,99)"
              fillOpacity="1"
              d=" M200,0 C200,110.45700073242188 110.45700073242188,200 0,200 C-110.45700073242188,200 -200,110.45700073242188 -200,0 C-200,-110.45700073242188 -110.45700073242188,-200 0,-200 C110.45700073242188,-200 200,-110.45700073242188 200,0z"
            ></path>
          </g>
        </g>
        <g
          transform="matrix(1.093500018119812,0,0,1.093500018119812,267.7689514160156,187.52195739746094)"
          opacity="1"
        >
          <path
            strokeLinecap="butt"
            strokeLinejoin="miter"
            fillOpacity="0"
            strokeMiterlimit="4"
            stroke="rgb(10,11,30)"
            strokeOpacity="1"
            strokeWidth="18"
            d=" M80.03,37.62 C78.11,16.12 59.12,0.25 37.62,2.17 C16.12,4.09 0.25,23.08 2.17,44.58 C4.1,66.08 23.08,81.95 44.58,80.03 C66.08,78.11 81.95,59.12 80.03,37.62z"
          ></path>
          <g
            opacity="1"
            transform="matrix(1,0,0,1,41.10200119018555,41.10200119018555)"
          >
            <path
              fill="rgb(255,255,255)"
              fillOpacity="1"
              d=" M38.93000030517578,-3.4809999465942383 C37.007999420166016,-24.981000900268555 18.020000457763672,-40.85200119018555 -3.4800000190734863,-38.93000030517578 C-24.979999542236328,-37.007999420166016 -40.85100173950195,-18.020000457763672 -38.928001403808594,3.4800000190734863 C-37.00699996948242,24.979999542236328 -18.020000457763672,40.85200119018555 3.4800000190734863,38.92900085449219 C24.981000900268555,37.00699996948242 40.85200119018555,18.018999099731445 38.93000030517578,-3.4809999465942383z"
            ></path>
          </g>
        </g>
        <g
          transform="matrix(1.0807100534439087,0,0,1.0951000452041626,144.48773193359375,187.20619201660156)"
          opacity="1"
        >
          <path
            strokeLinecap="butt"
            strokeLinejoin="miter"
            fillOpacity="0"
            strokeMiterlimit="4"
            stroke="rgb(10,11,30)"
            strokeOpacity="1"
            strokeWidth="18"
            d=" M2.17,37.62 C4.09,16.12 23.08,0.25 44.58,2.17 C66.08,4.09 81.95,23.08 80.03,44.58 C78.11,66.08 59.12,81.95 37.62,80.03 C16.12,78.11 0.25,59.12 2.17,37.62z"
          ></path>
          <g
            opacity="1"
            transform="matrix(1,0,0,1,41.10100173950195,41.10200119018555)"
          >
            <path
              fill="rgb(255,255,255)"
              fillOpacity="1"
              d=" M-38.92900085449219,-3.4809999465942383 C-37.00699996948242,-24.981000900268555 -18.020000457763672,-40.85200119018555 3.4800000190734863,-38.93000030517578 C24.979999542236328,-37.007999420166016 40.85200119018555,-18.020000457763672 38.93000030517578,3.4800000190734863 C37.007999420166016,24.979999542236328 18.020000457763672,40.85200119018555 -3.4800000190734863,38.92900085449219 C-24.979999542236328,37.00699996948242 -40.85100173950195,18.018999099731445 -38.92900085449219,-3.4809999465942383z"
            ></path>
          </g>
        </g>
        <g transform="matrix(1,0,0,1,250,270)" opacity="1">
          <g opacity="1" transform="matrix(1,0,0,1,0,0)">
            <path
              fill="rgb(255,253,99)"
              fillOpacity="1"
              d=" M-91.5,-113 C-89.5,-119 -67.5,-141.5 -47,-127"
            ></path>
            <path
              strokeLinecap="round"
              strokeLinejoin="miter"
              fillOpacity="0"
              strokeMiterlimit="4"
              stroke="rgb(10,11,30)"
              strokeOpacity="1"
              strokeWidth="11"
              d=" M-91.5,-113 C-89.5,-119 -67.5,-141.5 -47,-127"
            ></path>
          </g>
        </g>
        <g
          transform="matrix(1,0,0,1,252.6300048828125,268.60400390625)"
          opacity="1"
        >
          <g opacity="1" transform="matrix(1,0,0,1,0,0)">
            <path
              fill="rgb(255,253,99)"
              fillOpacity="1"
              d=" M101,-111.5 C89.25,-128.75 71.5,-129.5 57.5,-126"
            ></path>
            <path
              strokeLinecap="round"
              strokeLinejoin="miter"
              fillOpacity="0"
              strokeMiterlimit="4"
              stroke="rgb(10,11,30)"
              strokeOpacity="1"
              strokeWidth="11"
              d=" M101,-111.5 C89.25,-128.75 71.5,-129.5 57.5,-126"
            ></path>
          </g>
        </g>
        <g transform="matrix(1,0,0,1,250,263.5)" opacity="1">
          <g opacity="1" transform="matrix(1,0,0,1,0,0)">
            <path
              strokeLinecap="round"
              strokeLinejoin="miter"
              fillOpacity="0"
              strokeMiterlimit="4"
              stroke="rgb(10,11,30)"
              strokeOpacity="1"
              strokeWidth="11"
              d=" M-63,90.5 C-34.5,135 31.5,139.5 64.5,89.5"
            ></path>
          </g>
        </g>
      </g>
    </>
  );
};
