import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ArrowLeftV2Icon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M21.3912 12.0174L2.60861 12.0174M2.60861 12.0174L6.60861 7.8M2.60861 12.0174L6.60861 16.2348"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
