import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const BanIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <circle
      cx="12"
      cy="12"
      r="11"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M5 20L19 4"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
