import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { VerseIconIdEnum } from '..';
import { useVerseTheme } from '../..';
import { VerseIcon } from '../VerseIcon/VerseIcon';
import { VerseKeyframes } from '../VerseKeyframes/VerseKeyframes';
import {
  VerseIconButtonSizeEnum,
  VerseIconButtonVariantEnum,
} from './consts/iconButtonConsts';
import {
  VerseIconButtonComponentProps,
  VerseIconButtonContainerProps,
} from './typings';

export const VerseIconButton = forwardRef<
  HTMLButtonElement,
  VerseIconButtonComponentProps
>(
  (
    {
      variant = VerseIconButtonVariantEnum.PRIMARY_CTA,
      size = VerseIconButtonSizeEnum.SMALL,
      icon,
      loading,
      disabled,
      testId,
      onClick,
      allowDefault,
      ...containerProps
    }: VerseIconButtonComponentProps,
    ref,
  ) => {
    const theme = useVerseTheme();
    const defaultIconStroke = React.useMemo(() => {
      switch (variant) {
        case VerseIconButtonVariantEnum.PRIMARY_CTA:
          return theme.colors.other.White;
        default:
          return theme.colors.main.PrimaryDark100;
      }
    }, [variant]);

    const defaultIconProps = React.useMemo(() => {
      return {
        size: size === VerseIconButtonSizeEnum.XSMALL ? 10 : 16,
        fill: 'transparent',
        stroke: defaultIconStroke,
      };
    }, [defaultIconStroke]);

    const defaultPreventedOnClick = React.useCallback(
      (e: any) => {
        if (onClick) {
          if (!allowDefault) {
            // prevent default behaviour of button that might cause page reload
            e.preventDefault();
          }
          onClick(e);
        }
      },
      [onClick]
    );

    return (
      <StyledIconButtonContainer
        size={size}
        variant={variant}
        disabled={disabled || loading}
        loading={loading}
        onClick={defaultPreventedOnClick}
        {...containerProps}
        data-testid={testId}
        ref={ref}
      >
        {loading ? (
          <VerseIcon
            {...defaultIconProps}
            {...icon}
            iconId={VerseIconIdEnum.REFRESH}
          />
        ) : (
          <VerseIcon {...defaultIconProps} {...icon} />
        )}
      </StyledIconButtonContainer>
    );
  },
);

const NormalizedButton = styled.button`
  // fix for Firefox & safari
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;

  // fix for IE & Edge
  overflow: visible;
  text-transform: none;

  // fix for iOS & Safari
  -webkit-appearance: button;
`;

const spinAnimation = VerseKeyframes.spinKeyframes({ fromDeg: 0, toDeg: 360 });
const StyledIconButtonContainer = styled(
  NormalizedButton,
)<VerseIconButtonContainerProps>`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.main.PrimaryDark100};
  transition: all ${({ theme }) => theme.animationSpeed}ms linear;
  color: ${({ theme }) => theme.colors.main.PrimaryDark100};

  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}
  ${({ size, theme }) => {
    switch (size) {
      case VerseIconButtonSizeEnum.XSMALL:
        return `
                height: 20px;
                padding: 0 ${theme.spacing(0.5)}px;
                border-radius: 6px;
            `;
      case VerseIconButtonSizeEnum.SMALL:
        return `
                height: 32px;
                padding: 0 ${theme.spacing(1)}px;
                border-radius: 10px;
            `;
      case VerseIconButtonSizeEnum.MEDIUM:
        return `
                height: 40px;
                padding: 0 ${theme.spacing(1.5)}px;
                border-radius: 12px;
            `;
      case VerseIconButtonSizeEnum.LARGE:
        return `
                height: 48px;
                padding: 0 ${theme.spacing(2)}px;
                border-radius: 16px;
            `;
      default:
        return ``;
    }
  }}

  ${({ variant, theme }) => {
    switch (variant) {
      case VerseIconButtonVariantEnum.PRIMARY_CTA:
        return `
                background-color: ${theme.colors.main.PrimaryDark100};
                color: ${theme.colors.other.White};
                :active { background-color: ${theme.colors.main.PrimaryDark90}; }
            `;
      case VerseIconButtonVariantEnum.SECONDARY_CTA:
        return `
                background-color: ${theme.colors.main.PrimaryDark07};
                border-color: ${theme.colors.main.PrimaryDark07};
                :hover { border-color: ${theme.colors.main.PrimaryDark20}; }
                :active { background-color: ${theme.colors.main.PrimaryDark10}; }
            `;
      case VerseIconButtonVariantEnum.TERTIARY_CTA:
        return `
                background-color: ${theme.colors.other.White};
                border-color: ${theme.colors.main.PrimaryDark20};
                :hover {  background-color: ${theme.colors.main.PrimaryDark03}; }
                :active {  background-color: ${theme.colors.main.PrimaryDark07}; }
            `;
      case VerseIconButtonVariantEnum.BRANDED_CTA:
        return `
                background-color: ${theme.colors.main.PrimaryYellow100};
                border-color: ${theme.colors.main.PrimaryDark15RGBA};
                :hover { border-color: ${theme.colors.main.PrimaryDark30RGBA};}
                :active { background-color: ${theme.colors.main.PrimaryYellowButtonHover}; }
            `;
      case VerseIconButtonVariantEnum.TEXT_ICON_LINK:
        return `
                background-color: transparent;
                border-color: transparent;
                :hover { background-color: ${theme.colors.main.PrimaryDark05RGBA};}
                :active { background-color: ${theme.colors.main.PrimaryDark07RGBA}; }
            `;
      default:
        return ``;
    }
  }}

  ${({ disabled }) => {
    if (disabled) return `opacity: 0.15; pointer-events:none;`;
  }}

  :active {
    transform: unset;
    box-shadow: unset;
  }

  svg {
    animation: ${({ loading }) => (loading ? spinAnimation : null)} 1s infinite;
  }
`;
