import * as React from "react";
import { TDSnapshot } from "@tldraw/tldraw";

import { useButterTldrawApp } from "src/utils/useButterTldrawApp";
import { VerseBody1 } from "src/verse/src";
import styled from "styled-components";

const shapesSelector = (s: TDSnapshot) => s.document.pages?.page?.shapes ?? {};

export const ButterboardEmptyState = React.memo(function PrimaryTools() {
  const app = useButterTldrawApp();

  const shapes = app?.useStore(shapesSelector) ?? {};
  const hasShapes = Object.keys(shapes).length > 0;

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  if (hasShapes) {
    return null;
  }

  if (isLoading) {
    return (
      <StyledLoader>
        <VerseBody1>Loading...</VerseBody1>
      </StyledLoader>
    );
  }
  return (
    <StyledLoader>
      <VerseBody1>Your board is empty</VerseBody1>
    </StyledLoader>
  );
});

const StyledLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;
