import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { ButtonIcon } from './ButtonIcon';
import { ButtonShortcutIndicator } from './ButtonShortcutIndicator';
import { ButtonText } from './ButtonText';
import {
  VerseButtonIconPositionEnum,
  VerseButtonSizeEnum,
  VerseButtonVariantEnum,
} from './consts/buttonConsts';
import {
  VerseButtonComponentProps,
  VerseButtonContainerProps,
} from './typings';

export const VerseButton = forwardRef<
  HTMLButtonElement,
  VerseButtonComponentProps
>(
  (
    {
      variant = VerseButtonVariantEnum.PRIMARY_CTA,
      size = VerseButtonSizeEnum.SMALL,
      title,
      icon,
      disabled,
      loading,
      loadingText = 'Loading...',
      testId,
      html,
      onClick,
      allowDefault,
      shortcutKeyInfo,
      ...containerProps
    }: VerseButtonComponentProps,
    ref,
  ) => {
    const defaultPreventedOnClick = React.useCallback(
      (e: any) => {
        if (onClick) {
          if (!allowDefault) {
            // prevent default behaviour of button that might cause page reload
            e.preventDefault();
          }
          onClick(e);
        }
      },
      [onClick]
    );

    return (
      <StyledButtonContainer
        size={size}
        variant={variant}
        icon={icon}
        disabled={disabled || loading}
        onClick={defaultPreventedOnClick}
        {...containerProps}
        data-testid={testId}
        ref={ref}
      >
        {shortcutKeyInfo && (
          <ButtonShortcutIndicator
            {...shortcutKeyInfo}
            onTrigger={defaultPreventedOnClick}
          />
        )}

        {icon ? (
          <ButtonIcon icon={icon} buttonSize={size} buttonVariant={variant} />
        ) : null}
        <ButtonText
          title={loading ? loadingText : title}
          buttonSize={size}
          html={html}
        />
      </StyledButtonContainer>
    );
  },
);

export const NormalizedButton = styled.button`
  // fix for Firefox & safari
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;

  // fix for IE & Edge
  overflow: visible;
  text-transform: none;

  // fix for iOS & Safari
  -webkit-appearance: button;
`;

const StyledButtonContainer = styled(
  NormalizedButton,
)<VerseButtonContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'center'};

  flex-direction: ${({ icon }) => {
    const iconPosition = icon?.position ?? VerseButtonIconPositionEnum.LEFT;
    switch (iconPosition) {
      case VerseButtonIconPositionEnum.LEFT:
        return 'row';
      case VerseButtonIconPositionEnum.RIGHT:
        return 'row-reverse';
      default:
        return 'row';
    }
  }};

  border: 1px solid ${({ theme }) => theme.colors.main.PrimaryDark100};
  transition: all ${({ theme }) => theme.animationSpeed}ms linear;
  color: ${({ theme }) => theme.colors.main.PrimaryDark100};

  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}

  ${({ size, theme }) => {
    switch (size) {
      case VerseButtonSizeEnum.XSMALL:
        return `
                height: 24px;
                padding: 0 ${theme.spacing(1)}px;
                border-radius: 8px;
            `;
      case VerseButtonSizeEnum.SMALL:
        return `
                height: 32px;
                padding: 0 ${theme.spacing(1.5)}px;
                border-radius: 10px;
            `;
      case VerseButtonSizeEnum.MEDIUM:
        return `
                height: 40px;
                padding: 0 ${theme.spacing(2)}px;
                border-radius: 12px;
            `;
      case VerseButtonSizeEnum.LARGE:
        return `
                height: 48px;
                padding: 0 ${theme.spacing(2.5)}px;
                border-radius: 16px;
            `;
      case VerseButtonSizeEnum.XLARGE:
        return `
                height: 60px;
                padding: 0 ${theme.spacing(2.5)}px;
                border-radius: 20px;
            `;
      default:
        return ``;
    }
  }}


  ${({ width }) =>
    width && `width: ${Number.isInteger(width) ? `${width}px` : width};`}
  ${({ minWidth = 'max-content' }) =>
    minWidth
      ? `min-width: ${Number.isInteger(minWidth) ? `${minWidth}px` : minWidth};`
      : ''}
  ${({ maxWidth }) =>
    maxWidth
      ? `min-width: ${Number.isInteger(maxWidth) ? `${maxWidth}px` : maxWidth};`
      : ''}

  ${({ height }) =>
    height && `height: ${Number.isInteger(height) ? `${height}px` : height};`}

  ${({ variant, theme }) => {
    switch (variant) {
      case VerseButtonVariantEnum.PRIMARY_CTA:
        return `
                background-color: ${theme.colors.main.PrimaryDark100};
                color: ${theme.colors.other.White};
                :active { background-color: ${theme.colors.main.PrimaryDark90}; }
            `;
      case VerseButtonVariantEnum.SECONDARY_CTA:
        return `
                background-color: ${theme.colors.main.PrimaryDark07};
                border-color: ${theme.colors.main.PrimaryDark07};
                :hover { border-color: ${theme.colors.main.PrimaryDark20}; }
                :active { background-color: ${theme.colors.main.PrimaryDark10}; }
            `;
      case VerseButtonVariantEnum.TERTIARY_CTA:
        return `
                background-color: ${theme.colors.other.White};
                border-color: ${theme.colors.main.PrimaryDark20};
                :hover {  background-color: ${theme.colors.main.PrimaryDark03}; }
                :active {  background-color: ${theme.colors.main.PrimaryDark07}; }
            `;
      case VerseButtonVariantEnum.BRANDED_CTA:
        return `
                background-color: ${theme.colors.main.PrimaryYellow100};
                border-color: ${theme.colors.main.PrimaryDark15RGBA};
                :hover { border-color: ${theme.colors.main.PrimaryDark30RGBA};}
                :active { background-color: ${theme.colors.main.PrimaryYellowButtonHover}; }
            `;
      case VerseButtonVariantEnum.TEXT_ICON_LINK:
        return `
                background-color: transparent;
                border-color: transparent;
                :hover { background-color: ${theme.colors.main.PrimaryDark05RGBA};}
                :active { background-color: ${theme.colors.main.PrimaryDark07RGBA}; }
            `;
      default:
        return ``;
    }
  }}

  ${({ disabled }) => {
    if (disabled) return `opacity: 0.15; pointer-events: none;`;
  }}

  :active {
    transform: unset;
    box-shadow: unset;
  }
`;
export const TextWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
