import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ShareScreenIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M3.53846 17.8582C3.43613 17.8477 3.33844 17.8373 3.24539 17.8272C2.37793 17.7328 1.67052 17.1342 1.49781 16.2788C1.26456 15.1236 1 13.2358 1 10.6154C1 8.90749 1.11238 7.5108 1.25523 6.42532C1.48963 4.6442 2.94208 3.40846 4.73273 3.26378C6.41758 3.12766 8.84001 3 12 3C15.16 3 17.5824 3.12766 19.2673 3.26378C21.0579 3.40846 22.5104 4.6442 22.7448 6.42532C22.8876 7.5108 23 8.90749 23 10.6154C23 13.2358 22.7354 15.1236 22.5022 16.2788C22.3295 17.1342 21.6221 17.7328 20.7546 17.8272C20.6616 17.8373 20.5639 17.8477 20.4615 17.8582"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9999 20.7693C14.5531 20.7693 16.4215 20.6323 17.7428 20.4686C18.974 20.316 19.2606 18.9624 18.2919 18.1875L13.0113 13.963C12.42 13.49 11.5798 13.49 10.9885 13.963L5.70789 18.1875C4.73917 18.9624 5.02577 20.316 6.25691 20.4686C7.57826 20.6323 9.44666 20.7693 11.9999 20.7693Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
