import * as React from "react";
import {
  CircleIcon,
  LineHeightIcon,
  SquareIcon,
  TriangleUpIcon,
  VercelLogoIcon,
} from "@radix-ui/react-icons";
import { TDShapeType, TDSnapshot } from "@tldraw/tldraw";
import { StyledToolButton } from "./StyledToolButton";
import { useButterTldrawApp } from "../../../utils/useButterTldrawApp";
import {
  useVerseTheme,
  VerseBasePopover,
  VerseBody1,
  VerseIcon,
  VerseIconIdEnum,
  VerseMenuButton,
  VerseTooltip,
} from "src/verse/src";
import styled from "styled-components";
import { useHover } from "src/verse/src/utils";

const activeToolSelector = (s: TDSnapshot) => s.appState.activeTool;

const LineIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.64645 11.3536C3.45118 11.1583 3.45118 10.8417 3.64645 10.6465L11.1464 3.14645C11.3417 2.95118 11.6583 2.95118 11.8536 3.14645C12.0488 3.34171 12.0488 3.65829 11.8536 3.85355L4.35355 11.3536C4.15829 11.5488 3.84171 11.5488 3.64645 11.3536Z" />
  </svg>
);

export const ToolPanelShapeButton = React.memo(function PrimaryTools() {
  const app = useButterTldrawApp();
  const theme = useVerseTheme();
  const activeTool = app?.useStore(activeToolSelector);
  const previousActiveTool = React.useRef(activeTool);

  const toolIsActive = [
    TDShapeType.Ellipse,
    TDShapeType.Rectangle,
    TDShapeType.Line,
    TDShapeType.Triangle,
  ].some((substring) => activeTool?.includes(substring));

  const buttonRef = React.useRef<any>();
  const popoverTimeout = React.useRef<number | null>(null);
  const setRectangleTool = React.useCallback(() => {
    app?.selectTool(TDShapeType.Rectangle);
    previousActiveTool.current = TDShapeType.Rectangle;
  }, [app]);
  const setTriangleTool = React.useCallback(() => {
    app?.selectTool(TDShapeType.Triangle);
    previousActiveTool.current = TDShapeType.Triangle;
  }, [app]);
  const setLineTool = React.useCallback(() => {
    app?.selectTool(TDShapeType.Line);
    previousActiveTool.current = TDShapeType.Line;
  }, [app]);
  const setEllipseTool = React.useCallback(() => {
    app?.selectTool(TDShapeType.Ellipse);
    previousActiveTool.current = TDShapeType.Ellipse;
  }, [app]);
  const [open, setOpen] = React.useState(false);

  const handleOnContextMenu = (event: any) => {
    if (event) {
      if (popoverTimeout?.current) {
        clearTimeout(popoverTimeout.current);
      }

      event.preventDefault();
      setOpen(true);
    } else {
      popoverTimeout.current = window.setTimeout(() => {
        setOpen(false);
      }, theme.animationSpeed);
    }
  };
  const hoverEvents = useHover({
    onHoverEvent: handleOnContextMenu,
  });
  const handleOnContextClose = () => {
    if (popoverTimeout.current) clearTimeout(popoverTimeout.current);
    setOpen(false);
  };

  const handlePopoverHover = () => {
    if (popoverTimeout.current) clearTimeout(popoverTimeout.current);
    setOpen(true);
  };

  const renderActiveToolIcon = () => {
    switch (previousActiveTool.current) {
      case TDShapeType.Ellipse: {
        return <CircleIcon />;
      }
      case TDShapeType.Triangle: {
        return <VercelLogoIcon />;
      }
      case TDShapeType.Line: {
        return <LineIcon />;
      }
      case TDShapeType.Rectangle:
      default: {
        return <SquareIcon />;
      }
    }
  };
  const handleButtonClick = () => {
    switch (previousActiveTool.current) {
      case TDShapeType.Ellipse:
        setEllipseTool();
        break;
      case TDShapeType.Rectangle:
        setRectangleTool();
        break;

      case TDShapeType.Triangle:
        setTriangleTool();
        break;

      case TDShapeType.Line:
        setLineTool();

        break;
      default: {
        setRectangleTool();
      }
    }
  };
  return (
    <>
      <VerseTooltip
        title="Shape Tool"
        placement="right"
        enabled={!toolIsActive}
      >
        <StyledToolButton
          ref={buttonRef}
          {...hoverEvents}
          onClick={handleButtonClick}
          isActive={toolIsActive}
        >
          {renderActiveToolIcon()}
        </StyledToolButton>
      </VerseTooltip>
      <StyledTrayMenuPopover
        anchorEl={buttonRef.current}
        open={open && toolIsActive}
        onMouseEnter={handlePopoverHover}
        onClose={handleOnContextClose}
        placement="right"
        offsetX={16}
      >
        <VerseMenuButton
          onClick={setRectangleTool}
          width="100%"
          testId="butterboard-setRectangleTool"
        >
          <SquareIcon />
          <VerseBody1 ml={1}>Rectangle {`(R)`}</VerseBody1>
        </VerseMenuButton>
        <VerseMenuButton
          onClick={setEllipseTool}
          width="100%"
          testId="butterboard-setEllipseTool"
        >
          <CircleIcon />
          <VerseBody1 ml={1}>Ellipse {`(O)`}</VerseBody1>
        </VerseMenuButton>
        <VerseMenuButton
          onClick={setTriangleTool}
          width="100%"
          testId="butterboard-setTriangleTool"
        >
          <VercelLogoIcon />
          <VerseBody1 ml={1}>Triangle {`(6)`}</VerseBody1>
        </VerseMenuButton>
        <VerseMenuButton
          onClick={setLineTool}
          width="100%"
          testId="butterboard-setLineTool"
        >
          <LineIcon />
          <VerseBody1 ml={1}>Line {`(L)`}</VerseBody1>
        </VerseMenuButton>
      </StyledTrayMenuPopover>
    </>
  );
});

export const StyledTrayMenuPopover = styled(VerseBasePopover)`
  position: relative;
  border-radius: 12px;
  border-color: ${({ theme }) => theme.colors.main.PrimaryDark20};
  box-shadow: 2px 2px 7px 0px
    ${({ theme }) => theme.colors.main.PrimaryDark20RGBA};
  overflow: hidden;
`;
