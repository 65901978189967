import { useSnackbar } from 'notistack';
import { darken } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { VerseBody3 } from '..';
import { VerseIcon } from '../VerseIcon';
import { VerseIconIdEnum } from '../VerseIcon/consts';
import { VerseSnackbarVariantEnum } from './consts';
import {
  VerseSnackbarComponentProps,
  VerseSnackbarContentOuterProps,
  VerseSnackbarOuterWrapperProps,
} from './typings';

export const VerseSnackbar = React.forwardRef<
  HTMLDivElement,
  VerseSnackbarComponentProps
>((props, ref) => {
  const {
    id,
    icon,
    title,
    onClick,
    variant = VerseSnackbarVariantEnum.NEUTRAL,
    withCloseButton = true,
    clickText = 'View',
    width,
    testId,
    hidden,
  } = props;
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = React.useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const defaultTestId = React.useMemo(() => {
    switch (variant) {
      case VerseSnackbarVariantEnum.NEUTRAL:
        return 'snackbar-neutral';
      case VerseSnackbarVariantEnum.SUCCESS:
        return 'snackbar-success';
      case VerseSnackbarVariantEnum.WARNING:
        return 'snackbar-warning';
      case VerseSnackbarVariantEnum.ERROR:
        return 'snackbar-error';
      default:
        return ``;
    }
  }, []);

  return (
    <OuterWrapper
      hidden={hidden}
      width={width}
      ref={ref}
      data-testid={testId ?? defaultTestId}
    >
      <ContentWrapper
        variant={variant}
        hasOnView={Boolean(onClick)}
        onClick={onClick}
      >
        {icon && (
          <VerseIcon iconId={icon.iconId} mr={1} size={16} fill={icon?.fill} />
        )}

        <VerseBody3>{title}</VerseBody3>

        {onClick && (
          <ViewTextContainer>
            <ViewText>{clickText}</ViewText>
          </ViewTextContainer>
        )}
      </ContentWrapper>

      {withCloseButton && (
        <FloatingCloseButtonContainer onClick={handleDismiss}>
          <VerseIcon iconId={VerseIconIdEnum.CLOSE} size={10} />
        </FloatingCloseButtonContainer>
      )}
    </OuterWrapper>
  );
});

const OuterWrapper = styled.div<VerseSnackbarOuterWrapperProps>`
  position: relative;
  ${({ width = 320 }) =>
    `width: ${Number.isInteger(width) ? `${width}px` : width};`}
`;
const ContentWrapper = styled.div<VerseSnackbarContentOuterProps>`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  min-height: 48px;
  width: 100%;

  box-shadow: ${({ theme }) => theme.shadows.primaryDark07};

  ${({ variant, theme }) => {
    switch (variant) {
      case VerseSnackbarVariantEnum.NEUTRAL:
        return `background-color: ${theme.colors.main.PrimaryDark20};`;
      case VerseSnackbarVariantEnum.SUCCESS:
        return `background-color: ${theme.colors.other.Green75};`;
      case VerseSnackbarVariantEnum.WARNING:
        return `background-color: ${theme.colors.other.Warning};`;
      case VerseSnackbarVariantEnum.ERROR:
        return `background-color: ${theme.colors.other.Danger75};`;
      default:
        return ``;
    }
  }}

  transition: all ${({ theme }) => theme.animationSpeed}ms linear;
  ${({ hasOnView, variant, theme }) => {
    if (hasOnView) {
      let hoverStyling = '';
      switch (variant) {
        case VerseSnackbarVariantEnum.NEUTRAL:
          hoverStyling = `background-color: ${darken(
            0.1,
            theme.colors.main.PrimaryDark20,
          )};`;
          break;
        case VerseSnackbarVariantEnum.SUCCESS:
          hoverStyling = `background-color: ${darken(
            0.1,
            theme.colors.other.Green75,
          )};`;
          break;
        case VerseSnackbarVariantEnum.WARNING:
          hoverStyling = `background-color: ${darken(
            0.1,
            theme.colors.other.Warning,
          )};`;
          break;
        case VerseSnackbarVariantEnum.ERROR:
          hoverStyling = `background-color: ${darken(
            0.1,
            theme.colors.other.Danger75,
          )};`;
          break;
        default:
      }

      return `
        cursor: pointer;
        :hover {
          ${hoverStyling}
        }
      `;
    }
  }}
`;
const FloatingCloseButtonContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 6px;

  border: 1px solid ${({ theme }) => theme.colors.main.PrimaryDark100};
  background-color: ${({ theme }) => theme.colors.other.White};
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -4px;
  right: -4px;

  transition: all ${({ theme }) => theme.animationSpeed}ms linear;
  :hover {
    background-color: ${({ theme }) => theme.colors.other.Sand05};
  }
`;
const ViewTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: ${({ theme }) => theme.spacing(0.5)}px;
`;
const ViewText = styled(VerseBody3)`
  opacity: 0.6;
`;
