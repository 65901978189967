import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const GoogleDocV1Icon = () => (
  <>
    <path
      d="M18.5 23H5.5C4.675 23 4 22.325 4 21.5V2.5C4 1.675 4.675 1 5.5 1H14.5L20 6.5V21.5C20 22.325 19.325 23 18.5 23Z"
      fill="#F8BF08"
    />
    <path d="M14.5 1L20 6.5H14.5V1Z" fill="#F59307" />
    <path
      d="M7 9.625V16.625H17V9.625H7ZM15.75 15.375H8.25V10.875H15.75V15.375Z"
      fill="#FDFFFF"
    />
  </>
);
