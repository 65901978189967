import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const RecordV1Icon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <circle
      cx="12"
      cy="12"
      r="11"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
