import React, { ReactNode } from 'react';
import ReactLinkify from 'react-linkify';
import styled from 'styled-components';

const componentDecorator = (
  href: string,
  text: string,
  key: number,
): ReactNode => {
  const stopPropagation: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.stopPropagation();
  };
  return (
    <StyledLink
      href={href}
      key={key}
      target="_blank"
      rel="noopener noreferrer"
      onClick={stopPropagation}
    >
      {text}
    </StyledLink>
  );
};

interface VerseLinkifyProps {
  children: ReactNode;
  customDecorator?: (
    href: string,
    text: string,
    key: number,
  ) => React.ReactNode;
}
export const VerseLinkify = ({
  children,
  customDecorator,
}: VerseLinkifyProps) => {
  return (
    <ReactLinkify componentDecorator={customDecorator ?? componentDecorator}>
      {children}
    </ReactLinkify>
  );
};

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.other.Link};
  text-decoration: none;

  :hover {
    text-decoration: underline;
    opacity: 1;
  }
`;
