import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const BlockIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M1.62647 7.85899C1.84434 9.36386 3.18711 10.2483 4.70547 10.33C6.29408 10.4155 8.69903 10.5 12 10.5C15.301 10.5 17.7059 10.4155 19.2945 10.33C20.8129 10.2483 22.1557 9.36386 22.3735 7.85899C22.4494 7.33494 22.5 6.71676 22.5 6C22.5 5.28324 22.4494 4.66506 22.3735 4.14101C22.1557 2.63614 20.8129 1.75168 19.2945 1.66997C17.7059 1.58448 15.301 1.5 12 1.5C8.69903 1.5 6.29407 1.58448 4.70546 1.66997C3.18711 1.75168 1.84434 2.63614 1.62647 4.14101C1.5506 4.66506 1.5 5.28324 1.5 6C1.5 6.71676 1.5506 7.33494 1.62647 7.85899Z"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <path
      d="M1.62647 19.859C1.84434 21.3639 3.18711 22.2483 4.70547 22.33C6.29408 22.4155 8.69903 22.5 12 22.5C15.301 22.5 17.7059 22.4155 19.2945 22.33C20.8129 22.2483 22.1557 21.3639 22.3735 19.859C22.4494 19.3349 22.5 18.7168 22.5 18C22.5 17.2832 22.4494 16.6651 22.3735 16.141C22.1557 14.6361 20.8129 13.7517 19.2945 13.67C17.7059 13.5845 15.301 13.5 12 13.5C8.69903 13.5 6.29407 13.5845 4.70546 13.67C3.18711 13.7517 1.84434 14.6361 1.62647 16.141C1.5506 16.6651 1.5 17.2832 1.5 18C1.5 18.7168 1.5506 19.3349 1.62647 19.859Z"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      fill={Array.isArray(fill) ? fill[1] : fill}
    />
  </>
);
