import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const PlusIcon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M2 12H12M12 12H22M12 12V2M12 12V22"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
