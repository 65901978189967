import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const PlayV2Icon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M1.37539 4.68872C1.58307 2.91566 2.91566 1.58307 4.68872 1.37539C6.34923 1.18088 8.76351 1 12 1C15.2365 1 17.6508 1.18088 19.3113 1.37539C21.0843 1.58307 22.4169 2.91566 22.6246 4.68872C22.8191 6.34923 23 8.76351 23 12C23 15.2365 22.8191 17.6508 22.6246 19.3113C22.4169 21.0843 21.0843 22.4169 19.3113 22.6246C17.6508 22.8191 15.2365 23 12 23C8.76351 23 6.34923 22.8191 4.68872 22.6246C2.91566 22.4169 1.58307 21.0843 1.37539 19.3113C1.18088 17.6508 1 15.2365 1 12C1 8.76351 1.18088 6.34923 1.37539 4.68872Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M7 12C7 14.473 7.16467 16.0983 7.28854 16.9711C7.35367 17.43 7.74911 17.7162 8.19237 17.5808C8.96023 17.3461 10.3785 16.7862 12.775 15.45C15.1606 14.1198 16.5345 13.1743 17.2689 12.6134C17.6941 12.2887 17.6986 11.7061 17.2797 11.3732C16.5165 10.7669 15.0952 9.76285 12.775 8.55C10.5931 7.40944 9.16531 6.82294 8.33771 6.52882C7.83323 6.34954 7.33652 6.66248 7.26597 7.19319C7.1456 8.09867 7 9.67456 7 12Z"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
