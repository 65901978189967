import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const StarIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M11.066 2.97307C11.4474 2.19795 12.5526 2.19795 12.934 2.97307L15.0809 7.33613C15.3839 7.95195 15.971 8.37885 16.6502 8.47729L21.3915 9.16443C22.2491 9.28873 22.5882 10.3453 21.9631 10.9455L18.5491 14.2229C18.0495 14.7025 17.8211 15.3991 17.9398 16.0814L18.771 20.8607C18.9184 21.7082 18.0325 22.3586 17.2681 21.964L12.9626 19.7419C12.3593 19.4304 11.642 19.4328 11.0407 19.7482L6.82329 21.9607C6.06468 22.3586 5.17695 21.721 5.31181 20.875L6.07856 16.0654C6.18595 15.3918 5.95651 14.7083 5.46443 14.2359L2.03694 10.9455C1.41179 10.3453 1.75088 9.28873 2.60851 9.16443L7.3498 8.47729C8.02903 8.37885 8.61611 7.95195 8.91913 7.33613L11.066 2.97307Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
