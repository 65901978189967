import * as React from "react";
import { ArrowTopRightIcon } from "@radix-ui/react-icons";
import { TDShapeType, TDSnapshot } from "@tldraw/tldraw";
import { StyledToolButton } from "./StyledToolButton";
import { useButterTldrawApp } from "../../../utils/useButterTldrawApp";
import { VerseTooltip } from "src/verse/src";

const activeToolSelector = (s: TDSnapshot) => s.appState.activeTool;

export const ToolPanelArrowButton = () => {
  const app = useButterTldrawApp();

  const activeTool = app?.useStore(activeToolSelector);

  const selectArrowTool = React.useCallback(() => {
    app?.selectTool(TDShapeType.Arrow);
  }, [app]);

  return (
    <VerseTooltip title="Arrow Tool (A)" placement="right">
      <StyledToolButton
        onClick={selectArrowTool}
        isActive={activeTool === TDShapeType.Arrow}
      >
        <ArrowTopRightIcon />
      </StyledToolButton>
    </VerseTooltip>
  );
};
