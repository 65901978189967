import { TldrawApp } from "@tldraw/tldraw";
import * as React from "react";
export const TldrawContext = React.createContext<TldrawApp | undefined>(
  {} as TldrawApp
);

export function useButterTldrawApp() {
  const context = React.useContext(TldrawContext);
  return context;
}
