import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const GridIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M1.37539 4.68872C1.58307 2.91566 2.91566 1.58307 4.68872 1.37539C6.34923 1.18088 8.76351 1 12 1C15.2365 1 17.6508 1.18088 19.3113 1.37539C21.0843 1.58307 22.4169 2.91566 22.6246 4.68872C22.8191 6.34923 23 8.76351 23 12C23 15.2365 22.8191 17.6508 22.6246 19.3113C22.4169 21.0843 21.0843 22.4169 19.3113 22.6246C17.6508 22.8191 15.2365 23 12 23C8.76351 23 6.34923 22.8191 4.68872 22.6246C2.91566 22.4169 1.58307 21.0843 1.37539 19.3113C1.18088 17.6508 1 15.2365 1 12C1 8.76351 1.18088 6.34923 1.37539 4.68872Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M8.5 1.5V22.5M15.5 1.5V22.5M1 15.5H23M1 8.5H23"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </>
);
