import React, { forwardRef } from 'react';
import styled from 'styled-components';
import {
  border,
  color,
  flexbox,
  grid,
  layout,
  shadow,
  typography,
  background,
  backgroundSize,
  backgroundPosition,
  backgroundRepeat,
  backgroundImage,
} from 'styled-system';
import { VerseTheme } from '../../theme';
import { VerseBoxBaseProps, VerseDivProps } from './typings';

/**
 * uses styled-system to rebuild Box
 * @ref https://styled-system.com/api
 */
export const VerseBox = forwardRef<HTMLDivElement, VerseDivProps>(
  ({ component, testId, ...props }, ref) => {
    switch (component) {
      case 'form': {
        return <StyledForm {...props} data-testid={testId} ref={ref} />;
      }
      case 'svg': {
        return <StyledSvg {...props} data-testid={testId} ref={ref} />;
      }
      case 'span': {
        return <StyledSpan {...props} data-testid={testId} ref={ref} />;
      }
      case 'input': {
        return <StyledInput {...props} data-testid={testId} ref={ref} />;
      }
      default: {
        return <StyledDiv {...props} data-testid={testId} ref={ref} />;
      }
    }
  },
);

const StyledVerseBoxBase = styled.div<VerseBoxBaseProps>`
  /**
   * minWidth 0 here to correctly truncate long texts 
   * @see https://css-tricks.com/flexbox-truncated-text/#aa-demo pont #3
   */
  min-width: 0;
  ${color}
  ${layout}
  ${grid}
  ${border}
  ${shadow}
  ${typography}
  ${flexbox}
  
  ${background}
  ${backgroundSize}
  ${backgroundPosition}
  ${backgroundRepeat}
  ${backgroundImage}
  ${({ opacity }) => (opacity ? `opacity: ${opacity};` : '')}
  box-sizing: border-box;
  ${({ color }) => (color ? `color: ${color};` : `color: inherit;`)}
  ${({ bgcolor }) =>
    bgcolor
      ? `background-color: ${bgcolor};`
      : `background-color: transparent;`}
  ${({ boxSizing }) => (boxSizing ? `box-sizing: ${boxSizing};` : '')}
  ${({ whiteSpace }) => (whiteSpace ? `white-space: ${whiteSpace};` : '')}
  ${({ textOverflow }) =>
    textOverflow ? `text-overflow: ${textOverflow};` : ''}

  ${({ zIndex }) => (zIndex ? `z-index: ${zIndex};` : '')}

  ${({ position }) => (position ? `position: ${position};` : '')}

  ${({ top }) =>
    top != null
      ? typeof top === 'string'
        ? `top: ${top};`
        : `top: ${top}px;`
      : ''}
  ${({ bottom }) =>
    bottom != null
      ? typeof bottom === 'string'
        ? `bottom: ${bottom};`
        : `bottom: ${bottom}px;`
      : ''}
  ${({ right }) =>
    right != null
      ? typeof right === 'string'
        ? `right: ${right};`
        : `right: ${right}px;`
      : ''}
  ${({ left }) =>
    left != null
      ? typeof left === 'string'
        ? `left: ${left};`
        : `left: ${left}px;`
      : ''}


  ${({ margin }) => getSpaceValue('margin', margin)}
  ${({ m }) => getSpaceValue('margin', m)}
  ${({ mr }) => getSpaceValue('margin-right', mr)}
  ${({ marginRight }) => getSpaceValue('margin-right', marginRight)}
  ${({ mt }) => getSpaceValue('margin-top', mt)}
  ${({ marginTop }) => getSpaceValue('margin-top', marginTop)}
  ${({ ml }) => getSpaceValue('margin-left', ml)}
  ${({ marginLeft }) => getSpaceValue('margin-left', marginLeft)}
  ${({ mb }) => getSpaceValue('margin-bottom', mb)}
  ${({ marginBottom }) => getSpaceValue('margin-bottom', marginBottom)}
  ${({ mx }) =>
    `${getSpaceValue('margin-left', mx)} ${getSpaceValue('margin-right', mx)}`}
  ${({ marginX }) =>
    `${getSpaceValue('margin-left', marginX)} ${getSpaceValue(
      'margin-right',
      marginX,
    )}`}
  ${({ my }) =>
    `${getSpaceValue('margin-top', my)} ${getSpaceValue('margin-bottom', my)}`}
  ${({ marginY }) =>
    `${getSpaceValue('margin-top', marginY)} ${getSpaceValue(
      'margin-bottom',
      marginY,
    )}`}

  ${({ padding }) => getSpaceValue('padding', padding)}
  ${({ p }) => getSpaceValue('padding', p)}
  ${({ pr }) => getSpaceValue('padding-right', pr)}
  ${({ paddingRight }) => getSpaceValue('padding-right', paddingRight)}
  ${({ pt }) => getSpaceValue('padding-top', pt)}
  ${({ paddingTop }) => getSpaceValue('padding-top', paddingTop)}
  ${({ pl }) => getSpaceValue('padding-left', pl)}
  ${({ paddingLeft }) => getSpaceValue('padding-left', paddingLeft)}
  ${({ pb }) => getSpaceValue('padding-bottom', pb)}
  ${({ paddingBottom }) => getSpaceValue('padding-bottom', paddingBottom)}
  ${({ px }) =>
    `${getSpaceValue('padding-left', px)} ${getSpaceValue(
      'padding-right',
      px,
    )}`}
  ${({ paddingBottom }) => getSpaceValue('padding-bottom', paddingBottom)}
  ${({ paddingX }) =>
    `${getSpaceValue('padding-left', paddingX)} ${getSpaceValue(
      'padding-right',
      paddingX,
    )}`}
  ${({ py }) =>
    `${getSpaceValue('padding-top', py)} ${getSpaceValue(
      'padding-bottom',
      py,
    )}`}
  ${({ paddingY }) =>
    `${getSpaceValue('padding-top', paddingY)} ${getSpaceValue(
      'padding-bottom',
      paddingY,
    )}`}

  ${({ transition }) => (transition ? `transition: ${transition};` : '')}

  ${({ transform }) => (transform ? `transform: ${transform};` : '')}


  ${({ pointerEvents }) =>
    pointerEvents ? `pointer-events: ${pointerEvents};` : ''}
`;

const StyledDiv = styled(StyledVerseBoxBase)<VerseDivProps>``;
const StyledForm = styled(StyledVerseBoxBase).attrs({
  as: 'form',
})``;
const StyledSvg = styled(StyledVerseBoxBase).attrs({
  as: 'svg',
})``;
const StyledSpan = styled(StyledVerseBoxBase).attrs({
  as: 'span',
})``;
const StyledInput = styled(StyledVerseBoxBase).attrs({
  as: 'input',
})``;

const getSpaceValue = (key: string, value?: string | number) => {
  return value
    ? typeof value === 'string'
      ? `${key}: ${value};`
      : `${key}: ${VerseTheme.spacing(value)}px;`
    : '';
};
