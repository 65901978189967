import React from 'react';

import { VerseTheme } from '../../../theme';
import { VerseIconSVGProps } from '../typings';

export const TemplateIconV2 = ({
  fill = VerseTheme.colors.other.White,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M17.6048 20.0409C19.4623 19.8396 20.7889 18.3833 20.9453 16.5215C21.0649 15.0984 21.1665 13.1469 21.1665 10.6249C21.1665 8.10294 21.0649 6.15151 20.9453 4.72838C20.7889 2.86655 19.4623 1.41033 17.6048 1.20896C16.5153 1.09085 15.1123 0.999939 13.3748 0.999939C11.6374 0.999939 10.2344 1.09085 9.14488 1.20896C7.28738 1.41033 5.96074 2.86655 5.80434 4.72838C5.68479 6.15151 5.58317 8.10294 5.58317 10.6249C5.58317 13.1469 5.68479 15.0984 5.80434 16.5215C5.96074 18.3833 7.28738 19.8396 9.14488 20.0409C10.2344 20.159 11.6374 20.2499 13.3748 20.2499C15.1123 20.2499 16.5153 20.159 17.6048 20.0409Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M14.8548 22.7909C16.7123 22.5895 18.0389 21.1333 18.1953 19.2714C18.3149 17.8483 18.4165 15.8969 18.4165 13.3749C18.4165 10.8529 18.3149 8.90145 18.1953 7.47832C18.0389 5.61649 16.7123 4.16027 14.8548 3.9589C13.7653 3.84079 12.3623 3.74988 10.6248 3.74988C8.88736 3.74988 7.48441 3.84079 6.39488 3.9589C4.53738 4.16027 3.21074 5.61649 3.05434 7.47831C2.93479 8.90145 2.83317 10.8529 2.83317 13.3749C2.83317 15.8969 2.93479 17.8483 3.05434 19.2714C3.21074 21.1333 4.53738 22.5895 6.39488 22.7909C7.48441 22.909 8.88736 22.9999 10.6248 22.9999C12.3623 22.9999 13.7653 22.909 14.8548 22.7909Z"
      fill={Array.isArray(fill) ? fill[1] : fill}
      stroke={Array.isArray(stroke) ? stroke[1] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M11.1487 11.6154C11.1487 11.0629 10.8184 10.533 10.3946 10.1622C9.95842 9.78053 9.35122 9.5 8.67431 9.5V10.5C9.07432 10.5 9.4543 10.6682 9.73608 10.9147C10.0302 11.1721 10.1487 11.4499 10.1487 11.6154H11.1487ZM8.67431 9.5C7.32124 9.5 6.19995 10.6213 6.19995 11.9744H7.19995C7.19995 11.1736 7.87353 10.5 8.67431 10.5V9.5ZM6.19995 11.9744C6.19995 12.6361 6.45752 13.3081 6.80536 13.9165C7.15697 14.5314 7.62495 15.1231 8.10148 15.6354C8.57873 16.1485 9.07581 16.5939 9.49536 16.9148C9.70443 17.0747 9.90193 17.2096 10.0738 17.3072C10.1594 17.3558 10.2467 17.3998 10.3315 17.4329C10.4069 17.4624 10.5213 17.5 10.6487 17.5V16.5C10.7071 16.5 10.7304 16.5152 10.6955 16.5015C10.6699 16.4915 10.6277 16.4717 10.5677 16.4376C10.4482 16.3698 10.2898 16.2635 10.1029 16.1205C9.73034 15.8356 9.2747 15.4284 8.83368 14.9543C8.39194 14.4794 7.97611 13.9494 7.67347 13.4201C7.36706 12.8842 7.19995 12.3895 7.19995 11.9744H6.19995ZM10.6487 17.5C10.776 17.5 10.8904 17.4624 10.9659 17.4329C11.0506 17.3998 11.1379 17.3558 11.2236 17.3072C11.3954 17.2096 11.5929 17.0747 11.802 16.9148C12.2215 16.5939 12.7186 16.1485 13.1959 15.6354C13.6724 15.1231 14.1404 14.5314 14.492 13.9165C14.8398 13.3081 15.0974 12.6361 15.0974 11.9744H14.0974C14.0974 12.3895 13.9303 12.8842 13.6239 13.4201C13.3212 13.9494 12.9054 14.4794 12.4637 14.9543C12.0226 15.4284 11.567 15.8356 11.1945 16.1205C11.0075 16.2635 10.8491 16.3698 10.7297 16.4376C10.6696 16.4717 10.6274 16.4915 10.6019 16.5015C10.567 16.5152 10.5902 16.5 10.6487 16.5V17.5ZM15.0974 11.9744C15.0974 10.6213 13.9761 9.5 12.623 9.5V10.5C13.4238 10.5 14.0974 11.1736 14.0974 11.9744H15.0974ZM12.623 9.5C11.9461 9.5 11.3389 9.78053 10.9028 10.1622C10.4789 10.533 10.1487 11.0629 10.1487 11.6154H11.1487C11.1487 11.4499 11.2671 11.1721 11.5613 10.9147C11.843 10.6682 12.223 10.5 12.623 10.5V9.5Z"
      fill={Array.isArray(fill) ? fill[2] : fill}
      stroke={Array.isArray(stroke) ? stroke[2] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
