import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const UnsplashIcon = ({ stroke }: VerseIconSVGProps) => (
  <>
    <path
      d="M22.5 10.7986H15.8924H15.8684V16.6373H8.10755V10.7986H1.5V22.5H22.5V10.7986Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M8.10755 1.5H15.8684V7.3627H8.10755V1.5Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
  </>
);
