import { VerseTypographyVariant } from '../typings';

export enum VerseTypographyVariantEnum {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  SUBTITLE1 = 'subtitle1',
  SUBTITLE2 = 'subtitle2',
  SUBTITLE3 = 'subtitle3',
  BODY1 = 'body1',
  BODY2 = 'body2',
  BODY3 = 'body3',
  BUTTON_LARGE = 'buttonLarge',
  BUTTON_MEDIUM = 'buttonMedium',
  BUTTON_SMALL = 'buttonSmall',
  BUTTON_XSMALL = 'buttonXSmall',
  UNDERLINE = 'underline',
  LABEL = 'label',
  TOOLTIP = 'tooltip',
  HELP = 'help',
}
export enum VerseTypographyTextDecorationEnum {
  STRIKETHROUGH = 'strikethrough',
  UNDERLINE = 'underline',
}
export enum VerseTypographyFontWeightEnum {
  LIGHT = '300',
  NORMAL = 'normal',
  MEDIUM = '500',
  BOLD = '700',
  ULTRABOLD = '900',
}

const FM_CAPITAL_HEIGHT = 0.7;
const FM_DESCENDER = 0.26;
const FM_ASCENDER = 0.9;

/* desired capital height and line-height */
export const CAPITAL_HEIGHT = 0.7;
// const LINE_HEIGHT = 1.2;
export const COMPUTED_FONT_SIZE = CAPITAL_HEIGHT / FM_CAPITAL_HEIGHT;

/* compute needed values */
const DISTANCE_BOTTOM = FM_DESCENDER;
const DISTANCE_TOP = FM_ASCENDER - FM_CAPITAL_HEIGHT;
export const V_ALIGN = (DISTANCE_BOTTOM - DISTANCE_TOP) * COMPUTED_FONT_SIZE;
export const V_CENTER_HEIGHT = 0.49;
