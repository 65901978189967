import React from 'react';

export const BasicEmoji = () => (
  <>
    <path
      d="M39.4885 64.6106C36.7865 64.6106 34.6635 65.6266 33.8594 66.8996C35.6285 67.6953 37.4942 68.1115 39.8102 68.1115C42.7463 68.1115 44.6314 67.0343 45.4393 66.6303C43.9918 65.3573 42.1905 64.6106 39.4885 64.6106Z"
      fill="#FD5656"
    />
    <circle cx="40.3189" cy="40.3189" r="29.3189" fill="#FFFD63" />
    <circle
      cx="40.3189"
      cy="40.3189"
      r="29.3189"
      fill="#FFFD63"
      stroke="#0A0B1E"
      strokeWidth="2"
    />
    <path
      d="M46.9892 53.7783C46.9892 53.7783 45.1206 56.8683 40.5801 56.9074C36.0397 56.9465 33.9944 53.8902 33.9944 53.8902"
      stroke="#0A0B1E"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle
      cx="27.5438"
      cy="36.0844"
      r="7.74886"
      fill="white"
      stroke="#0A0B1E"
      strokeWidth="2"
    />
    <circle cx="27.5436" cy="36.0847" r="3.97914" fill="#0A0B1E" />
    <circle cx="29.218" cy="33.5716" r="1.466" fill="white" />
    <circle
      cx="53.7489"
      cy="36.0844"
      r="7.74886"
      fill="white"
      stroke="#0A0B1E"
      strokeWidth="2"
    />
    <circle cx="53.7487" cy="36.0847" r="3.97914" fill="#0A0B1E" />
    <circle cx="55.423" cy="33.5716" r="1.466" fill="white" />
  </>
);
