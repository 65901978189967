import React from 'react';

import { VerseTheme } from '../theme';
import { VerseThemeType } from '../theme/typings';

interface IVerseContext {
  animationEnabled: boolean;
  setAnimationEnabled?: (value: boolean) => void;
  theme: VerseThemeType;
  setTheme?: (value: VerseThemeType) => void;
}

const initState: IVerseContext = {
  animationEnabled: true,
  setAnimationEnabled: undefined,
  theme: VerseTheme,
  setTheme: undefined,
};
export const VerseContext = React.createContext<IVerseContext>(initState);
