import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const PlayNextIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M1 12C1 7.11096 1.14776 3.80947 1.26864 1.92665C1.32064 1.11673 2.24857 0.758909 2.8309 1.32422C3.90429 2.36624 5.45631 3.93967 7 5.73913C8.75588 7.78594 10.2916 9.74919 11.1827 10.9151C11.681 11.5671 11.7135 12.4553 11.2555 13.1363C10.3768 14.4428 8.80469 16.6789 7 18.7826C5.41854 20.6261 3.82834 22.0629 2.75299 22.9578C2.16838 23.4443 1.33567 23.0757 1.28464 22.3168C1.16124 20.4819 1 17.1071 1 12Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
    />
    <path
      d="M12 12C12 7.11096 12.1478 3.80947 12.2686 1.92665C12.3206 1.11673 13.2486 0.758909 13.8309 1.32422C14.9043 2.36624 16.4563 3.93967 18 5.73913C19.7559 7.78594 21.2916 9.74919 22.1827 10.9151C22.681 11.5671 22.7135 12.4553 22.2555 13.1363C21.3768 14.4428 19.8047 16.6789 18 18.7826C16.4185 20.6261 14.8283 22.0629 13.753 22.9578C13.1684 23.4443 12.3357 23.0757 12.2846 22.3168C12.1612 20.4819 12 17.1071 12 12Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
    />
  </>
);
