import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const DownloadIcon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M1.36096 18C1.36573 18.0418 1.37052 18.0832 1.37531 18.1241C1.58295 19.8972 2.91569 21.2307 4.68875 21.4384C6.34926 21.6329 8.76355 21.8138 12 21.8138C15.2365 21.8138 17.6508 21.6329 19.3113 21.4384C21.0844 21.2307 22.4171 19.8972 22.6248 18.1241C22.6296 18.0832 22.6343 18.0418 22.6391 18"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M12 2.21741L12 17M12 17L7.78259 13M12 17L16.2174 13"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
