import "./styles.css";
import "src/verse/src/theme/global.css";

import React from "react";

import { MultiplayerEditor } from "./MultiplayerEditor";
import { LocalStateEditor } from "./LocalStateEditor";
import { ButterboardAppBase } from "./components/ButterboardAppBase";
import { useParams } from "src/hooks/useParams";

export default function App() {
  const { id } = useParams();
  if (id) {
    return (
      <ButterboardAppBase>
        <MultiplayerEditor id={id} />
      </ButterboardAppBase>
    );
  }
  return (
    <ButterboardAppBase>
      <LocalStateEditor />
    </ButterboardAppBase>
  );
}
