import React, { ReactElement, useState } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import { VerseContext } from '../context';
import { VerseTheme } from '../theme';
import { VerseThemeType } from '../theme/typings';

const GlobalStyle = createGlobalStyle`
  #client-snackbar {
    word-break: break-word;
  }
  body {
    background-color: ${({ theme }) =>
      (theme as VerseThemeType).colors.other.White};
    color: ${({ theme }) =>
      (theme as VerseThemeType).colors.main.PrimaryDark100};
  }
`;

interface VerseThemeProviderProps {
  children: ReactElement;
  mountGlobalStyle?: boolean;
}
export const VerseThemeProvider = ({
  children,
  mountGlobalStyle = true,
}: VerseThemeProviderProps) => {
  const [animationEnabled, setAnimationEnabled] = useState<boolean>(true);

  const [theme, setTheme] = useState<VerseThemeType>(VerseTheme);

  return (
    <ThemeProvider theme={theme}>
      {mountGlobalStyle ? <GlobalStyle /> : null}
      <VerseContext.Provider
        value={{
          animationEnabled,
          setAnimationEnabled,
          theme,
          setTheme,
        }}
      >
        {children}
      </VerseContext.Provider>
    </ThemeProvider>
  );
};
