import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const UserPairIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M9.78076 12.158C12.2321 12.158 14.2194 10.1707 14.2194 7.71936C14.2194 5.26799 12.2321 3.28076 9.78076 3.28076C7.32939 3.28076 5.34216 5.26799 5.34216 7.71936C5.34216 10.1707 7.32939 12.158 9.78076 12.158Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M19.0438 4.43862C19.0438 6.65792 17.3069 8.39476 15.0876 8.39476C14.7981 8.39476 14.5859 8.39476 14.1999 8.29827C14.1999 8.10529 14.1999 7.91231 14.1999 7.71933C14.1999 5.7895 12.8683 4.09126 11.1315 3.51231C11.5174 1.77547 13.0613 0.482483 14.9911 0.482483C17.2104 0.482483 19.0438 2.21932 19.0438 4.43862Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M1.19971 18.5C1.2414 17.9706 1.28832 17.5018 1.3363 17.0921C1.4978 15.713 2.53437 14.6758 3.9134 14.5143C5.20488 14.363 7.08263 14.2223 9.59985 14.2223C12.1171 14.2223 13.9948 14.363 15.2863 14.5143C16.6653 14.6758 17.7019 15.713 17.8634 17.0921C17.9114 17.5018 17.9583 17.9706 18 18.5"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M22.8003 14.543C22.7586 14.0136 22.7117 13.5449 22.6637 13.1351C22.5022 11.7561 21.4644 10.7186 20.0854 10.5571C19.1826 10.4514 17.9935 10.3509 16.5 10.3"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
