import React from 'react';
import styled from 'styled-components';
import { components, MenuListComponentProps } from 'react-select';
import { VerseSelectOptionObj } from '..';

export const VerseCreatableSelectMenuList = (
  props: MenuListComponentProps<VerseSelectOptionObj, true>,
) => {
  return (
    <StyledVerseCreatableSelectMenuList>
      <components.MenuList {...props}>{props.children}</components.MenuList>
    </StyledVerseCreatableSelectMenuList>
  );
};

const StyledVerseCreatableSelectMenuList = styled.div`
  width: 290px;
  max-height: 160px;
`;
