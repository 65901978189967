export enum VerseCardBorderRadiusSizeEnum {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum VerseCardShadowElevationEnum {
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum VerseCardShadowColorEnum {
  OPAQUE = 'opaque',
  DARK = 'dark',
}
