import React from 'react';
import styled from 'styled-components';
import { VerseBody2, VerseHelpText, VerseIcon, VerseIconIdEnum } from '..';
import { useVerseTheme } from '../..';
import { VerseKeyframes } from '../VerseKeyframes';
import { VerseInputDataValueEnum, VerseInputSizeEnum } from './consts';
import { useVerseInputController } from './hooks';
import {
  VerseInputComponentProps,
  VerseInputErrorLabelContainerProps,
  VerseInputOuterWrapperProps,
  VerseInputWrapperProps,
} from './typings';

export const VerseInput = ({
  errorLabels,
  testId,
  prefixText,
  suffixElement,
  prefixElement,
  ...other
}: VerseInputComponentProps) => {
  const {
    outerWrapperProps,
    inputWrapperProps,
    styledInputProps,
    inputIconSize,
    errorHoverEvents,
    inputFieldHoverEvents,
    showErrorIcon,
    showValidations,
    validations,
    isPasswordType,
    isPasswordVisible,
    togglePasswordVisibility,
  } = useVerseInputController(other);
  const theme = useVerseTheme();
  return (
    <OuterWrapper {...outerWrapperProps}>
      <InputWrapper
        {...inputWrapperProps}
        {...inputFieldHoverEvents}
        hasPrefixText={Boolean(prefixText)}
        id="input_wrapper"
      >
        {prefixElement}

        {prefixText ? (
          <PrefixTextContainer>
            <VerseBody2 opacity={0.7} linkify={false}>
              {prefixText}
            </VerseBody2>
          </PrefixTextContainer>
        ) : null}
        <InputInnerContainer id="input_inner_container">
          <StyledInput {...styledInputProps} data-testid={testId} />
          {isPasswordType && (
            <IconContainer onClick={togglePasswordVisibility}>
              <VerseIcon
                iconId={
                  isPasswordVisible ? VerseIconIdEnum.HIDE : VerseIconIdEnum.EYE
                }
                stroke={theme.colors.main.PrimaryDark100}
                size={inputIconSize}
                fill="none"
              />
            </IconContainer>
          )}
          {showErrorIcon && (
            <IconContainer {...errorHoverEvents}>
              <VerseIcon
                iconId={VerseIconIdEnum.WARNING_V2}
                stroke={theme.colors.other.Danger100}
                size={inputIconSize}
                fill="none"
              />
            </IconContainer>
          )}
        </InputInnerContainer>

        {suffixElement}
      </InputWrapper>

      <ErrorLabelsContainer
        hasErrors={showValidations && validations.length > 0}
        {...inputFieldHoverEvents}
        id="input_error_labels_container"
      >
        {showValidations &&
          validations.map(validation => {
            const label = errorLabels?.[validation.key];
            /**
             * do not show label if label doesnt exist
             */
            if (!label) {
              return null;
            }

            return (
              <ErrorLabelContainer key={`validation-${validation.key}`}>
                <VerseHelpText
                  color={
                    validation.value
                      ? theme.colors.main.PrimaryDark100
                      : theme.colors.other.Danger100
                  }
                  mr={0.5}
                >
                  {label}
                </VerseHelpText>

                {validation.value && (
                  <VerseIcon iconId={VerseIconIdEnum.CHECK_V1} size={8} />
                )}
              </ErrorLabelContainer>
            );
          })}
      </ErrorLabelsContainer>
    </OuterWrapper>
  );
};
const fadeInAnimation = VerseKeyframes.fadeInKeyframes();

export const ErrorLabelContainer = styled.div<VerseInputErrorLabelContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
  }

  p {
    margin: 0 ${({ theme }) => theme.spacing(0.5)}px 0 0;
  }

  animation: ${fadeInAnimation} ${({ theme }) => theme.animationSpeed}ms 1;
`;
const IconContainer = styled.div`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing(1)}px;

  :nth-child(2) {
    margin-left: 0;
  }

  animation: ${fadeInAnimation} ${({ theme }) => theme.animationSpeed}ms 1;
`;

const StyledInput = styled.input`
  border: none;
  width: 100%;
  height: 100%;
  background: none;
  font-family: GTEestiDisplay;
  font-weight: 300;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  /* Chrome, Firefox, Opera, Safari 10.1+ */
  ::placeholder {
    color: ${({ theme }) => theme.colors.main.PrimaryDark30};
    opacity: 1;
  }
  /* Internet Explorer 10-11 */
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.main.PrimaryDark30};
  }
  /* Microsoft Edge */
  ::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.main.PrimaryDark30};
  }
`;

const InputInnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const PrefixTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 8px 0 16px;
  height: 100%;
  border-right: 1px solid;
  border-color: inherit;
  background-color: ${({ theme }) => theme.colors.other.Sand05};
  margin-right: 8px;
`;
const InputWrapper = styled.div<VerseInputWrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.other.White};
  border: 1px solid;
  overflow: hidden;

  transition: background-color 0.2s linear, border-color 0.2s linear;

  ${({ dataValues, disabled, theme }) => {
    let cssString = ``;
    if (dataValues?.includes(VerseInputDataValueEnum.FILLED)) {
      if (dataValues?.includes(VerseInputDataValueEnum.FOCUS)) {
        cssString =
          cssString + `border-color:${theme.colors.main.PrimaryDark100};`;
      } else {
        cssString =
          cssString +
          `border-color:${theme.colors.main.PrimaryDark50};:hover{border-color:${theme.colors.main.PrimaryDark70};}`;
      }
    } else {
      if (dataValues?.includes(VerseInputDataValueEnum.FOCUS)) {
        cssString =
          cssString + `border-color:${theme.colors.main.PrimaryDark100};`;
      } else {
        cssString =
          cssString +
          `border-color:${theme.colors.main.PrimaryDark30};:hover{border-color:${theme.colors.main.PrimaryDark50};}`;
      }
    }
    if (disabled) {
      cssString =
        cssString +
        `border-color:${theme.colors.main.PrimaryDark20}!important;background-color:${theme.colors.other.Sand03}!important;`;
    } else if (dataValues?.includes(VerseInputDataValueEnum.INVALID)) {
      cssString =
        cssString +
        `border-color:${theme.colors.other.Danger50}!important;background-color:${theme.colors.other.Danger10}!important;`;
    }
    return cssString;
  }}

  ${({ readOnly, disabled }) =>
    readOnly || disabled ? `pointer-events: none;` : ''}

  ${({ size, hasPrefixText, theme }) => {
    switch (size) {
      case VerseInputSizeEnum.SMALL:
        return `
                height: 32px;
                border-radius: 10px;
                padding: ${hasPrefixText ? 0 : `0 ${theme.spacing(1.5)}px`};
                padding-right: ${theme.spacing(1.5)}px;
                ${StyledInput} {font-size: 14px;}
            `;
      case VerseInputSizeEnum.MEDIUM:
        return `
                height: 40px;
                border-radius: 12px;
                padding: ${hasPrefixText ? 0 : `0 ${theme.spacing(2)}px`};
                padding-right: ${theme.spacing(2)}px;
                ${StyledInput} {font-size: 15px;}
            `;
      case VerseInputSizeEnum.LARGE:
        return `
                height: 48px;
                border-radius: 16px;
                padding: ${hasPrefixText ? 0 : `0 ${theme.spacing(2.5)}px`};
                padding-right: ${theme.spacing(2.5)}px;
                
                ${StyledInput} {font-size: 16px;}
            `;
      default:
        return ``;
    }
  }}
`;
const OuterWrapper = styled.div<VerseInputOuterWrapperProps>`
  display: flex;
  flex-direction: column;

  ${({ mr, theme }) => (mr ? `margin-right: ${theme.spacing(mr)}px;` : ``)}
  ${({ ml, theme }) => (ml ? `margin-left: ${theme.spacing(ml)}px;` : ``)}
  ${({ mt, theme }) => (mt ? `margin-top: ${theme.spacing(mt)}px;` : ``)}
  ${({ mb, theme }) => (mb ? `margin-bottom: ${theme.spacing(mb)}px;` : ``)}
  ${({ width }) =>
    width ? `width: ${Number.isInteger(width) ? `${width}px` : width};` : ``}
`;
export const ErrorLabelsContainer = styled.div<{ hasErrors?: boolean }>`
  display: ${({ hasErrors }) => (hasErrors ? 'flex' : 'none')};
  flex-direction: column;
  align-self: stretch;
  padding-top: ${({ theme }) => theme.spacing(1)}px;
`;
