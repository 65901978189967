export enum VerseIconButtonSizeEnum {
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
export enum VerseIconButtonVariantEnum {
  PRIMARY_CTA = 'primaryCTA',
  SECONDARY_CTA = 'secondaryCTA',
  TERTIARY_CTA = 'tertiaryCTA',
  BRANDED_CTA = 'brandedCTA',
  TEXT_ICON_LINK = 'textIconLink',
}
