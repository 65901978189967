import * as React from "react";
import { EraserIcon } from "@radix-ui/react-icons";
import { TDSnapshot } from "@tldraw/tldraw";
import { StyledToolButton } from "./StyledToolButton";
import { useButterTldrawApp } from "../../../utils/useButterTldrawApp";
import { VerseTooltip } from "src/verse/src";

const activeToolSelector = (s: TDSnapshot) => s.appState.activeTool;

export const ToolPanelEraseButton = React.memo(function PrimaryTools() {
  const app = useButterTldrawApp();

  const activeTool = app?.useStore(activeToolSelector);

  const selectEraseTool = React.useCallback(() => {
    app?.selectTool("erase");
  }, [app]);

  return (
    <VerseTooltip title="Eraser Tool (E)" placement="right">
      <StyledToolButton
        onClick={selectEraseTool}
        isActive={activeTool === "erase"}
      >
        <EraserIcon />
      </StyledToolButton>
    </VerseTooltip>
  );
});
