import React, { forwardRef } from 'react';

import { useVerseTheme } from '../..';
import { VERSE_ICON_LIBRARY } from './consts';
import { VerseIconComponentProps } from './typings';
import { IconWrapper, InteractableIconWrapper } from './VerseIconStyles';

export const VerseIcon = forwardRef<HTMLDivElement, VerseIconComponentProps>(
  (
    {
      iconId,
      size = 24,
      viewBox = '0 0 24 24',
      strokePx,
      fill,
      stroke,
      testId,
      svgOnly,
      ...other
    }: VerseIconComponentProps,
    ref,
  ) => {
    const theme = useVerseTheme();
    const IconInner = VERSE_ICON_LIBRARY[iconId];

    const normalizedStroke = React.useMemo(() => {
      const strokeTargetToUse = strokePx ?? (size >= 40 ? 2 : 1);
      const renderedStrokeMultiplier = size / 24;

      /** multiplier to anchor back stroke rendered to `defaultStrokeTarget`. */
      const normalizer = strokeTargetToUse / renderedStrokeMultiplier;
      return 1 * normalizer;
    }, [size, strokePx]);

    /**
     * fixes crash if iconId is undefined
     */
    if (!IconInner || !iconId) {
      return null;
    }

    if (svgOnly) {
      return (
        <IconWrapper
          size={size}
          viewBox={viewBox}
          data-testid={testId}
          className={other?.className}
        >
          <IconInner
            fill={fill ?? theme.colors.other.White}
            strokeWidth={`${normalizedStroke}px`}
            stroke={stroke ?? theme.colors.main.PrimaryDark100}
          />
        </IconWrapper>
      );
    }

    return (
      <InteractableIconWrapper
        size={size}
        {...other}
        ref={ref}
        data-testid={testId}
      >
        <IconWrapper size={size} viewBox={viewBox}>
          <IconInner
            fill={fill ?? theme.colors.other.White}
            strokeWidth={`${normalizedStroke}px`}
            stroke={stroke ?? theme.colors.main.PrimaryDark100}
          />
        </IconWrapper>
      </InteractableIconWrapper>
    );
  },
);
