import * as React from "react";
import { Pencil1Icon } from "@radix-ui/react-icons";
import { TDShapeType, TDSnapshot } from "@tldraw/tldraw";
import { StyledToolButton } from "./StyledToolButton";
import { useButterTldrawApp } from "../../../utils/useButterTldrawApp";
import { VerseTooltip } from "src/verse/src";

const activeToolSelector = (s: TDSnapshot) => s.appState.activeTool;

export const ToolPanelDrawButton = React.memo(function PrimaryTools() {
  const app = useButterTldrawApp();

  const activeTool = app?.useStore(activeToolSelector);

  const selectDrawTool = React.useCallback(() => {
    app?.selectTool(TDShapeType.Draw);
  }, [app]);

  return (
    <VerseTooltip title="Pen Tool (P)" placement="right">
      <StyledToolButton
        onClick={selectDrawTool}
        isActive={activeTool === TDShapeType.Draw}
      >
        <Pencil1Icon />
      </StyledToolButton>
    </VerseTooltip>
  );
});
