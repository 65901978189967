import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const PollIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M1.61605 14.3112C1.65403 13.7832 1.99011 13.3279 2.50315 13.1975C2.90961 13.0942 3.43517 13 4 13C4.56483 13 5.09039 13.0942 5.49685 13.1975C6.00989 13.3279 6.34597 13.7832 6.38395 14.3112C6.43765 15.0574 6.5 16.2918 6.5 18C6.5 19.7082 6.43765 20.9426 6.38395 21.6888C6.34597 22.2168 6.00989 22.6721 5.49685 22.8025C5.09039 22.9058 4.56483 23 4 23C3.43517 23 2.90961 22.9058 2.50315 22.8025C1.99011 22.6721 1.65403 22.2168 1.61605 21.6888C1.56235 20.9426 1.5 19.7082 1.5 18C1.5 16.2918 1.56235 15.0574 1.61605 14.3112Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M9.6517 2.42078C9.6736 1.85451 10.0209 1.35298 10.5715 1.21899C10.9677 1.12257 11.4663 1.03845 12 1.03845C12.5337 1.03845 13.0323 1.12257 13.4285 1.21899C13.9791 1.35298 14.3264 1.85451 14.3483 2.42079C14.4047 3.87985 14.5 7.07292 14.5 12C14.5 16.9354 14.4044 20.1501 14.348 21.617C14.3262 22.1836 13.9788 22.6856 13.4279 22.8196C13.0318 22.916 12.5334 23 12 23C11.4666 23 10.9682 22.916 10.5721 22.8196C10.0212 22.6856 9.67377 22.1836 9.65199 21.617C9.5956 20.1501 9.5 16.9354 9.5 12C9.5 7.07292 9.59527 3.87985 9.6517 2.42078Z"
      fill={Array.isArray(fill) ? fill[1] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M17.6482 7.42275C17.6729 6.85659 18.0209 6.35298 18.5715 6.21899C18.9677 6.12258 19.4663 6.03845 20 6.03845C20.5306 6.03845 21.0265 6.12161 21.4216 6.21732C21.976 6.35162 22.3249 6.85862 22.348 7.42855C22.4044 8.8162 22.5 11.5324 22.5 14C22.5 16.5727 22.3961 19.9767 22.341 21.5998C22.3215 22.1752 21.9714 22.6888 21.4116 22.8235C21.0181 22.9182 20.5261 23 20 23C19.472 23 18.9783 22.9176 18.5842 22.8225C18.0267 22.688 17.677 22.1761 17.6563 21.603C17.6006 20.0673 17.5 16.8928 17.5 14C17.5 11.2422 17.5914 8.72202 17.6482 7.42275Z"
      fill={Array.isArray(fill) ? fill[2] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
