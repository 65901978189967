import React, { useEffect, useMemo } from 'react';

import { useVerseAnimation } from '../..';
import { useDelayUnmount } from '../../utils';
import { VerseDelayUnmountEnum } from './consts';
import { VerseDelayUnmountProps } from './typings';

export const VerseDelayUnmount = ({
  isMounted,
  delayTime = 150,
  children,
  onExitComplete,
}: VerseDelayUnmountProps) => {
  const { animationEnabled } = useVerseAnimation();

  const delayUnmountToUse = animationEnabled ? delayTime : 1;
  const shouldMount = useDelayUnmount({
    isMounted,
    delayTime: delayUnmountToUse,
  });

  const state = useMemo(() => {
    if (!isMounted && shouldMount) {
      return VerseDelayUnmountEnum.EXITING;
    }
    if (isMounted && !shouldMount) {
      return VerseDelayUnmountEnum.MOUNTING;
    }
    if (shouldMount && isMounted) {
      return VerseDelayUnmountEnum.MOUNTED;
    }
    return VerseDelayUnmountEnum.UNMOUNTED;
  }, [shouldMount, isMounted]);

  useEffect(() => {
    if (state === VerseDelayUnmountEnum.UNMOUNTED) {
      onExitComplete && onExitComplete();
    }
  }, [state]);

  if (state === VerseDelayUnmountEnum.UNMOUNTED) {
    return null;
  }

  return <>{children({ state })}</>;
};
