export enum RoomSessionLogTargetTypesEnum {
  room = "room",
  user = "user",
  breakout = "breakout",
  poll = "poll",
  timer = "timer",
}

export enum RoomSessionLogTypesEnum {
  roomStart = "room_start",
  roomStop = "room_stop",
  agendaChanged = "agenda_changed",
  agendaPaused = "agenda_paused",
  agendaResumed = "agenda_resumed",
  participantJoinedCall = "participant_joined_call",
  participantLeftCall = "participant_left_call",
  participantChangedRole = "participant_changed_role",
  breakoutLeave = "breakout_leave",
  breakoutJoin = "breakout_join",
  micOn = "mic_on",
  micOff = "mic_off",
  videoOn = "video_on",
  videoOff = "video_off",
  changeCam = "change_cam",
  changeMic = "change_mic",
  changeSpeaker = "change_speaker",
  handUp = "hand_up",
  handUpSelected = "hand_up_selected",
  handUpCleared = "hand_up_cleared",

  sendReaction = "send_reaction",
  sendChat = "send_chat",
  sendImage = "send_image",
  sendGif = "send_gif",

  breakoutStarted = "breakout_started",
  breakoutStopped = "breakout_stopped",
  breakoutExtended = "breakout_extended",
  pollStarted = "poll_started",
  pollStopped = "poll_stopped",
  pollClosed = "poll_closed",
  timerStarted = "timer_started",
  timerStopped = "timer_stopped",
  timerDone = "timer_done",
  timerNotDone = "timer_not_done",
}
