import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const PaperIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M3 12C3 15.2464 3.14891 17.6656 3.3086 19.3266C3.47797 21.0881 4.73781 22.4664 6.49343 22.6887C7.82354 22.857 9.64596 23 12 23C14.354 23 16.1765 22.8571 17.5066 22.6887C19.2622 22.4664 20.522 21.0881 20.6914 19.3266C20.8511 17.6656 21 15.2464 21 12C21 10.3482 20.9615 8.91053 20.9025 7.67796C20.8512 6.60388 20.3401 5.60828 19.5197 4.91321L16.0183 1.94692C15.3573 1.38697 14.5327 1.05154 13.6668 1.02498C13.144 1.00894 12.5886 1 12 1C9.64596 1 7.82355 1.14295 6.49343 1.31134C4.73781 1.53359 3.47797 2.9119 3.3086 4.67341C3.14891 6.33438 3 8.75355 3 12Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M19.5197 4.91322L15.7639 1.73139C15.2681 1.31134 14.6494 1.06058 14 1.03632V7C14 7.55229 14.4477 8 15 8L20.9173 8.00001C20.9125 7.89124 20.9076 7.78399 20.9026 7.67825C20.8512 6.60417 20.3401 5.60829 19.5197 4.91322Z"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
