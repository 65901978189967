import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const LinkedInIcon = ({ stroke }: VerseIconSVGProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5 20.2273H18.1902V13.9954C18.1902 12.3642 17.5161 11.2507 16.0336 11.2507C14.8997 11.2507 14.2691 12.0021 13.9756 12.7264C13.8656 12.9863 13.8828 13.3484 13.8828 13.7105V20.2273H9.61313C9.61313 20.2273 9.66816 9.18817 9.61313 8.18472H13.8828V10.0747C14.135 9.24833 15.4993 8.06893 17.6766 8.06893C20.3778 8.06893 22.5 9.80114 22.5 13.5312V20.2273ZM5.29533 6.6784H5.26782C3.89199 6.6784 3 5.75781 3 4.5909C3 3.40129 3.91836 2.5 5.3217 2.5C6.72389 2.5 7.58608 3.39903 7.61359 4.5875C7.61359 5.75441 6.72389 6.6784 5.29533 6.6784ZM3.49184 8.18472H7.29257V20.2273H3.49184V8.18472Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
  </>
);
