import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const LinkIcon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M9.49106 14.572C9.93725 15.0181 10.36 15.394 10.7408 15.7056C11.7991 16.5717 13.241 16.4936 14.2865 15.612C15.2155 14.8287 16.5402 13.6331 18.317 11.8563C20.277 9.8963 21.5297 8.48644 22.302 7.55106C23.0454 6.65061 23.1444 5.45666 22.4115 4.54765C22.073 4.12782 21.6245 3.62203 21.0327 3.03028C20.441 2.43853 19.9352 1.99004 19.5154 1.65153C18.6063 0.918585 17.4113 1.01842 16.5109 1.7619C15.8101 2.34057 14.8431 3.18875 13.5782 4.40175"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M14.5968 9.49369C14.1506 9.0475 13.7279 8.67169 13.3471 8.36009C12.2888 7.49397 10.8469 7.57206 9.80141 8.45365C8.87244 9.23696 7.5477 10.4325 5.77084 12.2094C3.81088 14.1693 2.55819 15.5792 1.78591 16.5146C1.04246 17.415 0.943462 18.609 1.67641 19.518C2.01492 19.9378 2.4634 20.4436 3.05516 21.0354C3.64691 21.6271 4.1527 22.0756 4.57253 22.4141C5.48154 23.1471 6.67657 23.0472 7.57699 22.3037C8.27781 21.7251 9.24478 20.8769 10.5097 19.6639"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
