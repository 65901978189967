import { keyframes } from 'styled-components';

export enum VerseRadioButtonSizeEnum {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum VerseRadioButtonVariantEnum {
  DEFAULT = 'default',
  BORDER = 'border',
}

export const VERSE_RADIO_BUTTON_DOT_GROW_KEYFRAMES = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
`;
