import React from 'react';

import { VerseTheme } from '../../../theme';
import { VerseIconSVGProps } from '../typings';

export const TemplateIcon = ({
  fill = VerseTheme.colors.other.White,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M3.75525 15.3279C3.61417 14.1239 3.5 12.5243 3.5 10.5C3.5 8.47571 3.61417 6.87606 3.75526 5.67208C3.94326 4.0677 5.19818 2.90936 6.83988 2.76285C8.39697 2.62388 10.5987 2.5 13.5 2.5C16.4013 2.5 18.603 2.62388 20.1601 2.76285C21.8018 2.90936 23.0567 4.0677 23.2447 5.67208C23.3858 6.87606 23.5 8.47571 23.5 10.5C23.5 12.5243 23.3858 14.1239 23.2447 15.3279C23.0567 16.9323 21.8018 18.0906 20.1601 18.2372C18.603 18.3761 16.4013 18.5 13.5 18.5C10.5987 18.5 8.39697 18.3761 6.83988 18.2372C5.19818 18.0906 3.94326 16.9323 3.75525 15.3279Z"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M0.755255 18.3279C0.614171 17.1239 0.5 15.5243 0.5 13.5C0.5 11.4757 0.614171 9.87606 0.755255 8.67208C0.943257 7.0677 2.19818 5.90936 3.83988 5.76285C5.39697 5.62388 7.59868 5.5 10.5 5.5C13.4013 5.5 15.603 5.62388 17.1601 5.76285C18.8018 5.90936 20.0567 7.0677 20.2447 8.67208C20.3858 9.87606 20.5 11.4757 20.5 13.5C20.5 15.5243 20.3858 17.1239 20.2447 18.3279C20.0567 19.9323 18.8018 21.0906 17.1601 21.2372C15.603 21.3761 13.4013 21.5 10.5 21.5C7.59868 21.5 5.39697 21.3761 3.83988 21.2372C2.19818 21.0906 0.943257 19.9323 0.755255 18.3279Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
