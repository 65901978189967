import React from 'react';
import styled from 'styled-components';

import { VerseIcon } from '../';
import { useVerseTheme } from '../..';
import { VerseIconIdEnum } from '../VerseIcon/consts';
import { VerseButtonTextMedium } from '../VerseTypography/Internal/Button/VerseButtonTextMedium';
import { VerseLinkButtonComponentProps, VerseLinkButtonContainerProps } from './typings';

export function VerseLinkButton({
  href,
  title,
  onClick,
  ...other
}: VerseLinkButtonComponentProps) {
  const theme = useVerseTheme();
  return (
    <LinkContainer
      href={href}
      onClick={onClick}
      target="_blank"
      rel="noopener noreferrer"
      {...other}
    >
      <VerseButtonTextMedium t={title} />
      <VerseIcon
        ml={0.5}
        stroke={theme.colors.main.PrimaryDark100}
        iconId={VerseIconIdEnum.ARROW_RIGHT_V2}
        size={20}
      />
    </LinkContainer>
  );
}

const LinkContainer = styled.a<VerseLinkButtonContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  height: 28px;

  outline: 0 !important;
  padding: 0 12px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.main.PrimaryDark100};
  background-color: ${({ theme }) => theme.colors.other.White};

  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}
  
  cursor: pointer;
  transition: background-color 0.2s linear;
  :hover {
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.other.Sand05};
  }
`;
