import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const GoogleDriveIcon = () => (
  <>
    <path
      d="M4.16272 15.4998V21.9935H19.8293L23.492 15.4998H4.16272Z"
      fill="#4285F4"
    />
    <path
      d="M7.92941 15.4998L11.6961 21.9935H4.16272L7.92941 15.4998Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M7.85742 15.6194H23.2841L23.5 15.4915H7.9294L7.85742 15.6194Z"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M23.2761 15.6197L19.7573 21.8655H4.23469L4.16272 21.9935H19.8293L23.492 15.4998L23.2761 15.6197Z"
      fill="#1A237E"
      fillOpacity="0.2"
    />
    <path
      d="M8.00138 15.6194L7.92941 15.4915L4.16272 21.9932H4.30667L8.00138 15.6194Z"
      fill="#1A237E"
      fillOpacity="0.05"
    />
    <path
      d="M8.24131 2L0.5 15.4993L4.16273 21.993L12.9917 6.75035L8.24131 2Z"
      fill="#0F9D58"
    />
    <path
      d="M8.24131 2L8.31328 2.36787L11.8481 8.46975L4.16273 21.7371L0.64395 15.4913H0.5L4.16273 21.993L12 8.46975L8.24131 2Z"
      fill="#263238"
      fillOpacity="0.1"
    />
    <path
      d="M0.64395 15.4993L8.32128 2.12796L8.24131 2L0.5 15.4993H0.64395Z"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M15.7507 2H8.24133L16.0626 15.4993H23.492L15.7507 2Z"
      fill="#FFCD40"
    />
    <path
      d="M15.7507 2H8.24133L8.45726 2.12796H15.6707L23.3481 15.4993H23.492L15.7507 2Z"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M16.1346 15.3713L8.45726 2.12796L8.24133 2L16.0626 15.4993L16.1346 15.3713Z"
      fill="#BF360C"
      fillOpacity="0.1"
    />
    <path
      d="M15.7507 2H8.24131L0.5 15.4993L4.16273 21.993H19.8293L23.492 15.4993L15.7507 2ZM7.92942 15.4993L12 8.47775L16.0706 15.4993H7.92942Z"
      fill="url(#paint1_radial)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="6.06748"
        y1="18.7575"
        x2="11.7429"
        y2="21.8367"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1A237E" stopOpacity="0.2" />
        <stop offset="1" stopColor="#1A237E" stopOpacity="0.02" />
      </linearGradient>
      <radialGradient
        id="paint1_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.29329 2.20205) scale(22.8988)"
      >
        <stop stopColor="white" stopOpacity="0.1" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </>
);
