import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const TapeIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M1.31134 6.49343C1.53359 4.73781 2.9119 3.47797 4.67341 3.3086C6.33438 3.14891 8.75355 3 12 3C15.2464 3 17.6656 3.14891 19.3266 3.3086C21.0881 3.47797 22.4664 4.73781 22.6887 6.49343C22.8571 7.82355 23 9.64596 23 12C23 14.354 22.8571 16.1765 22.6887 17.5066C22.4664 19.2622 21.0881 20.522 19.3266 20.6914C17.6656 20.8511 15.2464 21 12 21C8.75355 21 6.33438 20.8511 4.67341 20.6914C2.9119 20.522 1.53359 19.2622 1.31134 17.5066C1.14295 16.1765 1 14.354 1 12C1 9.64596 1.14295 7.82354 1.31134 6.49343Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <circle
      cx="6.91926"
      cy="11.7692"
      r="2.76923"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <circle
      cx="17.0731"
      cy="11.7692"
      r="2.76923"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M6.64221 14.5385C6.64221 14.5385 9.22683 15 11.9961 15C14.7653 15 17.2576 14.5385 17.2576 14.5385"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
