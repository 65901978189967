import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const GoogleChromeIcon = () => (
  <>
    <mask
      id="mask0"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="22"
      height="22"
    >
      <circle cx="12" cy="12" r="11" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0)">
      <path d="M2.74622 1V14.5H7.67122L12 7H23V1H2.74622Z" fill="#DB4437" />
      <path
        d="M2.74622 1V14.5H7.67122L12 7H23V1H2.74622Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M7.78755 14.4501L2.81005 5.9126L2.73755 6.0376L7.68005 14.5126L7.78755 14.4501Z"
        fill="#3E2723"
        fillOpacity="0.15"
      />
      <path
        d="M1 23.0001H11.4712L16.3312 18.1401V14.5001H7.66875L1 3.06006V23.0001Z"
        fill="#0F9D58"
      />
      <path
        d="M1 23.0001H11.4712L16.3312 18.1401V14.5001H7.66875L1 3.06006V23.0001Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M16.225 14.6625L16.1213 14.6025L11.3213 22.9963H11.465L16.2275 14.6663L16.225 14.6625Z"
        fill="#263238"
        fillOpacity="0.15"
      />
      <path
        d="M11.9999 7L16.3312 14.5L11.4712 23H22.9999V7H11.9999Z"
        fill="#FFCD40"
      />
      <path
        d="M11.9999 7L16.3312 14.5L11.4712 23H22.9999V7H11.9999Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M11.9999 7L16.3312 14.5L11.4712 23H22.9999V7H11.9999Z"
        fill="#FFCD40"
      />
      <path
        d="M11.9999 7L16.3312 14.5L11.4712 23H22.9999V7H11.9999Z"
        fill="url(#paint3_linear)"
      />
      <path d="M2.74622 1V14.5H7.67122L12 7H23V1H2.74622Z" fill="#DB4437" />
      <path
        d="M2.74622 1V14.5H7.67122L12 7H23V1H2.74622Z"
        fill="url(#paint4_linear)"
      />
      <path d="M12 7V9.61875L21.8 7H12Z" fill="url(#paint5_radial)" />
      <path
        d="M1 23.0001H11.4712L14.8125 17.3751L16.3312 14.5001H7.66875L1 3.06006V23.0001Z"
        fill="#0F9D58"
      />
      <path
        d="M1 23.0001H11.4712L14.8125 17.4376L16.3312 14.5001H7.66875L1 3.06006V23.0001Z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M2.74622 6.05615L9.90247 13.2112L7.66997 14.4999L2.74622 6.05615Z"
        fill="url(#paint7_radial)"
      />
      <path
        d="M11.4788 22.9874L14.0988 13.2124L16.3313 14.4999L11.4788 22.9874Z"
        fill="url(#paint8_radial)"
      />
      <path
        d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
        fill="#F1F1F1"
      />
      <path
        d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
        fill="#4285F4"
      />
      <path
        d="M12 6.875C9.2375 6.875 7 9.1125 7 11.875V12C7 9.2375 9.2375 7 12 7H23V6.875H12Z"
        fill="#3E2723"
        fillOpacity="0.2"
      />
      <path
        d="M16.325 14.5001C15.46 15.9926 13.85 17.0001 12 17.0001C10.15 17.0001 8.5375 15.9926 7.675 14.5001H7.67L1 3.06006V3.18506L7.675 14.6251C8.54 16.1176 10.15 17.1251 12 17.1251C13.85 17.1251 15.46 16.1188 16.325 14.6251H16.3312V14.5001H16.3237H16.325Z"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        opacity="0.1"
        d="M12.125 7C12.1037 7 12.0838 7.0025 12.0625 7.00375C14.795 7.0375 17 9.26 17 12C17 14.74 14.795 16.9625 12.0625 16.9963C12.0838 16.9963 12.1037 17 12.125 17C14.8875 17 17.125 14.7625 17.125 12C17.125 9.2375 14.8875 7 12.125 7Z"
        fill="#3E2723"
      />
      <path
        d="M16.3749 14.6625C16.7999 13.9275 17.0462 13.0775 17.0462 12.1675C17.0465 11.6547 16.9676 11.145 16.8124 10.6562C16.9312 11.0837 16.9999 11.5313 16.9999 11.9975C16.9999 12.9075 16.7537 13.7575 16.3287 14.4925L16.3312 14.4975L11.4712 22.9975H11.6162L16.3749 14.6625Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M12 1.125C18.0537 1.125 22.965 6.01625 23 12.0625C23 12.0412 23.0012 12.0212 23.0012 12C23.0012 5.925 18.0762 1 12.0012 1C5.92625 1 1 5.925 1 12C1 12.0212 1.00125 12.0412 1.00125 12.0625C1.035 6.01625 5.94625 1.125 12 1.125Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M12 22.875C18.0537 22.875 22.965 17.9838 23 11.9375C23 11.9588 23.0012 11.9788 23.0012 12C23.0012 18.075 18.0762 23 12.0012 23C5.92625 23 1 18.075 1 12C1 11.9788 1.00125 11.9588 1.00125 11.9375C1.035 17.9838 5.94625 22.875 12.0012 22.875H12Z"
        fill="#3E2723"
        fillOpacity="0.15"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="3.66747"
        y1="9.3775"
        x2="10.23"
        y2="5.54375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A52714" stopOpacity="0.6" />
        <stop offset="0.66" stopColor="#A52714" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="13.8625"
        y1="20.5626"
        x2="6.5675"
        y2="16.2876"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#055524" stopOpacity="0.4" />
        <stop offset="0.33" stopColor="#055524" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="15.2374"
        y1="6.225"
        x2="17.0749"
        y2="14.2625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EA6100" stopOpacity="0.3" />
        <stop offset="0.66" stopColor="#EA6100" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="15.2374"
        y1="6.225"
        x2="17.0749"
        y2="14.2625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EA6100" stopOpacity="0.3" />
        <stop offset="0.66" stopColor="#EA6100" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="3.66747"
        y1="9.3775"
        x2="10.23"
        y2="5.54375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A52714" stopOpacity="0.6" />
        <stop offset="0.66" stopColor="#A52714" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint5_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.525 6.99375) scale(10.51)"
      >
        <stop stopColor="#3E2723" stopOpacity="0.2" />
        <stop offset="1" stopColor="#3E2723" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint6_linear"
        x1="13.8625"
        y1="20.5626"
        x2="6.5675"
        y2="16.2876"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#055524" stopOpacity="0.4" />
        <stop offset="0.33" stopColor="#055524" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint7_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(2.73747 6.0649) scale(9.755)"
      >
        <stop stopColor="#3E2723" stopOpacity="0.2" />
        <stop offset="1" stopColor="#3E2723" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint8_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.975 12.0174) scale(10.9838)"
      >
        <stop stopColor="#263238" stopOpacity="0.2" />
        <stop offset="1" stopColor="#263238" stopOpacity="0" />
      </radialGradient>
    </defs>
  </>
);
