import React from 'react';
import { components, PlaceholderProps } from 'react-select';
import styled from 'styled-components';

import { VerseIcon } from '..';
import { VerseIconId } from '../VerseIcon/typings';

interface VerseSelectPlaceholderProps
  extends Omit<PlaceholderProps<any, any>, "theme" | "children"> {
  children: string | { label: string; iconId?: VerseIconId };
}
export const VerseSelectPlaceholder = ({
  children,
  ...other
}: VerseSelectPlaceholderProps) => {
  const renderChildren = () => {
    return typeof children === 'string' ? (
      children
    ) : (
      <>
        {children?.iconId && (
          <VerseIcon
            size={16}
            stroke="inherit"
            mr={0.75}
            iconId={children.iconId}
          />
        )}

        {children.label}
      </>
    );
  };
  return (
    <PlaceholderContainer {...other}>
      {renderChildren() as any}
    </PlaceholderContainer>
  );
};
const PlaceholderContainer = styled(
  components.Placeholder,
)<VerseSelectPlaceholderProps>`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
