import * as React from "react";

import { ToolPanelDeleteButton } from "./ToolPanelDeleteButton";
// import { ToolPanelGroupButton } from "./ToolPanelGroupButton";
import { StyledToolbar } from "../../StyledToolsPanel";
import { useButterTldrawApp } from "src/utils/useButterTldrawApp";
import { ToolPanelLockButton } from "./ToolPanelLockButton";
import { VerseBox } from "src/verse/src";

export const ToolPanelSelectedButtons = React.memo(function ToolsPanel() {
  const app = useButterTldrawApp();

  const hasSelection = app?.useStore(
    (s) =>
      s.appState.status === "idle" &&
      s.document.pageStates[s.appState.currentPageId].selectedIds.length > 0
  );
  if (hasSelection) {
    return (
      <VerseBox position="absolute" top={-144} left={0}>
        <StyledToolbar isSelected={true}>
          <ToolPanelLockButton />
          {/* <ToolPanelGroupButton /> */}
          <ToolPanelDeleteButton />
        </StyledToolbar>
      </VerseBox>
    );
  }
  return null;
});
