import React from 'react';
import { createPortal } from 'react-dom';
import styled, { createGlobalStyle } from 'styled-components';
import {
  VerseDrawerComponentProps,
  VerseDrawerScrimColorVariant,
} from './typings';
import usePortal from '../../utils/usePortal';
import { VerseDrawerScrimColorEnum, VerseDrawerPositionEnum } from './consts';
import { useDelayUnmount, useKeyPress } from '../../utils';
import { VerseTheme } from '../../theme';
import { VerseIcon } from '../VerseIcon';
import { VerseIconIdEnum } from '../VerseIcon/consts';
import { VerseBox } from '../VerseBox';
import { VerseTooltip } from '../VerseTooltip';

export const VerseDrawer = ({
  open,
  onClose,
  children,
  scrimColorVariant = VerseDrawerScrimColorEnum.MEDIUM,
  position = VerseDrawerPositionEnum.LEFT,
  zIndex,
  padding,
  withCloseButton = true,
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick = true,
  closeButtonTooltip,
  ...others
}: VerseDrawerComponentProps) => {
  const portalRef = usePortal({ portalId: 'drawer-portal' });

  const shouldMount = useDelayUnmount({
    isMounted: open,
    delayTime: VerseTheme.animationSpeed,
  });

  useKeyPress({
    targetKey: 'Escape',
    onKeyDown: onClose,
    enabled: shouldCloseOnEsc && open,
  });

  return (
    <>
      {createPortal(
        <>
          {shouldMount ? (
            <>
              <VerseDrawerScrim
                onClick={shouldCloseOnOverlayClick ? onClose : undefined}
                colorVariant={scrimColorVariant}
                zIndex={zIndex}
              />
              <DisableScrollBody />
            </>
          ) : null}

          <VerseDrawerContainer
            open={open}
            zIndex={zIndex}
            position={position}
            padding={padding}
            {...others}
          >
            <VerseBox
              width="100%"
              height="100%"
              position="relative"
              id="verseDrawerRoot"
            >
              {withCloseButton && onClose ? (
                <VerseTooltip title={closeButtonTooltip ?? 'Close'}>
                  <VerseBox position="absolute" right={0} top={0}>
                    <VerseIcon
                      iconId={VerseIconIdEnum.CLOSE}
                      onClick={onClose}
                      size={16}
                    />
                  </VerseBox>
                </VerseTooltip>
              ) : null}

              {shouldMount ? children : null}
            </VerseBox>
          </VerseDrawerContainer>
        </>,
        portalRef,
      )}
    </>
  );
};

const VerseDrawerContainer = styled.div<Partial<VerseDrawerComponentProps>>`
  height: 100%;
  background: white;
  position: fixed;
  overflow: scroll;
  top: ${({ offset }) => offset?.y ?? 0}px;
  ${({ position }) => {
    switch (position) {
      case VerseDrawerPositionEnum.RIGHT:
        return `right: 0;`;

      case VerseDrawerPositionEnum.LEFT:
      default:
        return `left: 0;`;
    }
  }}

  ${({ width = 500 }) =>
    width && `width: ${Number.isInteger(width) ? `${width}px` : width};`}
  ${({ maxWidth = '100%' }) =>
    maxWidth &&
    `max-width: ${Number.isInteger(maxWidth) ? `${maxWidth}px` : maxWidth};`}

  z-index: ${({ theme, zIndex }) =>
    zIndex ? zIndex : theme.zIndex.level.drawer};

  box-shadow: ${({ theme }) => theme.shadows.primaryDark13};
  transition: transform ${({ theme }) => theme.animationSpeed}ms ease-in-out;

  transform: ${({ open, offset, position }) => {
    const isLeftPosition = position === VerseDrawerPositionEnum.LEFT;
    const collapsedOffset = isLeftPosition ? `-100%` : `100%`;
    const expandedOffset = isLeftPosition ? offset?.x ?? 0 : -(offset?.x ?? 0);

    return open
      ? `translateX(${expandedOffset}px)`
      : `translateX(${collapsedOffset})`;
  }};
  padding: ${({ padding, theme }) =>
    padding
      ? Number.isInteger(padding)
        ? `${theme.spacing(padding)}px`
        : padding
      : padding ?? `${theme.spacing(3)}px ${theme.spacing(4)}px`};
`;

const VerseDrawerScrim = styled.div<{
  colorVariant?: VerseDrawerScrimColorVariant;
  zIndex?: number;
}>`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: ${({ theme, zIndex }) =>
    zIndex ? zIndex : theme.zIndex.level.drawer};
  top: 0;
  right: 0;

  ${({ colorVariant, theme }) => {
    switch (colorVariant) {
      case VerseDrawerScrimColorEnum.LIGHT:
        return `background-color: ${theme.colors.main.PrimaryDark10}; opacity: 0.4;`;
      case VerseDrawerScrimColorEnum.MEDIUM:
        return `background-color: ${theme.colors.main.PrimaryDark40}; opacity: 0.4;`;
      case VerseDrawerScrimColorEnum.DARK:
        return `background-color: ${theme.colors.main.PrimaryDark100}; opacity: 0.4;`;
      case VerseDrawerScrimColorEnum.NONE:
      default:
        return ``;
    }
  }}
`;

const DisableScrollBody = createGlobalStyle`
  body{
    overflow: hidden;
  }
`;
