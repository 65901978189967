import React from 'react';
import styled from 'styled-components';

import { VerseBody3 } from '..';
import {
  KEYPRESS_PREFIX_CONTAINER_MOTION_PROPS,
  VerseShortcutBadgeDirectionEnum,
} from './shortcutBadgeConsts';
import {
  ShortcutBadgeContainerProps,
  VerseShortcutBadgeComponentProps,
} from './typings';

export function VerseShortcutBadge({
  shouldExpand,
  shortcutKey,
  ...other
}: VerseShortcutBadgeComponentProps) {
  return (
    <ShortcutBadgeContainer shouldExpand={shouldExpand} {...other}>
      {typeof shortcutKey === 'string' ? (
        <InnerKeypressContainer>
          <VerseBody3 component="span">{shortcutKey}</VerseBody3>
        </InnerKeypressContainer>
      ) : null}
      {Array.isArray(shortcutKey) ? (
        <>
          <InnerKeypressContainer>
            <VerseBody3 component="span">{shortcutKey[1]}</VerseBody3>
          </InnerKeypressContainer>

          <InnerKeypressContainer {...KEYPRESS_PREFIX_CONTAINER_MOTION_PROPS}>
            <VerseBody3 component="span">{shortcutKey[0]}</VerseBody3>
          </InnerKeypressContainer>
        </>
      ) : null}
    </ShortcutBadgeContainer>
  );
}

export const ShortcutBadgeContainer = styled.div<ShortcutBadgeContainerProps>`
  display: flex;
  ${({ direction, shouldExpand }) => {
    switch (direction) {
      case VerseShortcutBadgeDirectionEnum.ROW:
        return `
        width: ${shouldExpand ? 34 : 20}px;
        height: 20px;
        flex-direction: row-reverse;
        padding-right: ${shouldExpand ? 0 : 1}px;
        `;
      case VerseShortcutBadgeDirectionEnum.COLUMN:
      default:
        return `
        width: 20px;
        height: ${shouldExpand ? 40 : 20}px;
        flex-direction: column-reverse;
        `;
    }
  }}

  background-color: ${({ shouldExpand, theme }) =>
    shouldExpand ? theme.colors.other.White : theme.colors.main.PrimaryDark03};
  border: 1px solid ${({ theme }) => theme.colors.main.PrimaryDark10};
  border-radius: 6px;
  overflow: hidden;

  transition: all ${({ theme }) => theme.animationSpeed}ms ease-in;

  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}
`;

export const InnerKeypressContainer = styled.div`
  min-width: 16px;
  min-height: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  span {
    transform: translateY(1px);
  }
`;
