import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const IndentIcon = ({ stroke }: VerseIconSVGProps) => (
  <>
    <path
      d="M1.78259 7.79999L5.78259 12.0174L1.78259 16.2348"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 3V21"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
    />
    <path
      d="M22 6L13 6"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
    />
    <path
      d="M22 12L13 12"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
    />
    <path
      d="M22 18L13 18"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
    />
  </>
);
