import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ScreenIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M1.27587 5.86391C1.50422 4.13274 2.89687 2.92731 4.6366 2.77747C6.29856 2.63433 8.72949 2.5 12 2.5C15.2705 2.5 17.7014 2.63433 19.3634 2.77747C21.1031 2.92731 22.4958 4.13274 22.7241 5.86391C22.8766 7.02016 23 8.55618 23 10.5C23 12.4438 22.8766 13.9798 22.7241 15.1361C22.4958 16.8673 21.1031 18.0727 19.3634 18.2225C17.7014 18.3657 15.2705 18.5 12 18.5C8.72949 18.5 6.29856 18.3657 4.6366 18.2225C2.89687 18.0727 1.50422 16.8673 1.27587 15.1361C1.12336 13.9798 1 12.4438 1 10.5C1 8.55618 1.12336 7.02016 1.27587 5.86391Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M7 21C7 21 8.875 20.5 12 20.5C15.125 20.5 17 21 17 21"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
