import { ButterBoardEventsEnum } from "src/butter-const";

export const sendContentDataToWebapp = (content: any) => {
  window.parent?.postMessage(
    {
      butterBoardType: ButterBoardEventsEnum.CONTENT_DATA,
      content: content,
    },
    "*"
  );
};

export const sendInsertedContentMessageToWebapp = () => {
  window.parent?.postMessage(
    { butterBoardType: ButterBoardEventsEnum.INSERTED_CONTENT },
    "*"
  );
};

export const sendTemplateButtonClickedToWebapp = () => {
  window.parent?.postMessage(
    { butterBoardType: ButterBoardEventsEnum.CLICKED_TEMPLATES_BUTTON },
    "*"
  );
};
