import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const CameraIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M1 12.3077C1 14.3403 1.12967 16.012 1.28748 17.2939C1.51943 19.1779 3.03072 20.5272 4.92325 20.6744C6.5983 20.8047 8.95721 20.9231 12 20.9231C15.0428 20.9231 17.4017 20.8047 19.0767 20.6744C20.9693 20.5272 22.4806 19.1779 22.7125 17.2939C22.8703 16.012 23 14.3403 23 12.3077C23 10.3797 22.8833 8.91426 22.7366 7.83372C22.5153 6.2034 21.1839 5.11902 19.5445 4.97912C18.8852 4.92285 18.1009 4.86688 17.1916 4.81952C16.6015 4.78879 16.0716 4.44116 15.8174 3.90776C15.553 3.35319 14.9933 3 14.379 3H9.62102C9.00665 3 8.44701 3.35319 8.18264 3.90776C7.92835 4.44116 7.39852 4.78879 6.8084 4.81952C5.89913 4.86688 5.11482 4.92285 4.45546 4.97912C2.81614 5.11902 1.48469 6.2034 1.26336 7.83372C1.11667 8.91426 1 10.3797 1 12.3077Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <circle
      cx="12"
      cy="12.5"
      r="4.5"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
