import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ExitIcon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M21 6.07692C20.9726 5.70331 20.9437 5.35445 20.9142 5.03032C20.7348 3.05862 19.31 1.51184 17.3435 1.28195C16.0151 1.12665 14.2675 1 12.1008 1C9.93406 1 8.18646 1.12665 6.85801 1.28195C4.89155 1.51184 3.46677 3.05864 3.28733 5.03034C3.13553 6.69828 3 9.0215 3 12C3 14.9785 3.13553 17.3017 3.28733 18.9697C3.46677 20.9414 4.89155 22.4882 6.85801 22.718C8.18646 22.8734 9.93406 23 12.1008 23C14.2675 23 16.0151 22.8734 17.3436 22.718C19.31 22.4882 20.7348 20.9414 20.9142 18.9697C20.9437 18.6456 20.9726 18.2967 21 17.9231"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M9 12.0001H21M21 12.0001L17 7.78271M21 12.0001L17 16.2175"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
