import React, { useMemo } from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import uniqueId from 'lodash/uniqueId';

import { VerseSwitchSizeEnum } from './consts/switchConsts';
import {
  SwitchBaseComponentProps,
  VerseSwitchComponentProps,
  VerseSwitchWrapperProps,
} from './typings';

export function VerseSwitch(props: VerseSwitchComponentProps) {
  const { control, defaultValue = false, ...other } = props;
  if (control) {
    return <ControlledSwitch {...props} />;
  }
  return <SwitchBase checked={defaultValue as boolean} {...other} />;
}

export function ControlledSwitch({
  onChange,
  name,
  control,
  defaultValue,
  rules,
  onFocus,
  ...other
}: VerseSwitchComponentProps) {
  const {
    field: { ref, value, onChange: defaultOnChange, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
    onFocus,
  });

  const combinedOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    defaultOnChange(e.target.checked);
    onChange && onChange(e);
  };

  return (
    <SwitchBase
      inputRef={ref}
      checked={value}
      onChange={combinedOnChange}
      inputProps={inputProps}
      {...other}
    />
  );
}

export function SwitchBase({
  size = VerseSwitchSizeEnum.MEDIUM,
  disabled,
  checked = false,
  onChange,
  inputProps,
  readOnly,
  inputRef,
  testId,
  ...other
}: SwitchBaseComponentProps) {
  const readOnlyOnChange = () => {
    return false;
  };
  const switchId = useMemo(uniqueId, []);

  return (
    <SwitchWrapper size={size} {...other}>
      <HiddenInput
        id={`verse-switch-${switchId}`}
        type="checkbox"
        disabled={disabled}
        onChange={readOnly ? readOnlyOnChange : onChange}
        checked={checked}
        readOnly={readOnly}
      />
      <Switch
        htmlFor={`verse-switch-${switchId}`}
        ref={inputRef}
        {...inputProps}
        data-testid={testId}
      />
    </SwitchWrapper>
  );
}

const Switch = styled.label`
  position: relative;
  border: 1px solid;
  display: flex;
  flex-direction: row;
  align-items: center;

  background: ${({ theme }) => theme.colors.other.White};
  cursor: pointer;
  transition: all ${({ theme }) => theme.animationSpeed}ms linear;

  &::after {
    content: '';
    position: absolute;
    left: 2px;

    border-radius: 50%;
    background: ${({ theme }) => theme.colors.main.PrimaryDark100};
    transition: all ${({ theme }) => theme.animationSpeed}ms linear;
  }
`;
const HiddenInput = styled.input`
  display: none;

  :checked + ${Switch} {
    background: ${({ theme }) => theme.colors.main.PrimaryYellow100};
    &::after {
      left: calc(50% + 1px);
    }
  }

  :hover + ${Switch} {
    ${({ theme }) => theme.helpers.hover.elevate}
  }
  :disabled + ${Switch} {
    opacity: 0.3;
    transform: unset;
    box-shadow: unset;
  }
  :active + ${Switch} {
    transform: unset;
    box-shadow: unset;
  }

  ${({ readOnly }) => readOnly && `+ ${Switch}{pointer-events: none;}`}
`;

const SwitchWrapper = styled.div<VerseSwitchWrapperProps>`
  position: relative;
  width: max-content;

  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing(mr)}px;`}
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing(ml)}px;`}
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing(mt)}px;`}
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing(mb)}px;`}

  ${Switch} {
    ${({ size }) => {
      switch (size) {
        case VerseSwitchSizeEnum.MEDIUM:
          return `
            width: 44px;
            height: 24px;
            border-radius: 12px;
            &::after {
                width: 18px;
                height: 18px;
            }
          `;
        case VerseSwitchSizeEnum.LARGE:
          return `
          width: 52px;
          height: 28px;
          border-radius: 14px;
          &::after {
            width: 22px;
            height: 22px;
          }
          `;
        default:
          return ``;
      }
    }}
  }
`;
