import { keyframes } from 'styled-components';

export interface OpacityGlowKeyframesProps {
  from: number;
  to: number;
}

export const opacityGlowKeyframes = ({
  from,
  to,
}: OpacityGlowKeyframesProps) => keyframes`
0% {
  opacity: ${from};
}
50% {
  opacity: ${to};
}
100% {
  opacity: ${from};
}
`;
