import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const MusicIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M4.5 22.9C6.433 22.9 8 21.333 8 19.4C8 17.467 6.433 15.9 4.5 15.9C2.567 15.9 1 17.467 1 19.4C1 21.333 2.567 22.9 4.5 22.9Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M19.6 19.9C21.533 19.9 23.1 18.333 23.1 16.4C23.1 14.467 21.533 12.9 19.6 12.9C17.667 12.9 16.1 14.467 16.1 16.4C16.1 18.333 17.667 19.9 19.6 19.9Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M8 19.6V5.9195C8 4.74934 8.68358 3.6935 9.79348 3.32286C11.0439 2.9053 12.9309 2.36296 15.4 1.9C17.8331 1.44379 19.7781 1.21883 21.1045 1.1079C22.2053 1.01586 23.1 1.89543 23.1 3V16.4"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M8 12V10.0996C8 9.12445 8.56975 8.24411 9.49136 7.9255C10.7304 7.49715 12.7231 6.90192 15.4 6.4C18.1588 5.88273 20.2899 5.66276 21.6036 5.56921C22.4299 5.51037 23.1 6.17157 23.1 7V9.3"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
