import React from 'react';
import styled from 'styled-components';

import { VerseDelayState, VerseDelayUnmount, VerseDelayUnmountEnum } from '..';
import { VerseKeyframes } from '../VerseKeyframes';
import { VerseDotBadgeComponentProps, VerseDotBadgeContainerProps } from './typings';

export function VerseDotBadge({
  invisible,
  children,
  offsetX,
  offsetY,
  ...props
}: VerseDotBadgeComponentProps) {
  return (
    <OuterContainer>
      {children}

      <VerseDelayUnmount isMounted={!invisible}>
        {({ state }) => (
          <MessageBadge
            state={state}
            offsetX={offsetX}
            offsetY={offsetY}
            {...props}
          />
        )}
      </VerseDelayUnmount>
    </OuterContainer>
  );
}
const OuterContainer = styled.div`
  position: relative;
  width: fit-content;
`;

const fadeInAnimation = VerseKeyframes.fadeInKeyframes();
const fadeOutAnimation = VerseKeyframes.fadeOutKeyframes();
interface MessageBadgeProps extends VerseDotBadgeContainerProps {
  state: VerseDelayState;
}
const MessageBadge = styled.div<MessageBadgeProps>`
  position: absolute;
  ${({ size = 10, offsetY = 0, offsetX = 0 }) => `
    width: ${size}px;
    height: ${size}px;
    border-radius: ${size / 2}px;
    top: ${-(size / 2) + offsetY}px;
    right: ${-(size / 2) + offsetX}px;
  `}

  animation: ${({ state }) => {
    switch (state) {
      case VerseDelayUnmountEnum.MOUNTED:
      case VerseDelayUnmountEnum.MOUNTING: {
        return fadeInAnimation;
      }
      default: {
        return fadeOutAnimation;
      }
    }
  }}
  ${({ theme }) => theme.animationSpeed}ms 1;

  background-color: ${({ color, theme }) =>
    color ?? theme.colors.other.Danger100};
  box-shadow: ${({ theme }) => theme.shadows.primaryDark07};
`;
