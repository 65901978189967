export enum VerseButtonSizeEnum {
  XSMALL = 'xSmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xLarge',
}
export enum VerseButtonVariantEnum {
  PRIMARY_CTA = 'primaryCTA',
  SECONDARY_CTA = 'secondaryCTA',
  TERTIARY_CTA = 'tertiaryCTA',
  BRANDED_CTA = 'brandedCTA',
  TEXT_ICON_LINK = 'textIconLink',
}
export enum VerseButtonIconPositionEnum {
  LEFT = 'left',
  RIGHT = 'right',
}
