import { keyframes } from 'styled-components';

export interface SpinKeyframeProps {
  fromDeg: number;
  toDeg: number;
}
export const spinKeyframes = ({
  fromDeg,
  toDeg,
}: SpinKeyframeProps) => keyframes`
from { transform: rotate(${fromDeg}deg); }
to { transform: rotate(${toDeg}deg); }
`;
