import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const EmojiAddV1Icon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M14.5 1.28533C13.6969 1.09868 12.8599 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 11.1401 22.9013 10.3031 22.7147 9.5"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M19.2 12C19.2 15.9765 15.9765 19.2 12 19.2C8.0236 19.2 4.80005 15.9765 4.80005 12"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M8.5 9 T8.5 9, M15.5 9 T15.5 9"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth) * 1.25}
      strokeLinecap="round"
    />
    <path
      d="M17 4H23"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 7L20 1"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
