import * as React from "react";

import { ToolPanelArrowButton } from "./ToolPanelButtons/ToolPanelArrowButton";
import { ToolPanelSelectButton } from "./ToolPanelButtons/ToolPanelSelectButton";
import { ToolPanelDrawButton } from "./ToolPanelButtons/ToolPanelDrawButton";
import { ToolPanelEraseButton } from "./ToolPanelButtons/ToolPanelEraseButton";
import { ToolPanelTextButton } from "./ToolPanelButtons/ToolPanelTextButton";
import { ToolPanelStickyButton } from "./ToolPanelButtons/ToolPanelStickyButton";
import { ToolPanelShapeButton } from "./ToolPanelButtons/ToolPanelShapeButton";
import { ToolPanelTemplateButton } from "./ToolPanelButtons/ToolPanelTemplateButton";
// import { ToolPanelSnapshotButton } from "./ToolPanelButtons/ToolPanelSnapshotButton";
import {
  StyledCenterWrap,
  StyledToolbar,
  StyledToolsPanelContainer,
} from "./StyledToolsPanel";
import { ToolPanelSelectedButtons } from "./ToolPanelButtons/ToolPanelSelectedButtons";

export const ToolsPanel = React.memo(function ToolsPanel() {
  return (
    <StyledToolsPanelContainer
      bp={{
        "@initial": "mobile",
        "@sm": "small",
        "@md": "medium",
        "@lg": "large",
      }}
    >
      <StyledCenterWrap id="TD-Tools">
        <StyledToolbar>
          <ToolPanelSelectButton />
          <ToolPanelDrawButton />
          <ToolPanelEraseButton />
          <ToolPanelShapeButton />
          <ToolPanelArrowButton />
          <ToolPanelTextButton />
          <ToolPanelStickyButton />
          <ToolPanelTemplateButton />
          {/* <ToolPanelSnapshotButton /> */}
          <ToolPanelSelectedButtons />
        </StyledToolbar>
      </StyledCenterWrap>
    </StyledToolsPanelContainer>
  );
});
