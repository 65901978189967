import React from 'react';
import styled from 'styled-components';

import { VerseBody2, VerseH4 } from '..';
import { VerseButton } from '../VerseButton';
import {
  VerseButtonSizeEnum,
  VerseButtonVariantEnum,
} from '../VerseButton/consts';
import { VerseModalWithHeaderComponentProps } from './typings';
import { VerseBaseModal } from './VerseBaseModal';

export const VerseModalWithHeader = ({
  title,
  description,
  primaryButton,
  secondaryButton,
  imageUrl,
  components,
  shouldCloseOnOverlayClick = false,
  testId,
  ...other
}: VerseModalWithHeaderComponentProps) => {
  const renderHeader = () => {
    if (components?.Header) {
      const props = components?.headerProps || {};
      return <components.Header {...props} />;
    }
    return <HeaderArea imageUrl={imageUrl} />;
  };

  return (
    <StyledVerseBaseModal
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      {...other}
      testId={testId}
    >
      <VerseModalContentContainer>
        {renderHeader()}
        <ContentArea>
          <VerseH4 mb={2} html>
            {title}
          </VerseH4>
          <VerseBody2 html>{description}</VerseBody2>
          {components?.Content ? <components.Content /> : null}

          <ButtonsArea>
            {secondaryButton && (
              <VerseButton
                testId="confirmationModal-secondaryButton"
                variant={VerseButtonVariantEnum.SECONDARY_CTA}
                size={VerseButtonSizeEnum.SMALL}
                mr={0.5}
                {...secondaryButton}
              />
            )}
            <VerseButton
              testId="confirmationModal-primaryButton"
              variant={VerseButtonVariantEnum.PRIMARY_CTA}
              size={VerseButtonSizeEnum.SMALL}
              {...primaryButton}
            />
          </ButtonsArea>
        </ContentArea>
      </VerseModalContentContainer>
    </StyledVerseBaseModal>
  );
};

const VerseModalContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const StyledVerseBaseModal = styled(VerseBaseModal)`
  && {
    flex-direction: column;
    ${({ width = 500 }) =>
      width && `width: ${Number.isInteger(width) ? `${width}px` : width};`}
    border-radius: 24px;
    overflow: hidden;
    padding: 0;
    box-shadow: ${({ theme }) => theme.shadows.primaryDark10};
  }
`;
const HeaderArea = styled.div<{ imageUrl?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 294px;
  background: ${({ imageUrl, theme }) =>
    imageUrl
      ? `url('${imageUrl}') center center / cover`
      : theme.colors.main.PrimaryYellow100};
`;
const ContentArea = styled.div`
  padding: ${({ theme }) => theme.spacing(3)}px
    ${({ theme }) => theme.spacing(2.5)}px
    ${({ theme }) => theme.spacing(2.5)}px;
  display: flex;
  flex-direction: column;
`;

const ButtonsArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing(5)}px;
`;
