import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { VerseIcon, VerseIconIdEnum } from '..';
import { useVerseTheme } from '../..';
import { VerseIconClickableBGVariantEnum } from '../VerseIcon/consts';
import { VerseModalScrimColorEnum } from './consts';
import {
  VerseBaseModalComponentProps,
  VerseBaseModalContainerProps,
  VerseBaseModalViewportContainerProps,
  VerseModalScrimColorVariant,
} from './typings';

export const VerseBaseModal = ({
  open,
  onClose,
  children,
  className,
  withCloseButton = true,
  width = 'auto',
  height = 'auto',
  maxWidth = 'calc(100vw - 80px)',
  maxHeight = 'calc(100vh - 80px)',
  innerPadding = 16,
  viewportPadding = 40,
  scrimColorVariant = VerseModalScrimColorEnum.MEDIUM,
  testId,
  zIndex,
  closeButtonTestId,
  onMouseEnter,
  onMouseLeave,
  shouldCloseOnEsc = true,
  /** Set it false when there is both close button & cancel button */
  shouldCloseOnOverlayClick = true,
  modalId,
}: VerseBaseModalComponentProps) => {
  const theme = useVerseTheme();

  const onRequestClose = (e: any) => {
    e?.stopPropagation();
    onClose && onClose();
  };

  if (open) {
    return (
      <ReactModal
        isOpen={open}
        shouldCloseOnEsc={shouldCloseOnEsc}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        onRequestClose={onRequestClose}
        className="_"
        overlayClassName="_"
        id={modalId}
        ariaHideApp={false}
        contentElement={(props, children) => (
          <VerseModalContainer
            {...props}
            width={width}
            height={height}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            innerPadding={innerPadding}
            className={`${props.className} ${className}`}
            data-testid={testId}
          >
            {children}

            {withCloseButton && Boolean(onClose) ? (
              <VerseModalCloseIconContainer data-testid={closeButtonTestId}>
                <VerseIcon
                  iconId={VerseIconIdEnum.CLOSE}
                  size={16}
                  onClick={onRequestClose}
                  clickableBgVariant={
                    VerseIconClickableBGVariantEnum.SEMI_TRANSPARENT
                  }
                />
              </VerseModalCloseIconContainer>
            ) : null}
          </VerseModalContainer>
        )}
        overlayElement={(props, contentElement) => {
          const preventPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            props?.onClick && props.onClick(e);
          };
          return (
            <VerseModalRoot
              zIndex={zIndex ?? theme.zIndex.level.dialog}
              {...props}
              onClick={preventPropagation}
            >
              <VerseModalScrim colorVariant={scrimColorVariant} />

              <VerseModalViewportContainer viewportPadding={viewportPadding}>
                {contentElement}
              </VerseModalViewportContainer>
            </VerseModalRoot>
          );
        }}
        closeTimeoutMS={500}
      >
        {children}
      </ReactModal>
    );
  }
  return null;
};

const VerseModalRoot = styled.div<{ zIndex: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: ${({ zIndex }) => zIndex};
  transition: all ${({ theme }) => theme.animationSpeed}ms linear;
  opacity: 0;
  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }
  &.ReactModal__Overlay--before-close {
    opacity: 0;
  }
`;
const VerseModalScrim = styled.div<{
  colorVariant?: VerseModalScrimColorVariant;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // relative to parent's stacking context
  z-index: -1;

  ${({ colorVariant, theme }) => {
    switch (colorVariant) {
      case VerseModalScrimColorEnum.MEDIUM:
        return `background-color: ${theme.colors.main.PrimaryDark40}; opacity: 0.4;`;
      case VerseModalScrimColorEnum.DARK:
        return `background-color: ${theme.colors.main.PrimaryDark100}; opacity: 0.4;`;
      default:
        return ``;
    }
  }}

  overflow-x: hidden;
  overflow-y: auto;
`;
const VerseModalViewportContainer = styled.div<VerseBaseModalViewportContainerProps>`
  padding: ${({ viewportPadding }) =>
    Number.isInteger(viewportPadding)
      ? `${viewportPadding}px`
      : viewportPadding}};
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden auto;
`;
const VerseModalContainer = styled.div<VerseBaseModalContainerProps>`
  margin: auto 0;
  width: ${({ width = 'auto' }) =>
    Number.isInteger(width) ? `${width}px` : width}};
  height: ${({ height }) =>
    Number.isInteger(height) ? `${height}px` : height}};
  max-width: ${({ maxWidth = 'auto' }) =>
    Number.isInteger(maxWidth) ? `${maxWidth}px` : maxWidth}};
  max-height: ${({ maxHeight = 'auto' }) =>
    Number.isInteger(maxHeight) ? `${maxHeight}px` : maxHeight}};

  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.other.White};
  border-radius: 12px;
  padding: ${({ innerPadding }) =>
    Number.isInteger(innerPadding) ? `${innerPadding}px` : innerPadding}};

  opacity: 0;
  transition: opacity ${({ theme }) => theme.animationSpeed}ms ease-in-out;
  overflow: hidden auto;
  position: relative;

  box-shadow: ${({ theme }) => theme.shadows.primaryDark10};

  &.ReactModal__Content--after-open {
    opacity: 1;
  }
  &.ReactModal__Content--before-close {
    opacity: 0;
  }
`;

const VerseModalCloseIconContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;

  // relative to parent's stacking context
  z-index: 1;
`;
