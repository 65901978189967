import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const UserGroupIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M12.0956 14.0957C14.5256 14.0957 16.4956 12.1257 16.4956 9.69565C16.4956 7.2656 14.5256 5.29565 12.0956 5.29565C9.6655 5.29565 7.69556 7.2656 7.69556 9.69565C7.69556 12.1257 9.6655 14.0957 12.0956 14.0957Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M16.7827 3.47827C15.3479 3.47827 14.2 4.43479 13.9131 5.67827C15.4435 6.34784 16.4 7.78262 16.4957 9.50436C16.5913 9.50436 16.687 9.50436 16.7827 9.50436C18.5044 9.50436 19.8435 8.16523 19.8435 6.44349C19.8435 4.72175 18.4087 3.47827 16.7827 3.47827Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M10.1826 5.75C9.79997 4.41087 8.65215 3.47827 7.21736 3.47827C5.49562 3.47827 4.15649 4.8174 4.15649 6.53914C4.15649 8.26088 5.49562 9.60001 7.21736 9.60001C7.40867 9.60001 7.50432 9.60001 7.69562 9.50436C7.79128 7.87827 8.7478 6.41957 10.1826 5.75Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M3.80005 20.5C3.84174 19.9706 3.88866 19.5018 3.93665 19.0921C4.09814 17.713 5.13471 16.6758 6.51374 16.5143C7.80522 16.363 9.68297 16.2223 12.2002 16.2223C14.7174 16.2223 16.5952 16.363 17.8867 16.5143C19.2657 16.6758 20.3023 17.7131 20.4637 19.0921C20.5117 19.5018 20.5586 19.9706 20.6003 20.5"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M1.47998 14.1879C1.51105 13.7933 1.54602 13.444 1.58178 13.1387C1.70213 12.111 2.47486 11.3379 3.50255 11.2176C3.91889 11.1688 4.41697 11.1215 5 11.0836"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M22.52 14.1879C22.4889 13.7933 22.454 13.444 22.4182 13.1387C22.2979 12.111 21.5251 11.3379 20.4975 11.2176C20.0811 11.1688 19.583 11.1215 19 11.0836"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
