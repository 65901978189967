import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const PanelViewIcon = ({
  stroke,
  strokeWidth,
  fill = 'transparent',
}: VerseIconSVGProps) => (
  <>
    <path
      d="M1.37539 19.3113C1.58307 21.0843 2.91566 22.4169 4.68872 22.6246C6.34923 22.8191 8.76351 23 12 23C15.2365 23 17.6508 22.8191 19.3113 22.6246C21.0843 22.4169 22.4169 21.0843 22.6246 19.3113C22.8191 17.6508 23 15.2365 23 12C23 8.76351 22.8191 6.34923 22.6246 4.68872C22.4169 2.91566 21.0843 1.58307 19.3113 1.37539C17.6508 1.18088 15.2365 1 12 1C8.76351 1 6.34923 1.18088 4.68872 1.37539C2.91566 1.58307 1.58307 2.91566 1.37539 4.68872C1.18088 6.34923 1 8.76351 1 12C1 15.2365 1.18088 17.6508 1.37539 19.3113Z"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <path
      d="M12 1V14.5M12 14.5H1M12 14.5H23"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
    <path
      d="M15.5 14.5V23"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M8.5 23V14.5"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
