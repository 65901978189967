import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const StageMicIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M7.2 12.9C10.3 12.9 12.9 10.3 12.9 7.2C12.9 4.1 10.3 1.5 7.2 1.5C4.1 1.5 1.5 4.1 1.5 7.2C1.5 10.3 4.1 12.9 7.2 12.9Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      fill="transparent"
      d="M2.40002 10.4C6.00002 8.9 8.90002 6 10.4 2.4"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      fill="transparent"
      d="M4 12C7.6 10.5 10.5 7.6 12 4"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M22 22.8C22.4 22.8 22.7999 22.4 22.7999 22C22.7999 21.6 22.4 21.2 22 21.2C21.6 21.2 21.2 21.6 21.2 22C21.2 22.4 21.6 22.8 22 22.8Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      fill={Array.isArray(fill) ? fill[0] : fill}
      d="M22.2 19.3C21.4 18.2 20 16.2 17.8 13.7C16.4 12.1 14.6 10.1 12.8 8.2C12.4 10.5 10.6 12.3 8.40002 12.8C10 14.2 11.7 15.8 13.3 17.2C16.2 19.8 18.3 21.3 19.4 22.1C20 22.5 20.7 22.4 21.2 21.9L22 21.1C22.5 20.6 22.6 19.9 22.2 19.3Z"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
