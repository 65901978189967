export const ROOM_TEST_IDS = {
  TRAY: {
    REACT: "controls-reactIconButton",
    QUEUE: "controls-queueIconButton",
  },
  SIDEBAR: {
    AGENDA: "roomSidebar-agenda",
    TOOLBOX: "roomSidebar-toolbox",
    LIBRARY: "roomSidebar-library",
  },
  ROOM_NAV: {
    INVITE: "roomNav-inviteButton",
  },
};

export enum ParticipantTypeEnum {
  participant = "participant",
  host = "host",
  coHost = "co-host",
  tempHost = "temp-host",
}

export enum RoomUserLastSeenStatusEnum {
  ONLINE = "online",
  OFFLINE = "offline",
}

export const ROOM_HOST_TIMEOUT_MINS = 10;
export const TIME_CAP_PARTICIPANT_THRESHOLD = 2;
