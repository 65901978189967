import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ArrowLeftV1Icon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M10.75 3.24994L2 11.9999L10.75 20.7499"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 12H22"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
