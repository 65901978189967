import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const ImageIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M1.31134 6.49343C1.53359 4.73781 2.9119 3.47797 4.67341 3.3086C6.33438 3.14891 8.75355 3 12 3C15.2464 3 17.6656 3.14891 19.3266 3.3086C21.0881 3.47797 22.4664 4.73781 22.6887 6.49343C22.8571 7.82355 23 9.64596 23 12C23 14.354 22.8571 16.1765 22.6887 17.5066C22.4664 19.2622 21.0881 20.522 19.3266 20.6914C17.6656 20.8511 15.2464 21 12 21C8.75355 21 6.33438 20.8511 4.67341 20.6914C2.9119 20.522 1.53359 19.2622 1.31134 17.5066C1.14295 16.1765 1 14.354 1 12C1 9.64596 1.14295 7.82354 1.31134 6.49343Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M22.9424 14.5C22.9395 14.5612 22.9365 14.6219 22.9334 14.682M22.9334 14.682C22.8758 15.7948 22.7865 16.7338 22.6887 17.5065C22.4664 19.2621 21.0881 20.522 19.3266 20.6914C17.6657 20.8511 15.2465 21 12 21C8.75359 21 6.33441 20.8511 4.67344 20.6914C2.91193 20.522 1.53358 19.262 1.31134 17.5063C1.2909 17.3448 1.27083 17.1761 1.25134 17L5.199 13.0523C5.62433 12.627 6.32618 12.6707 6.69546 13.1455L8.67256 15.6875C9.08449 16.2171 9.89084 16.1988 10.2783 15.651L15.9615 7.61655C16.3524 7.06399 17.1678 7.05118 17.5758 7.5912L22.9334 14.682Z"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <circle
      cx="8.5"
      cy="8.5"
      r="2.5"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
  </>
);
