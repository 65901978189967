import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const BulbIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M15.6134 16.1394C18.2162 14.8194 20 12.118 20 9C20 4.58172 16.4183 1 12 1C7.58172 1 4 4.58172 4 9C4 12.118 5.7838 14.8194 8.38657 16.1394C8.63788 16.2668 8.835 16.479 8.93267 16.7326C9.02056 16.9607 9.03138 17.2122 9.09337 17.4487C9.34592 18.4122 10.0871 19.18 11.0797 19.2622C11.3582 19.2852 11.6667 19.3 12 19.3C12.3333 19.3 12.6418 19.2852 12.9203 19.2622C13.9129 19.18 14.6541 18.4122 14.9066 17.4487C14.9686 17.2122 14.9794 16.9607 15.0673 16.7326C15.165 16.479 15.3621 16.2668 15.6134 16.1394Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M9.5 21C9.5 21 10.5 21.3 12 21.3C13.5 21.3 14.5 21 14.5 21"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 23C10 23 11 23.2 12 23.2C13 23.2 14 23 14 23"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 9C17 6.23858 14.7614 4 12 4"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
