import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const SaveIcon = ({ fill, stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M1 12C1 15.2365 1.18088 17.6508 1.37539 19.3113C1.58307 21.0843 2.91566 22.4169 4.68872 22.6246C6.34923 22.8191 8.76351 23 12 23C15.2365 23 17.6508 22.8191 19.3113 22.6246C21.0843 22.4169 22.4169 21.0843 22.6246 19.3113C22.8191 17.6508 23 15.2365 23 12C23 10.5114 22.9617 9.1967 22.9015 8.0493C22.8658 7.36958 22.5864 6.72717 22.1255 6.22631L18.2977 2.06648C17.7849 1.50929 17.0807 1.16346 16.3248 1.11928C15.0916 1.04719 13.6531 1 12 1C8.76351 1 6.34923 1.18088 4.68872 1.37539C2.91566 1.58307 1.58307 2.91566 1.37539 4.68872C1.18088 6.34923 1 8.76351 1 12Z"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <rect
      x="4"
      y="4"
      width="13"
      height="5"
      rx="1"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
    />
    <circle
      cx="12"
      cy="16.5"
      r="4"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={Number.parseFloat(strokeWidth)}
    />
  </>
);
