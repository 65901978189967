import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const UnderlineIcon = ({ stroke }: VerseIconSVGProps) => (
  <>
    <path
      d="M17.1594 13.5853C17.1594 14.5926 16.9591 15.4786 16.5586 16.2432C16.1581 17.0078 15.5817 17.6024 14.8292 18.0272C14.0767 18.4398 13.1787 18.6462 12.1349 18.6462C11.1033 18.6462 10.2052 18.4398 9.44066 18.0272C8.6882 17.6024 8.11172 17.0078 7.71122 16.2432C7.31072 15.4786 7.11047 14.5926 7.11047 13.5853V5.62991H8.60325V13.4761C8.60325 14.629 8.92486 15.5393 9.56809 16.2068C10.2113 16.8621 11.0669 17.1898 12.1349 17.1898C13.2029 17.1898 14.0585 16.8621 14.7018 16.2068C15.3571 15.5393 15.6848 14.629 15.6848 13.4761V5.62991H17.1594V13.5853Z"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      d="M19.499 21L4.49902 21"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeLinecap="round"
    />
  </>
);
