import { TDSnapshot } from "@tldraw/tldraw";
import * as React from "react";
import { useButterTldrawApp } from "src/utils/useButterTldrawApp";
import {
  useVerseTheme,
  VerseIcon,
  VerseIconIdEnum,
  VerseTooltip,
} from "src/verse/src";
import { StyledToolButton } from "../StyledToolButton";

const hasLockedSelectedSelector = (s: TDSnapshot) => {
  return s.document.pageStates[s.appState.currentPageId].selectedIds.some(
    (id) => s.document.pages[s.appState.currentPageId].shapes[id].isLocked
  );
};
export function ToolPanelLockButton() {
  const app = useButterTldrawApp();

  const hasSelection = app?.useStore(
    (s) =>
      s.appState.status === "idle" &&
      s.document.pageStates[s.appState.currentPageId].selectedIds.length > 0
  );
  const theme = useVerseTheme();

  const hasLockedSelected = app?.useStore(hasLockedSelectedSelector);

  const handleClick = React.useCallback(() => {
    if (hasSelection) {
      app?.toggleLocked();
    }
  }, [app, hasSelection, hasLockedSelected]);

  if (hasSelection) {
    return (
      <VerseTooltip
        title={hasLockedSelected ? "Unlock" : "Lock"}
        placement="right"
      >
        <StyledToolButton
          isActive={hasLockedSelected}
          disabled={!hasSelection}
          isActiveColor={theme.colors.main.PrimaryYellow50}
          hoverColor={theme.colors.main.PrimaryYellow25}
          onClick={handleClick}
        >
          <VerseIcon
            fill="transparent"
            stroke={theme.colors.main.PrimaryDark100}
            iconId={VerseIconIdEnum.LOCK}
          />
        </StyledToolButton>
      </VerseTooltip>
    );
  }
  return null;
}
