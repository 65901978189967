import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const DistributeIcon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M1 18H3.86059C5.54243 18 7.14706 17.2941 8.28351 16.0543L15.7165 7.94565C16.8529 6.70588 18.4576 6 20.1394 6H23"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M21.1666 4L23 6L21.1666 8"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 6H3.86059C5.54243 6 7.14706 6.70588 8.28351 7.94565L15.7165 16.0543C16.8529 17.2941 18.4576 18 20.1394 18H23"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M21.1666 20L23 18L21.1666 16"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
