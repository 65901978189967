import * as React from "react";

import { StyledToolButton } from "./StyledToolButton";
import { VerseIcon, VerseIconIdEnum, VerseTooltip } from "src/verse/src";
import { sendTemplateButtonClickedToWebapp } from "src/utils/boardToWebappUtils";
import { useParams } from "src/hooks/useParams";

export const ToolPanelTemplateButton = () => {
  const { enableTemplates } = useParams();
  if (enableTemplates) {
    return (
      <VerseTooltip title="Templates" placement="right">
        <StyledToolButton onClick={sendTemplateButtonClickedToWebapp}>
          <VerseIcon iconId={VerseIconIdEnum.TEMPLATE} />
        </StyledToolButton>
      </VerseTooltip>
    );
  }
  return null;
};
