import React, { useState, useRef } from 'react';
import { useController } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';

import {
  VerseBody2,
  VerseBox,
  VerseButton,
  VerseButtonSizeEnum,
  VerseButtonVariantEnum,
  VerseIcon,
  VerseIconIdEnum,
  VerseSelectOptionObj,
} from '..';
import { CUSTOM_SELECT_STYLES } from './consts';
import { VerseCreatableSelectProps } from './typings';
import { VerseCreatableSelectMenu } from './VerseCreatableSelectMenu';
import { VerseCreatableSelectMenuList } from './VerseCreatableSelectMenuList';
import { VerseCreatableSelectOption } from './VerseCreatableSelectOption';
import { ClickAwayListener } from '../ClickAwayListener';

export function VerseCreatableSelect({
  name,
  control,
  placeholder,
  options,
  defaultValue,
  setValue,
  setOptions,
  title,
  createLabel,
  testId,
  disabled,
}: VerseCreatableSelectProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    field: { ref, value, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const onChange = (options: VerseSelectOptionObj[]) => {
    const newValues = options;
    setValue(name, newValues);
    closeMenu();
  };

  const onCreateOption = (input: string) => {
    const newOption = { label: input, value: input };
    setOptions([...options, newOption]);
    setValue(name, [...value, newOption]);
  };

  const onRemoveItem = (removedItemValue: string) => {
    setValue(
      name,
      value.filter((option: any) => option?.value !== removedItemValue),
    );
  };
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const closeMenu = (e?: MouseEvent | TouchEvent) => {
    /** If the click event is coming from the Add button, let the button handle on click event  */
    if (
      buttonRef?.current &&
      !buttonRef.current?.contains(e?.target as Element)
    ) {
      setIsMenuOpen(false);
    }
  };

  const onAddButtonClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <VerseBox position="relative">
      <VerseBox display="flex" alignItems="flex-start">
        <VerseButton
          variant={VerseButtonVariantEnum.TERTIARY_CTA}
          size={VerseButtonSizeEnum.SMALL}
          title={title}
          icon={{
            iconId: VerseIconIdEnum.PLUS,
          }}
          onClick={onAddButtonClick}
          testId={testId}
          mr={1.5}
          disabled={disabled}
          ref={buttonRef}
        />
        <StyledTagContainer>
          {value?.map((option: VerseSelectOptionObj) => (
            <StyledTag key={option.value}>
              <VerseBody2 mr={1}>{option.label}</VerseBody2>
              <VerseIcon
                iconId={VerseIconIdEnum.CLOSE}
                size={7}
                onClick={() => onRemoveItem(option.value)}
              ></VerseIcon>
            </StyledTag>
          ))}
        </StyledTagContainer>
      </VerseBox>
      <ClickAwayListener enabled={isMenuOpen} onClickAway={closeMenu}>
        <StyledVerseCreatableSelectContainer>
          {isMenuOpen ? (
            <StyledCreatableSelect
              {...inputProps}
              inputRef={ref}
              name={name}
              formatCreateLabel={(inputValue: string) =>
                `${createLabel ?? 'Create'} " ${inputValue}"`
              }
              value={value}
              hideSelectedOptions={false}
              classNamePrefix="react-select"
              isMulti
              autoFocus
              menuIsOpen
              control={control}
              placeholder={placeholder}
              options={options}
              onCreateOption={onCreateOption}
              onChange={onChange}
              styles={CUSTOM_SELECT_STYLES}
              controlShouldRenderValue={false}
              components={{
                Menu: VerseCreatableSelectMenu,
                MenuList: VerseCreatableSelectMenuList,
                IndicatorSeparator: null,
                /** Cannot pass null since it's a container */
                IndicatorsContainer: () => <></>,
                Option: VerseCreatableSelectOption,
              }}
            />
          ) : null}
        </StyledVerseCreatableSelectContainer>
      </ClickAwayListener>
    </VerseBox>
  );
}

const StyledVerseCreatableSelectContainer = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  width: 290px;
  max-height: 228px;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.level.dialogPopup + 1};
  position: absolute;
  top: 45px;
`;

const StyledCreatableSelect = styled(CreatableSelect)`
  width: 260px;
  font-family: GTEestiDisplay !important;
  font-weight: 300;
  font-size: 14px;

  .react-select__input {
    > input {
      font-family: GTEestiDisplay !important;
      font-weight: 300 !important;
    }
  }
`;

const StyledTagContainer = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  max-width: fit-content;
  flex-wrap: wrap;
`;

const StyledTag = styled.li`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.other.Blue10};
  height: 32px;
  border-radius: 12px;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(1.5)}px;
  margin-right: ${({ theme }) => theme.spacing(1.5)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;
