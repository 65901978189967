import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const GiphyIcon = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23C5.928 23 1 18.072 1 12C1 5.928 5.928 1 12 1C18.072 1 23 5.928 23 12C23 18.072 18.072 23 12 23Z"
      fill="#0A0B1E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.58301 4.3916V6.42666V8.51672V10.6159V12.7152V14.8052V16.9045V19.0587H7.4164V16.9045V14.8052V12.7152V10.6159V8.51672V6.42666V4.3916H5.58301Z"
      fill="#00FF99"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5835 8.05835V10.1942V12.3943V14.5944V16.7944V19.0587H18.4169V16.7944V14.5944V12.3943V10.1942V8.05835H16.5835Z"
      fill="#9933FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7714 3.75H9.70886H7.6463H5.58374V5.58339H7.6463H9.70886H11.7714H13.559V3.75H11.7714Z"
      fill="#FFF35C"
    />
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0833 5.58339H13.8333L12.9166 3.75L11.0833 5.58339Z"
      fill="black"
    />
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4169 9.25043L16.5835 8.33374V11.0838L18.4169 9.25043Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5833 18.417H14.7499H12.9165H11.0832H9.24978H7.41639H5.58301V20.2504H7.41639H9.24978H11.0832H12.9165H14.7499H16.5833H18.4167V18.417H16.5833Z"
      fill="#00CCFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.583 7.41677V5.58339H14.7496V3.75H12.9163V5.24421V7.0776V9.25016H14.7496H16.583H18.4164V7.41677H16.583Z"
      fill="#FF6666"
    />
  </>
);
