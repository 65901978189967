import React from 'react';
import { components, OptionProps } from 'react-select';
import styled from 'styled-components';

import { VerseIcon, VerseIconIdEnum, VerseSelectOptionObj } from '..';

export const VerseCreatableSelectOption = (
  props: OptionProps<VerseSelectOptionObj, true>,
) => {
  return (
    <components.Option {...props}>
      <StyledVerseCreatableSelectOption>
        <>
          {props.children}
          {props.isSelected ? (
            <StyledIconBackground>
              <VerseIcon iconId={VerseIconIdEnum.CHECK_V1} size={10} />
            </StyledIconBackground>
          ) : null}
        </>
      </StyledVerseCreatableSelectOption>
    </components.Option>
  );
};

const StyledVerseCreatableSelectOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
`;
const StyledIconBackground = styled.div`
  background: ${({ theme }) => theme.colors.other.Sand75};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
