import React, { ReactNode } from 'react';
import { components, SingleValueProps } from 'react-select';
import styled from 'styled-components';

import { VerseBody1, VerseBody2, VerseBody3, VerseIcon, VerseSelectSizeEnum } from '..';
import { VerseSelectOptionObj } from './typings/verseSelectTypings';

interface VerseSelectSingleValueProps extends SingleValueProps<any> {
  data: VerseSelectOptionObj;
}
export const VerseSelectSingleValue = (
  props: Omit<VerseSelectSingleValueProps, 'theme'>,
) => {
  const { iconId, label, Component } = props.data;
  const { size } = props.selectProps;

  const TextComponent = React.useMemo(() => {
    switch (size) {
      case VerseSelectSizeEnum.LARGE:
        return VerseBody1;
      case VerseSelectSizeEnum.MEDIUM:
        return VerseBody2;
      case VerseSelectSizeEnum.SMALL:
      default:
        return VerseBody3;
    }
  }, []);

  const renderComponent = () => {
    if (Component) {
      return Component as ReactNode;
    }
    return (
      <>
        {iconId && (
          <VerseIcon size={16} mr={0.75} iconId={iconId} stroke="inherit" />
        )}
        <TextWrapper>
          <TextComponent color="inherit" component="span">
            {label}
          </TextComponent>
        </TextWrapper>
      </>
    )
  }

  return (
    <ValueContainer {...props}>
      {renderComponent()}
    </ValueContainer>
  );
};

const ValueContainer = styled(components.SingleValue)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
