import { Tldraw } from "@tldraw/tldraw";
import React from "react";
import { ToolsPanel } from "./components/ToolsPanel";
import { useEventMessageHook } from "src/hooks/useEventMessageHook";
import { useParams } from "src/hooks/useParams";

import { useReadOnlyState } from "src/hooks/useReadOnlyState";
import { TldrawContext } from "./utils/useButterTldrawApp";
import { ButterboardEmptyState } from "./components/ButterboardEmptyState/ButterboardEmptyState";

export const LocalStateEditor = () => {
  const { enableListener, readOnly, showDefaultToolbar, hideToolbar } =
    useParams();
  const { app, ...events } = useReadOnlyState({ readOnly });

  useEventMessageHook({ app, enable: enableListener });
  return (
    <TldrawContext.Provider value={app}>
      {!showDefaultToolbar && app && !hideToolbar ? <ToolsPanel /> : null}
      {app ? <ButterboardEmptyState /> : null}
      <Tldraw
        disableAssets
        {...events}
        showPages={false}
        showMenu={false}
        showUI={hideToolbar ? false : !readOnly}
        showTools={hideToolbar ? false : showDefaultToolbar}
      />
    </TldrawContext.Provider>
  );
};
