import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const InstagramIcon = ({ fill, stroke }: VerseIconSVGProps) => (
  <>
    <rect
      x="2"
      y="2"
      width="20"
      height="20"
      rx="6"
      fill={Array.isArray(stroke) ? stroke[0] : stroke}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17ZM12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
    />
    <circle cx="17" cy="7" r="1" fill={Array.isArray(fill) ? fill[0] : fill} />
  </>
);
