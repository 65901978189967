import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const SoundOnIcon = ({
  fill,
  stroke,
  strokeWidth,
}: VerseIconSVGProps) => (
  <>
    <path
      d="M13 12C13 7.49234 12.6667 4.3269 12.416 2.58389C12.2822 1.65323 11.4766 1 10.5364 1C9.92899 1 9.3541 1.27425 8.97194 1.7463L4.61269 7.13085C4.23296 7.59989 3.66174 7.87239 3.05825 7.87239H2.97154C2.06987 7.87239 1.28687 8.4788 1.16867 9.37268C1.07802 10.0583 1 10.9526 1 12C1 13.0474 1.07802 13.9417 1.16867 14.6273C1.28687 15.5212 2.06987 16.1276 2.97154 16.1276H3.05825C3.66174 16.1276 4.23296 16.4001 4.61269 16.8692L8.97194 22.2537C9.3541 22.7258 9.92899 23 10.5364 23C11.4766 23 12.2822 22.3468 12.416 21.4161C12.6667 19.6731 13 16.5077 13 12Z"
      fill={Array.isArray(fill) ? fill[0] : fill}
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="M16.5 6C16.5 6 19 8 19 12C19 16 16.5 18 16.5 18"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      id="soundOnIcon-wave-1"
    />
    <path
      d="M19.5 3C19.5 3 23 6 23 12C23 18 19.5 21 19.5 21"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      id="soundOnIcon-wave-2"
    />
  </>
);
