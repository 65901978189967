/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { TDUser, TldrawApp } from "@tldraw/tldraw";
import { useCallback, useState } from "react";

export interface TldrawPresence {
  id: string;
  tdUser: TDUser;
}

interface ReadOnlySettings {
  readOnly?: boolean;
}

export function useReadOnlyState(props?: ReadOnlySettings) {
  const [app, setApp] = useState<TldrawApp>();

  const onMount = useCallback(
    (app: TldrawApp) => {
      app?.setSetting("dockPosition", "left");
      app?.zoomToContent();
      app?.setSetting("keepStyleMenuOpen", true);

      setApp(app);
    },
    [props]
  );

  return {
    onMount,
    app,
  };
}
