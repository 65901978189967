import React from 'react';
import { VerseIconSVGProps } from '../typings';

export const UpdateIcon = ({ stroke, strokeWidth }: VerseIconSVGProps) => (
  <>
    <path
      d="M0.75 12C0.75 5.7868 5.7868 0.75 12 0.75C15.7641 0.75 19.0965 2.59862 21.1387 5.4375M21.1387 5.4375L21.375 2.625M21.1387 5.4375H18.0938"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M23.25 12C23.25 18.2132 18.2132 23.25 12 23.25C8.23589 23.25 4.90355 21.4014 2.86134 18.5625M2.86134 18.5625L2.625 21.375M2.86134 18.5625L5.90625 18.5625"
      fill="transparent"
      stroke={Array.isArray(stroke) ? stroke[0] : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </>
);
